import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { STEPS } from 'constants/constants';

export const useJobAssignedData = jobs => {
  const { id: candidateId } = useParams();
  return useMemo(
    () =>
      jobs.map(job => ({
        id: job.uuid,
        job_step: {
          job: job.job,
          step: `${STEPS.findIndex(s => s.value === job.active_step?.name) + 1 || ''} - ${job.active_step?.name || ''}`,
          link: `/jobs/${job.uuid}/show/${job.active_step ? `steps/${job.active_step.uuid}/${candidateId}` : 'hired'}`,
        },
        status: job.active_step?.step_status || job.candidate_status,
        recruiter: job.responsible_recruiter.full_name,
        team_lead: !job.active_step && job.opening ? job.opening.team_lead.full_name : job.team_lead.full_name,
      })),
    [jobs]
  );
};
