export const getDuplicatedData = (value, field, data) => {
  switch (field) {
    case 'full_name_latin':
    case 'full_name_local':
    case 'skype':
      return data?.[field] === value;
    case 'phone':
    case 'email':
      return data?.[field]?.includes(value);
  }
};
