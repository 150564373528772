import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AnalyticsDashboard } from '_templates';
import { ROLES } from 'permission';
import { getUser } from 'store/selectors/authorizeSelectors';
import { analyticsFilter } from 'actions/analyticActions';
import { ReactComponent as CircularProgress } from 'dist/icons/loading.svg';

export const AnalyticsWrapper = () => {
  const user = useSelector(getUser);
  const isSH = user.active_role?.name === ROLES.SERVICE_HEAD;
  const [loading, setLoading] = React.useState(isSH);
  const [defaultServices, setDefaultServices] = React.useState([]);

  React.useEffect(() => {
    if (isSH) {
      analyticsFilter(
        'services',
        {
          candidates: [],
          companies: [],
          jobs: [],
          offices: [],
          openings_statuses: [],
          recruiters: [],
          services: [],
          team_leads: [],
          period: [moment(), moment()],
        },
        ''
      )
        .then(setDefaultServices)
        .catch(() => setDefaultServices([]))
        .finally(() => setLoading(false));
    }
  }, []);

  return loading ? (
    <div style={{ paddingTop: 68, height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress width={40} height={40} />
    </div>
  ) : (
    <AnalyticsDashboard defaultServices={defaultServices} />
  );
};
