import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AtsButtonMenu, Button, BUTTON_TYPES, Tooltip, Typography, TYPOGRAPHY_WEIGHT } from '_atoms';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';

const headerCellRender = (title, field, key, sort, setSorting) => () => {
  const order = sort.key === key ? sort.order : null;
  const onClick = () => setSorting({ field, key, order: order === 'asc' ? 'desc' : 'asc' });
  return (
    <>
      {title}
      <Sort className={order} onClick={onClick} />
    </>
  );
};

export const INITIAL_VALUES_SSC = {
  sorting: { key: 'candidate', order: 'asc', field: 'candidate' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
  search: '',
  period: [null, null],
};

export const COLUMNS_SSC = (sort, setSorting, onAction) => [
  {
    title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
    dataIndex: 'full_name_latin',
    rowKey: 'full_name_latin',
    width: 200,
    sorter: true,
    className: 'self-submitted-candidates__name',
    render: (name, { uuid }) => (
      <Tooltip label={name}>
        <Link to={`/self-submitted-candidates/${uuid}/show`} target="_blank">
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{name}</Typography.Text>
        </Link>
      </Tooltip>
    ),
  },
  {
    title: 'Vacancy info',
    dataIndex: 'vacancy',
    rowKey: 'vacancy',
    width: 240,
    className: 'self-submitted-candidates__vacancy-info',
    render: data =>
      data ? (
        <>
          <Typography.Text>
            <b>Name:</b> {data.name}
          </Typography.Text>
          <Typography.Text>
            <b>RL Name:</b> {data.author?.full_name}
          </Typography.Text>
          <a href={data.page_url} target="_blank" rel="noopener noreferrer">
            {data.page_url}
          </a>
        </>
      ) : (
        '-'
      ),
  },
  {
    title: headerCellRender('Creation date', 'creation_date', 'creation_date', sort, setSorting),
    dataIndex: 'submitted_at',
    rowKey: 'submitted_at',
    width: 190,
    sorter: true,
    className: 'self-submitted-candidates__date',
    render: date => moment(date).format('ddd MMM DD, YYYY ⠀ HH:mm'),
  },
  {
    title: 'Potential duplicates',
    dataIndex: 'has_potential_duplicates',
    rowKey: 'has_potential_duplicates',
    width: 144,
    className: 'self-submitted-candidates__duplicate',
    render: (hasDuplicates, record) =>
      hasDuplicates ? (
        <Button type={BUTTON_TYPES.TEXT} onClick={onAction('duplicates', record, true)}>
          open duplicates
        </Button>
      ) : (
        '-'
      ),
  },
  {
    title: 'Viewed by',
    dataIndex: 'viewed_by_list',
    rowKey: 'viewed_by_list',
    width: 210,
    className: 'self-submitted-candidates__viewed-by',
    render: viewed_by =>
      viewed_by.length ? (
        <Tooltip label={viewed_by.join(', ')}>
          <Typography.Text>{viewed_by.join(', ')}</Typography.Text>
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 120,
    className: 'self-submitted-candidates__action',
    render: (_, record) => (
      <AtsButtonMenu fixedPosition>
        <li role="none" onClick={onAction('assign', record)}>
          Assign to job
        </li>
        <li role="none" onClick={onAction('review', record)}>
          Mark as reviewed
        </li>
        <li role="none" onClick={onAction('share', record)}>
          Share profile
        </li>
        <li role="none" onClick={onAction('delete', record)} className="red">
          Delete
        </li>
      </AtsButtonMenu>
    ),
  },
];
