import React from 'react';
import { Form } from 'antd';
import cn from 'classnames';
import { DropDownMenu } from '_atoms/index';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as Arrow } from 'dist/icons/caret-down.svg';
import { ReactComponent as CheckMark } from 'dist/icons/checkmark.svg';
import { PLACEMENTS } from './constants';
import './style.scss';

const SelectSmallInput = ({ label, options, value, onChange, className, placement = PLACEMENTS.BOTTOM_LEFT }) => (
  <div className={cn('select-small-default', placement)}>
    <Typography.Caption>{label}:</Typography.Caption>
    <DropDownMenu
      triggerElement={
        <div className={cn('select-small-default__trigger', className)}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{value.label}</Typography.Text>
          <Arrow className="select-small-default__arrow" />
        </div>
      }
    >
      {options.map(option => (
        <li key={option.value} role="none" onClick={() => onChange(option)}>
          <Typography.Text>{option.label}</Typography.Text>
          {option.value === value.value && <CheckMark />}
        </li>
      ))}
    </DropDownMenu>
  </div>
);

const SelectSmall = ({ itemProps, ...props }) => (
  <Form.Item {...itemProps}>
    <SelectSmallInput {...props} />
  </Form.Item>
);

export default SelectSmall;
