import React from 'react';
import { Tooltip } from '_atoms';
import { ReactComponent as Minor } from 'dist/icons/priority-minor.svg';
import { ReactComponent as Medium } from 'dist/icons/priority-medium.svg';
import { ReactComponent as Critical } from 'dist/icons/priority-critical.svg';
import { PRIORITY } from './constants';

const Priority = ({ priority = PRIORITY.MEDIUM }) => (
  <Tooltip label={priority}>
    <div className="priority">
      {
        {
          [PRIORITY.MINOR]: <Minor />,
          [PRIORITY.MEDIUM]: <Medium />,
          [PRIORITY.CRITICAL]: <Critical />,
        }[priority]
      }
    </div>
  </Tooltip>
);

export default Priority;
