import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';
import { Button, BUTTON_TYPES, Card } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { MixpanelService } from 'helpers/Mixpanel';
import { signIn } from 'actions';
import { BUTTON_TEXT } from 'constants/text';
import { ReactComponent as GoogleLogo } from 'dist/icons/google.svg';
import { ReactComponent as Logo } from 'dist/icons/logo.svg';
import { useLoginState } from './utils';
import { LOGIN_TYPES } from './constants';
import './style.scss';

export const Login = ({ clearError }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [messageType, setMessageType] = useState(location?.state?.error ?? LOGIN_TYPES.WELCOME);
  const [title, extra] = useLoginState(messageType);

  const login = useGoogleLogin({
    onSuccess: ({ code }) => dispatch(signIn(code, setMessageType)),
    onNonOAuthError: error => console.log('error sign in', error),
    scope: 'email profile openid',
    overrideScope: true,
    flow: 'auth-code',
  });

  const onGoogleLogin = () => {
    localStorage.removeItem('access_token');
    if (clearError) clearError();
    login();
  };

  useEffect(() => {
    const mixpanel = new MixpanelService();
    mixpanel.track('View Page.Login');
  }, []);

  return (
    <div className="login-page">
      <Card className="login-card">
        <Logo className="login-card__app-name" />
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD} className="login-card__title">
          {title}
        </Typography.Text>
        <Typography.Text className="login-card__subtitle">{extra}</Typography.Text>
        <Button className="original-svg" type={BUTTON_TYPES.PRIMARY} icon={<GoogleLogo />} onClick={onGoogleLogin}>
          {BUTTON_TEXT.GOOGLE_LOGIN}
        </Button>
      </Card>
    </div>
  );
};
