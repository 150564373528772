import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Context } from '_templates/AnalyticsDashboard/analytics-dashboard';
import { ANALYTICS_TABS, STEPS } from 'constants/constants';
import { TransitionStep } from '_templates/AnalyticsDashboard/TransitionStep/transition-step';
import { AnalyticsEmpty } from '_templates/AnalyticsDashboard/AnalyticsEmpty/analytics-empty';
import { analyticWidgets } from 'actions/analyticActions';
import { PassingStep } from '_templates/AnalyticsDashboard/PassingStep/passing-step';
import { Rejection } from '_templates/AnalyticsDashboard/Rejection/rejection';
import { Reason } from '_templates/AnalyticsDashboard/Reason/reason';
import { initialOptions } from '_templates/AnalyticsDashboard/constans';
import Date from 'dist/images/common/icons/graph.svg';
import { ReactComponent as Clock } from 'dist/emptyStates/dashboardReminders.svg';
import { CandidateWidget } from '../AnalyticsRecruitersInfo/AnalyticsRecruitersInfo';

const assignedMapper = (values, data) => ({
  ...values,
  total: data.candidates,
  simpleView: true,
  options: [
    { label: 'Services', value: data.services },
    { label: 'Jobs', value: data.jobs.length },
  ],
  loading: false,
});

const closureTimeMapper = (values, data) => ({
  ...values,
  icon: null,
  total: { days: Math.floor(data.average_closure_hours / 24), hours: Math.floor(data.average_closure_hours % 24) },
  simpleView: true,
  options: data.openings.map(o => ({
    expected: moment(o.expected, 'YYYY-MM-DD').format('MMM DD, YYYY'),
    jobName: o.job_name,
    level: o.level,
    time: `${Math.floor(o.closure_hours / 24)}d ${Math.floor(o.closure_hours % 24)}h`,
  })),
  loading: false,
});

const offersMapper = (values, data) => ({
  ...values,
  total: data.total,
  options: [
    { label: 'Waiting for feedback', value: data.waiting_feedback },
    { label: 'Job started', value: data.job_started },
    { label: 'Rejected', value: data.rejected },
  ],
  loading: false,
});

export const AnalyticsJobInfo = () => {
  const { appliedFilters } = useContext(Context);

  const [totalAssigned, setTotalAssigned] = useState({
    title: 'Assigned',
    total: 0,
    simpleView: false,
    description: 'Candidates',
    options: [],
    loading: false,
  });
  const [closureTime, setClosureTime] = useState({
    title: 'Closure time',
    total: 0,
    simpleView: false,
    description: 'Interacted',
    options: [],
    loading: false,
    contentRender: ClosureContent,
  });
  const [jobOffers, setJobOffers] = useState({
    title: 'Job offers',
    total: 0,
    simpleView: false,
    description: 'Offers made',
    options: [],
    loading: false,
  });
  const [passingStep, setPassingStep] = useState(initialOptions);
  const [rejectionNumber, setRejectionNumber] = useState(initialOptions);
  const [rejectionReason, setRejectionReason] = useState(initialOptions);
  const [rejectionStep, setRejectionStep] = useState(STEPS[0]);
  const [transitionStep, setTransitionStep] = useState(initialOptions);
  const [to, setTo] = useState({});
  const [from, setFrom] = useState({});

  const chosenTab = ANALYTICS_TABS.JOBS;

  const setLoading = () => {
    setRejectionNumber(initialOptions);
    setRejectionReason(initialOptions);
    setPassingStep(initialOptions);
    setTransitionStep(initialOptions);
    setTotalAssigned(v => ({ ...v, loading: true }));
    setClosureTime(v => ({ ...v, loading: true }));
    setJobOffers(v => ({ ...v, loading: true }));
  };

  const applyAnalyticFilter = useCallback(f => {
    setLoading();

    analyticWidgets('total-assigned', f).then(resp => setTotalAssigned(v => assignedMapper(v, resp)));
    analyticWidgets('openings-closure-time', f).then(resp => setClosureTime(v => closureTimeMapper(v, resp)));
    analyticWidgets('job-offers', f).then(resp => setJobOffers(v => offersMapper(v, resp)));
    analyticWidgets('rejections-number', f, 'number').then(resp =>
      setRejectionNumber({ options: resp, loading: false })
    );
    analyticWidgets('rejection-reasons', f, 'reason').then(resp => {
      setRejectionReason({ options: resp, loading: false });
      setRejectionStep(STEPS[0]);
    });
    analyticWidgets('steps-transition', f, 'transition').then(resp =>
      setTransitionStep({ options: resp.options, empty: resp.empty, loading: false })
    );
    analyticWidgets('steps-passing-time', f, 'passing').then(resp => {
      setPassingStep({ options: resp, empty: !resp.filter(item => !!item.data).length, loading: false });
      setFrom(resp[0]);
      setTo(resp[resp.length - 1]);
    });
  }, []);

  useEffect(() => {
    applyAnalyticFilter(appliedFilters);
  }, [appliedFilters]);

  return transitionStep.loading || (!transitionStep.loading && (!transitionStep.empty || !passingStep.empty)) ? (
    <>
      <Row gutter={[32, 0]}>
        <Col>
          <CandidateWidget className="fill-height" data={[totalAssigned, closureTime, jobOffers]} />
        </Col>
        <Col>
          <Reason
            className="fill-height"
            options={rejectionReason.options}
            loading={rejectionReason.loading}
            rejectionStep={rejectionStep}
            changeRejectionStep={setRejectionStep}
          />
        </Col>
      </Row>
      <TransitionStep
        options={transitionStep.options}
        loading={transitionStep.loading}
        empty={transitionStep.empty}
        role={chosenTab}
      />
      <PassingStep
        options={passingStep.options}
        loading={passingStep.loading}
        from={from}
        to={to}
        setFrom={setFrom}
        setTo={setTo}
      />
      <Rejection options={rejectionNumber.options} loading={rejectionNumber.loading} />
    </>
  ) : (
    <AnalyticsEmpty
      text="Please try different period or filters"
      title="There are no results for the specified parameters"
      className="shadow"
      img={Date}
    />
  );
};

const ClosureContent = ({ total, options }) => (
  <div className="widget-candidates-close-time">
    <div className="main-info">
      <Clock className="main-info__image" />
      <div className="main-info__total">
        {total.days}
        <div className="main-info__description">d</div>
        {total.hours}
        <div className="main-info__description">h</div>
      </div>
    </div>
    <div className="description-info">
      {options.map(o => (
        <div className="opening-info">
          <div className="opening-info__info">
            <Typography.Title4 weight={TYPOGRAPHY_WEIGHT.BOLD}>{o.expected}</Typography.Title4>
            <Typography.Label>
              {o.level} {o.jobName}
            </Typography.Label>
          </div>
          <div className="opening-info__time">{o.time}</div>
        </div>
      ))}
    </div>
  </div>
);
