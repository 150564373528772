import { useMemo } from 'react';

export const dataMapper = (data, isClone) =>
  Object.entries({
    name: data.name,
    service: { value: data.service.uuid, label: data.service.name },
    company: { value: data.company.uuid, label: data.company.name },
    description: data.description,
    description_initial: data.description,
    benefits: data.benefits,
    benefits_initial: data.benefits,
    team_lead: { value: data.team_lead.uuid, label: data.team_lead.full_name },
    recruiters: isClone ? [] : data.recruiters.map(r => ({ value: r.uuid, label: r.full_name })),
    recruiter_leads: data.recruiter_leads?.map(r => ({ value: r.uuid, label: r.full_name })) || [],
    resource_manager: { value: data.resource_manager.uuid, label: data.resource_manager.full_name },
    service_head: data.service.service_head
      ? { value: data.service.service_head.uuid, label: data.service.service_head.full_name }
      : { value: undefined, label: 'Service Head is not assigned to the Service' },
    reassign_recruiters: isClone ? [] : data.recruiters,
  }).map(([name, value]) => ({ name, value }));

export const formDataMapper = data => ({
  ...data,
  service: data.service.value,
  team_lead: data.team_lead.value,
  recruiters: data.recruiters?.map(r => r.value) || [],
  recruiter_leads: data.recruiter_leads?.map(r => r.value) || [],
  service_head: undefined,
  resource_manager: undefined,
  company: undefined,
});

export const userMapper = list => list.map(el => ({ value: el.uuid, label: el.full_name }));

export const useServiceOptions = services =>
  useMemo(
    () =>
      services.map(el => ({
        value: el.uuid,
        label: el.name,
        resource_manager: { value: el.resource_manager.uuid, label: el.resource_manager.name },
        service_head: el.service_head
          ? { value: el.service_head.uuid, label: el.service_head.full_name }
          : { value: undefined, label: 'Service Head is not assigned to the Service' },
        company: { value: el.company.uuid, label: el.company.name },
      })),
    [services]
  );
