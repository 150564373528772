import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const location = useLocation();
  return useMemo(() => {
    const obj = {};
    const params = location.search.replace('?', '').split('&');
    params.forEach(p => {
      const key = p.split('=')[0];
      const val = p.split('=')[1];
      try {
        obj[key] = JSON.parse(decodeURIComponent(val));
      } catch (e) {
        obj[key] = val;
      }
    });
    return obj;
  }, [location.search]);
};
