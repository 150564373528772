import React from 'react';
import './contextMenu.scss';
import classNames from 'classnames';
import { Menu } from '../Icons';

export const AtsContextMenu = ({ children, className }) =>
  React.Children.toArray(children).filter(el => el).length ? (
    <div className={classNames('ats-content-menu', className)} tabIndex="0">
      <Menu />
      <ul>{children}</ul>
    </div>
  ) : null;
