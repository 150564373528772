const validator = (_, value) => {
  if (/^\d+$/.test(value) && +value >= 0 && +value <= 9) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Should be in range 0 - 9'));
};

export const RULES = {
  PERIOD: [{ required: true, message: 'Should be in range 0 - 9', validator }],
  DATE: [{ required: true, message: 'This field is required' }],
};
