import React from 'react';

export const linkModifier = ({ type, link, ...props }, text) => {
  switch (type) {
    case 'Phone':
      return {
        type,
        link: `tel:${link}`,
        label: (
          <>
            <div>{link}</div>
            <div>{text === `tel:${link + type}` ? 'Copied' : 'Copy link'}</div>
          </>
        ),
        ...props,
      };
    case 'Email':
      return {
        type,
        link: `mailto:${link}`,
        label: (
          <>
            <div>{link}</div>
            <div>{text === `mailto:${link + type}` ? 'Copied' : 'Copy link'}</div>
          </>
        ),
        ...props,
      };
    case 'Skype':
      return { type, link: `skype:${link}?chat`, ...props };
    default:
      return { type, link, ...props };
  }
};
