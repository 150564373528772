import React, { useState } from 'react';
import cn from 'classnames';
import { Select, AtsSkeleton, Card } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { STEPS } from 'constants/constants';
import { ANALYTICS_COLORS } from 'constants/colors';
import { ReactComponent as Empty } from 'dist/emptyStates/stepsNoCandidates.svg';
import { DoughnutGraphic } from './doughnut';
import { useDonutData } from './utils';
import './reason.scss';

export const Reason = ({ className, options, loading }) => {
  const [rejectionStep, changeRejectionStep] = useState(STEPS[0]);
  const reasons = options.find(item => item.type === rejectionStep.value)?.reasons || [];
  const data = useDonutData(reasons);
  const count = reasons.reduce((p, o) => p + o.value, 0);

  if (loading) {
    return (
      <Card className={cn('widget-rejection-reasons loading', className)}>
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Rejection reasons</Typography.Title2>
        <AtsSkeleton height={200} />
      </Card>
    );
  }

  return (
    <Card className={cn('widget-rejection-reasons', className)}>
      <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Rejection reasons</Typography.Title2>
      <div className="widget-rejection-reasons__content">
        <div className="left-block">
          <Select label="Steps" options={STEPS} value={rejectionStep} onChange={changeRejectionStep} />
          <ul>
            {reasons.map((reason, idx) => (
              <li>
                <div className="indicator" style={{ backgroundColor: ANALYTICS_COLORS[idx] }} />
                {reason.name}
              </li>
            ))}
          </ul>
        </div>
        {count ? (
          <div className="right-block">
            <DoughnutGraphic data={data} options={reasons} count={count} />
          </div>
        ) : (
          <Empty className="right-block__empty" />
        )}
      </div>
    </Card>
  );
};
