import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  filter: {},
  settings: {
    countries: [],
    companies: [],
    tags: [],
    specialities: [],
  },
  reminders: [],
  recruiters: [],
  jobs: [],
  jobsLoading: false,
  interactions: {},
  candidateData: {
    phone: [],
    email: [],
    skype: [],
    telegram: [],
    technologies: [],
    social_networks: [],
    assignments: [],
    experience: [],
    cvs: [],
    cvs_links: [],
    specialities: [],
    sources: [],
  },
  timeline: {
    data: [],
    count: 0,
    sorting: 'desc',
    filter: '',
  },
  candidateDuplicates: [],
  sources: [],
};

const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    changeCandidateFilter(state, { payload }) {
      state.filter = payload;
    },
    setCountries(state, { payload }) {
      state.settings.countries = payload;
    },
    setResponsibleRecruiter(state, { payload }) {
      state.recruiters = payload;
    },
    setCompanies(state, { payload }) {
      state.settings.companies = payload;
    },
    setSpecialties(state, { payload }) {
      state.settings.specialities = payload;
    },
    setTags(state, { payload }) {
      state.settings.tags = payload;
    },
    setDuplicateCandidates(state, { payload }) {
      state.candidateDuplicates = payload;
    },
    setCandidateData(state, { payload }) {
      state.candidateData = payload;
    },
    setRemindersList(state, { payload }) {
      state.reminders = payload;
    },
    setJobsList(state, { payload }) {
      state.jobs = payload;
    },
    setCandidateJobsListLoading(state, { payload }) {
      state.jobsLoading = payload;
    },
    setInteractions(state, { payload }) {
      state.interactions = payload;
    },
    setCandidateTimeline(state, { payload }) {
      if (payload.clear) {
        state.timeline.data = [];
        state.timeline.offset = 0;
        state.timeline.count = 0;
      } else {
        const data = [...state.timeline.data, ...payload.data];
        state.timeline.data = data;
        state.timeline.offset = data.length;
        state.timeline.count = payload.count;
      }
    },
    setCandidateTimelineFilters(state, { payload }) {
      state.timeline.filter = payload.filter;
      state.timeline.sorting = payload.sorting;
      state.timeline.offset = 0;
    },
    setCandidateDocuments(state, { payload }) {
      state.candidateData.cvs = payload;
    },
    setSources(state, { payload }) {
      state.sources = payload;
    },
    clearCandidateData(state) {
      state.candidateData = { ...initialState.candidateData };
    },
    resetCandidateReducer(state) {
      state = initialState;
    },
  },
});
export const {
  changeCandidateFilter,
  setCountries,
  setResponsibleRecruiter,
  setCompanies,
  setSpecialties,
  setTags,
  setDuplicateCandidates,
  setCandidateData,
  setRemindersList,
  setJobsList,
  setCandidateJobsListLoading,
  setInteractions,
  setCandidateTimeline,
  setCandidateTimelineFilters,
  setCandidateDocuments,
  clearCandidateData,
  setSources,
  resetCandidateReducer,
} = candidateSlice.actions;
export default candidateSlice.reducer;
