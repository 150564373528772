import React from 'react';
import { Checkbox as CheckboxAntd, Form } from 'antd';
import cn from 'classnames';
import { Typography } from '_atoms/Typography';
import './style.scss';

export const Checkbox = ({ className, label, isSelected, itemProps, ...props }) => (
  <div className={cn('checkbox-default', className)}>
    <Form.Item valuePropName="checked" {...itemProps}>
      <CheckboxAntd checked={isSelected} {...props}>
        <Typography.Text>{label}</Typography.Text>
      </CheckboxAntd>
    </Form.Item>
  </div>
);
