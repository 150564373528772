import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import cn from 'classnames';
import { AtsSkeleton, Card, Tabs } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Context } from '_templates/AnalyticsDashboard/analytics-dashboard';
import { ANALYTICS_TABS, STEPS } from 'constants/constants';
import { ANALYTICS_COLORS } from 'constants/colors';
import { TransitionStep } from '_templates/AnalyticsDashboard/TransitionStep/transition-step';
import { AnalyticsEmpty } from '_templates/AnalyticsDashboard/AnalyticsEmpty/analytics-empty';
import { analyticWidgets } from 'actions/analyticActions';
import { Reason } from '_templates/AnalyticsDashboard/Reason/reason';
import { PassingStep } from '_templates/AnalyticsDashboard/PassingStep/passing-step';
import { Rejection } from '_templates/AnalyticsDashboard/Rejection/rejection';
import { assignedDataMapper, interactedDataMapper, jobOffersDataMapper } from '_templates/AnalyticsDashboard/helpers';
import {
  ASSIGNED_CONFIG,
  initialOptions,
  INTERACTED_CONFIG,
  JOB_OFFERS_CONFIG,
} from '_templates/AnalyticsDashboard/constans';
import { ReactComponent as UserImage } from 'dist/icons/avatar-placeholder.svg';
import Date from 'dist/images/common/icons/graph.svg';
import './style.scss';

export const AnalyticsRecruitersInfo = () => {
  const { appliedFilters } = useContext(Context);

  const [interacted, setInteracted] = useState(INTERACTED_CONFIG);
  const [totalAssigned, setTotalAssigned] = useState(ASSIGNED_CONFIG);
  const [jobOffers, setJobOffers] = useState(JOB_OFFERS_CONFIG);
  const [transitionStep, setTransitionStep] = useState(initialOptions);
  const [passingStep, setPassingStep] = useState(initialOptions);
  const [rejectionNumber, setRejectionNumber] = useState(initialOptions);
  const [rejectionReason, setRejectionReason] = useState(initialOptions);
  const [rejectionStep, setRejectionStep] = useState(STEPS[0]);
  const [to, setTo] = useState({});
  const [from, setFrom] = useState({});

  const chosenTab = ANALYTICS_TABS.RECRUITERS;

  const setLoading = () => {
    setRejectionNumber(initialOptions);
    setRejectionReason(initialOptions);
    setPassingStep(initialOptions);
    setTransitionStep(initialOptions);
    setInteracted(v => ({ ...v, loading: true }));
    setTotalAssigned(v => ({ ...v, loading: true }));
    setJobOffers(v => ({ ...v, loading: true }));
  };

  const applyAnalyticFilter = useCallback(filterOptions => {
    setLoading();

    analyticWidgets('interacted', filterOptions).then(resp => setInteracted(interactedDataMapper(resp)));
    analyticWidgets('total-assigned', filterOptions).then(resp =>
      setTotalAssigned(assignedDataMapper(resp, filterOptions))
    );
    analyticWidgets('job-offers', filterOptions).then(resp => setJobOffers(jobOffersDataMapper(resp)));
    analyticWidgets('rejections-number', filterOptions, 'number').then(resp =>
      setRejectionNumber({ options: resp, loading: false })
    );
    analyticWidgets('rejection-reasons', filterOptions, 'reason').then(resp => {
      setRejectionReason({ options: resp, loading: false });
      setRejectionStep(STEPS[0]);
    });
    analyticWidgets('steps-transition', filterOptions, 'transition').then(resp =>
      setTransitionStep({ options: resp.options, empty: resp.empty, loading: false })
    );
    analyticWidgets('steps-passing-time', filterOptions, 'passing').then(resp => {
      setPassingStep({ options: resp, empty: !resp.filter(item => !!item.data).length, loading: false });
      setFrom(resp[0]);
      setTo(resp[resp.length - 1]);
    });
  }, []);

  useEffect(() => {
    applyAnalyticFilter(appliedFilters);
  }, [appliedFilters]);

  return transitionStep.loading || (!transitionStep.loading && (!transitionStep.empty || !passingStep.empty)) ? (
    <>
      <Row gutter={[32, 0]}>
        <Col>
          <CandidateWidget className="fill-height" data={[interacted, totalAssigned, jobOffers]} />
        </Col>
        <Col>
          <Reason
            className="fill-height"
            options={rejectionReason.options}
            loading={rejectionReason.loading}
            rejectionStep={rejectionStep}
            changeRejectionStep={setRejectionStep}
          />
        </Col>
      </Row>
      <TransitionStep
        options={transitionStep.options}
        loading={transitionStep.loading}
        empty={transitionStep.empty}
        role={chosenTab}
        filter={appliedFilters}
      />
      <PassingStep
        options={passingStep.options}
        loading={passingStep.loading}
        from={from}
        to={to}
        setFrom={setFrom}
        setTo={setTo}
      />
      <Rejection options={rejectionNumber.options} loading={rejectionNumber.loading} />
    </>
  ) : (
    <AnalyticsEmpty
      text="Please try different period or filters"
      title="There are no results for the specified parameters"
      className="shadow"
      img={Date}
    />
  );
};

const Line = ({ data }) => {
  const total = useMemo(() => data.reduce((sum, { value }) => sum + value, 0), [data]);
  if (!total || !data.length) return null;
  return (
    <div className="line-graphic">
      {data.map(({ value }, i) => (
        <div key={value + i} style={{ backgroundColor: ANALYTICS_COLORS[i], width: `${value / total / 0.01}%` }} />
      ))}
    </div>
  );
};

export const CandidateWidget = ({ data, className }) => {
  const [tab, setTab] = useState('0');
  const tabs = useMemo(() => data.map((d, id) => ({ key: `${id}`, label: d.title })), [data]);
  const {
    total = 0,
    description,
    options,
    simpleView,
    loading,
    contentRender,
    empty,
    extra,
    extra_description,
    mainInfoOnly,
  } = data[tab];

  return (
    <Card className={cn('widget-candidates', className)}>
      {tabs.length > 1 ? (
        <Tabs className="widget-candidates__tabs" activeTab={tab} onChange={setTab} items={tabs} />
      ) : (
        <Typography.Title3 className="widget-candidates__title">{tabs[0].label}</Typography.Title3>
      )}
      <div className="widget-candidates__content">
        {loading ? (
          <AtsSkeleton height={100} />
        ) : empty ? (
          <div className="widget-candidates__empty">
            <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>Not applicable</Typography.Title3>
            <Typography.Text>Try another filtering criteria</Typography.Text>
          </div>
        ) : contentRender ? (
          contentRender(data[tab])
        ) : (
          <>
            <div className="main-info">
              <UserImage className="main-info__image" />
              <div>
                <div className="main-info__total">
                  {total}
                  <span>{description}</span>
                </div>
                {!!extra && (
                  <div className="main-info__extra">
                    {extra}
                    <span>{extra_description}</span>
                  </div>
                )}
              </div>
            </div>
            {!mainInfoOnly &&
              (simpleView ? (
                <Row gutter={[16, 24]} className="description-info simple-view">
                  {options.map((v, i) => (
                    <Col key={v.value + i} className="info-block">
                      <Typography.Label>{v.label}</Typography.Label>
                      <Typography.Title3 weight="bold">{v.value}</Typography.Title3>
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="description-info full-view">
                  <Line data={options} />
                  <Row gutter={[16, 24]} className="full-view__items">
                    {options.map((v, i) => (
                      <Col key={v.value + i} className="item">
                        <Typography.Label className="item__title">{v.label}</Typography.Label>
                        <div className="item__info">
                          <div className="color" style={{ backgroundColor: ANALYTICS_COLORS[i] }} />
                          <Typography.Title3 className="label">{v.value}</Typography.Title3>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
          </>
        )}
      </div>
    </Card>
  );
};
