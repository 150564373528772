import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, BUTTON_TYPES, Card } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { getUser } from 'store/selectors/authorizeSelectors';
import history from 'helpers/history';
import { ROUTES } from 'router/constants';
import { ERROR } from './constans';
import './style.scss';

export const NotFound = () => {
  const user = useSelector(getUser);

  useEffect(() => {
    if (!window.localStorage.getItem('access_token') && !user) {
      window.localStorage.setItem('link_to_page', window.location.pathname);
    }
  }, []);

  const buttonClickHandler = () => {
    if (!!window.localStorage.getItem('access_token') && !!user) history.push(ROUTES.DASHBOARD);
    else history.push(ROUTES.LOGIN);
  };

  return (
    <div className="not-found-page">
      <Card className="not-found-card">
        <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>{ERROR.NOT_ACCESSED_PAGE}</Typography.Title3>
        <Typography.Text>{ERROR.OLD_VERSION}</Typography.Text>
        <Button type={BUTTON_TYPES.PRIMARY} onClick={buttonClickHandler}>
          {ERROR.GO_TO_DASHBOARD}
        </Button>
      </Card>
    </div>
  );
};
