import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  candidate_loading: false,
  steps_loading: false,
  openings_loading: false,
  hr_managers: [],
  recruiterLeads: [],
  list_hrl: [],
  services: [],
  locations: [],
  languages: [],
  levels: [],
  positions: [],
  companies: [],
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setCandidateLoading(state, { payload }) {
      state.candidate_loading = payload;
    },
    setStepsLoading(state, { payload }) {
      state.steps_loading = payload;
    },
    setOpeningsLoading(state, { payload }) {
      state.openings_loading = payload;
    },
    setListServices(state, { payload }) {
      state.services = payload;
    },
    setListLocations(state, { payload }) {
      state.locations = payload;
    },
    setListHrManagers(state, { payload }) {
      state.hr_managers = payload;
    },
    setRecruiterLeads(state, { payload }) {
      state.recruiterLeads = payload;
    },
    setListHRL(state, { payload }) {
      state.list_hrl = payload;
    },
    setForeignLanguages(state, { payload }) {
      state.languages = payload;
    },
    setLevels(state, { payload }) {
      state.levels = payload;
    },
    setPositions(state, { payload }) {
      state.positions = payload;
    },
    setAllCompanies(state, { payload }) {
      state.companies = payload;
    },
    resetCommonReducer(state) {
      state = initialState;
    },
  },
});

export const {
  setLoading,
  setCandidateLoading,
  setStepsLoading,
  setOpeningsLoading,
  setListServices,
  setListLocations,
  setListHrManagers,
  setRecruiterLeads,
  setListHRL,
  setForeignLanguages,
  setLevels,
  setPositions,
  setAllCompanies,
  resetCommonReducer,
} = commonSlice.actions;
export default commonSlice.reducer;
