import React from 'react';
import moment from 'moment';
import { Popup } from '_atoms';
import { Typography } from '_atoms/Typography';
import './style.scss';

export const PopupCancelInterview = ({ open, rooms = [], link, startDate, endDate, onClose, onConfirm }) => {
  const date = `${moment(startDate).format('ddd MMM DD, YYYY H:mm')} – ${moment(endDate).format('H:mm')}`;
  return (
    <Popup
      className="popup-cancel-interview"
      open={open}
      title="Cancel interview"
      cancelButtonProps={{ onClick: onClose, children: 'back to Job' }}
      okButtonProps={{ onClick: onConfirm, children: 'Cancel interview' }}
    >
      <div className="info-block">
        <Typography.Label>Date and time</Typography.Label>
        <Typography.Text>{date}</Typography.Text>
      </div>
      <div className="info-block">
        <Typography.Label>
          Conference rooms: {rooms.map(el => el.name).join(', ') || 'no rooms assigned'}
        </Typography.Label>
        <Typography.Text>
          {link ? (
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          ) : (
            'no link'
          )}
        </Typography.Text>
      </div>
      <Typography.Text>Are you sure you want to cancel interview?</Typography.Text>
    </Popup>
  );
};
