import React from 'react';
import cn from 'classnames';
import { Button as ButtonAntd } from 'antd';
import { BUTTON_TYPES } from './canstants';
import './style.scss';

export const Button = ({ children, className, type = BUTTON_TYPES.SECONDARY, ...props }) => (
  <ButtonAntd className={cn('button-default', className)} type={type} {...props}>
    {children}
  </ButtonAntd>
);
