import { STATUS_TYPES } from '_atoms/Status';

export const OPENING_STATUSES = {
  SH_APPROVAL: 'Pending SH approval',
  RM_APPROVAL: 'Pending RM approval',
  LM_APPROVAL: 'Pending LM approval',
  HRL_APPROVAL: 'Pending HRL approval',
  OPEN: 'Open',
  WAITING_FOR_ENTRY: 'Waiting for entry',
  DECLINED: 'Declined',
  HIRED: 'Hired',
  CLOSED: 'Closed',
};

export const OPENING_STATUSES_SHORT = {
  OPEN: 'Open',
  PENDING_APPROVAL: 'Pending Approval',
  WAITING_FOR_ENTRY: 'Waiting for entry',
  CLOSED: 'Closed',
};

export const OPENING_STATUS_TYPES = {
  [OPENING_STATUSES.SH_APPROVAL]: STATUS_TYPES.BLUE,
  [OPENING_STATUSES.RM_APPROVAL]: STATUS_TYPES.BLUE,
  [OPENING_STATUSES.LM_APPROVAL]: STATUS_TYPES.BLUE,
  [OPENING_STATUSES.HRL_APPROVAL]: STATUS_TYPES.BLUE,
  [OPENING_STATUSES.OPEN]: STATUS_TYPES.GREEN,
  [OPENING_STATUSES.WAITING_FOR_ENTRY]: STATUS_TYPES.YELLOW,
  [OPENING_STATUSES.CLOSED]: STATUS_TYPES.GRAY,
  [OPENING_STATUSES.HIRED]: STATUS_TYPES.GREEN,
  [OPENING_STATUSES.DECLINED]: STATUS_TYPES.RED,
};
