import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { AtsSkeleton, Button, BUTTON_TYPES, Card, STATUS_TYPES, Tooltip } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { getJobStatusType } from '_molecules/JobHeader/utils';
import { getHRLJobsDashboardAPI, getRecruiterLeadJobsDashboardAPI } from 'actions';
import { JOB_STATUSES } from 'constants/job';
import { ReactComponent as JobImage } from 'dist/emptyStates/dashboardOpening.svg';
import './style.scss';

export const NewJobs = React.memo(({ trigger, isHrl }) => {
  const [jobs, setJobs] = useState([]);
  const [allShown, setAllShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const onShowMoreClick = () => setAllShown(s => !s);

  useEffect(() => {
    setLoading(true);
    (isHrl ? getHRLJobsDashboardAPI() : getRecruiterLeadJobsDashboardAPI())
      .then(setJobs)
      .finally(() => setLoading(false));
  }, [trigger]);

  if (loading) {
    return (
      <Card className="new-jobs">
        <div className="new-jobs__header">
          New jobs<span className="number">{jobs.length}</span>
        </div>
        <div className="new-jobs__content-loading">
          <AtsSkeleton height={140} />
        </div>
      </Card>
    );
  }

  if (!jobs.length) {
    return (
      <Card className="new-jobs">
        <div className="new-jobs__header">
          New jobs<span className="number">0</span>
        </div>
        <div className="new-jobs__content-empty">
          <JobImage className="empty-image" />
          <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You don&apos;t have new jobs yet</Typography.Title3>
        </div>
      </Card>
    );
  }

  return (
    <Card className={cn('new-jobs', { allShown })}>
      <div className="new-jobs__header">
        New jobs<span className="number">({jobs.length})</span>
      </div>
      <div className="new-jobs__content">
        {jobs.map(job => (
          <div key={job.uuid} className="new-job">
            <JobImage className="new-job__image" />
            <div className="new-job__info">
              <Tooltip label={job.name}>
                <Link to={`/jobs/${job.uuid}/show/description`} target="_blank">
                  {job.name}
                </Link>
              </Tooltip>
              <Typography.Caption>{job.service}</Typography.Caption>
              <Status type={getJobStatusType(job.status)}>{job.status}</Status>
              {!!job.opening_status && (
                <>
                  <Typography.Caption>Opening status</Typography.Caption>
                  <span className="openings-info">
                    <Status type={STATUS_TYPES.BLUE}>{job.opening_status}</Status>
                    {job.openings_count > 1 && <Typography.Caption>+ {job.openings_count - 1} more</Typography.Caption>}
                  </span>
                </>
              )}
            </div>
            {[JOB_STATUSES.APPROVED, JOB_STATUSES.OPEN].includes(job.status) && (
              <div className="new-job__action">
                <Link to={`/jobs/${job.uuid}/show/description?team=true`} target="_blank">
                  <Button type={BUTTON_TYPES.PRIMARY}>Assign recruiters</Button>
                </Link>
              </div>
            )}
          </div>
        ))}
        <Button className="show-more" type={BUTTON_TYPES.TEXT} onClick={onShowMoreClick}>
          {allShown ? 'Show less' : 'Show more'}
        </Button>
      </div>
    </Card>
  );
});
