import moment from 'moment';
import { dateFormatter } from 'helpers/dateFormatter';
import { OPENING_STATUSES } from 'constants/opening';

export const serviceTableDataMapper = services =>
  services.map(service => ({
    uuid: service.uuid,
    name: service.name,
    count: service.openings_count,
    candidates_count: service.candidates_count,
    openings_count: service.total_openings_count,
    openings: service.openings.map(opening => ({
      uuid: opening.uuid,
      job: {
        job_name: opening.job_name,
        job_uuid: opening.job_uuid,
        priority: opening.priority,
      },
      location: opening.locations.join(', ') || '-',
      level: opening.custom_level || opening.level,
      creation_date: dateFormatter(opening.created),
      expected_date: {
        date: dateFormatter(opening.expected),
        error:
          opening.status === OPENING_STATUSES.OPEN &&
          moment(opening.expected, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day')),
      },
      team_lead: opening.team_lead.full_name,
      status: opening.status,
      action: {
        status: opening.status,
        job_uuid: opening.job_uuid,
        job_name: opening.job_name,
        service_uuid: opening.uuid,
        opening,
      },
    })),
  }));

export const jobTableDataMapper = jobs =>
  jobs.map(job => ({
    uuid: job.uuid,
    name: job.name,
    count: job.openings_count,
    service: job.service_name,
    candidates_count: job.candidates_count,
    openings_count: job.total_openings_count,
    openings: job.openings.map(opening => ({
      uuid: opening.uuid,
      location: opening.locations.join(', ') || '-',
      level: { priority: opening.priority, level: opening.custom_level || opening.level },
      creation_date: dateFormatter(opening.created),
      expected_date: {
        date: dateFormatter(opening.expected),
        error:
          opening.status === OPENING_STATUSES.OPEN &&
          moment(opening.expected, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day')),
      },
      team_lead: opening.team_lead.full_name,
      status: opening.status,
      action: {
        status: opening.status,
        job_uuid: opening.job_uuid,
        job_name: opening.job_name,
        service_uuid: opening.uuid,
        opening,
      },
    })),
  }));

export const locationTableDataMapper = locations =>
  locations.map(location => ({
    uuid: location.uuid,
    name: location.name,
    count: location.openings_count,
    openings_count: location.total_openings_count,
    openings: location.openings.map(opening => ({
      uuid: opening.uuid,
      job: {
        job_name: opening.job_name,
        job_uuid: opening.job_uuid,
        priority: opening.priority,
      },
      level: opening.custom_level || opening.level,
      creation_date: dateFormatter(opening.created),
      expected_date: {
        date: dateFormatter(opening.expected),
        error:
          opening.status === OPENING_STATUSES.OPEN &&
          moment(opening.expected, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day')),
      },
      team_lead: opening.team_lead.full_name,
      status: opening.status,
      action: {
        status: opening.status,
        job_uuid: opening.job_uuid,
        job_name: opening.job_name,
        service_uuid: opening.uuid,
        opening,
      },
    })),
  }));
