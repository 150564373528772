import { combineReducers } from 'redux';

import authorize, { initialState as authorizeInitialState } from './authorizeReducer';
import candidate, { initialState as candidateInitialState } from './candidateReducer';
import common, { initialState as commonInitialState } from './commonReducer';
import job, { initialState as jobInitialState } from './jobReducer';
import dashboard, { initialState as dashboardInitialState } from './dashboardReducer';

export const rootReducer = combineReducers({
  authorize,
  candidate,
  common,
  job,
  dashboard,
});

export const initialState = {
  authorize: authorizeInitialState,
  candidate: candidateInitialState,
  common: commonInitialState,
  job: jobInitialState,
  dashboard: dashboardInitialState,
};
