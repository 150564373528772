import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { RichText, Status, STATUS_TYPES } from '_atoms';
import { getCandidateStatusColor } from '_templates/Candidates/utils';
import { getCandidateJobs } from 'store/selectors/candidateSelectors';
import { dateFormatter } from 'helpers/dateFormatter';
import { STATUSES } from '../../constants/user';

const getFieldName = name => {
  switch (name) {
    case 'cvs_links':
      return 'Cv links';
    default:
      return name.replace(/^[a-z]/, match => match.toUpperCase()).replace(/_/g, ' ');
  }
};

export const useTimelineItemTitle = ({ data, datetime }) => {
  const { id, candidate } = useParams();
  const jobs = useSelector(getCandidateJobs);
  const job = jobs.find(j => j.uuid === data.details?.job?.uuid);

  const isReminderInteraction = data.group_name === 'reminder' || data.group_name === 'interactions';
  const user = isReminderInteraction
    ? data.details?.recruiter.full_name || data.recruiter.full_name
    : data.author?.full_name || 'Technical User';
  const date = dateFormatter(
    data.group_name === 'interactions' ? data.details.datetime : datetime,
    'MMM DD, YYYY [at] HH:mm'
  );

  let title = '';
  const content = [];
  const previous_content = {};

  switch (data.group_name) {
    case 'reminder':
      title = `${user}, ${data.type} on ${date}`;
      if (data.comment) content.push({ field: 'Comment', value: <RichText text={data.comment} /> });
      break;
    case 'interactions':
      title = `${user}, ${data.details.type} on ${date}`;
      if (data.details.comment) content.push({ field: 'Comment', value: <RichText text={data.details.comment} /> });
      break;
    case 'profile':
      switch (data.type) {
        case 'profile_created':
          title = `${user} has created the profile`;
          if (data.details.vacancy)
            content.push({
              field: 'Applied for vacancy',
              value: (
                <a href={data.details.vacancy.page_url} target="_blank" rel="noopener noreferrer">
                  {data.details.vacancy.name}
                </a>
              ),
            });
          break;
        case 'changelog':
          title = `${user} has updated the profile`;
          data.details.changes.forEach(v => {
            const value =
              v.to_value === 'True'
                ? 'Yes'
                : v.to_value === 'False'
                ? 'No'
                : ['worked_till', 'worked_from'].includes(v.field)
                ? dateFormatter(v.to_value)
                : v.to_value;
            const prevValue = ['worked_till', 'worked_from'].includes(v.field)
              ? dateFormatter(v.to_value)
              : v.from_value?.includes('https://') || v.from_value?.includes('http://')
              ? `<a href=${v.from_value} target="_blank" rel="noopener noreferrer">${v.from_value}</a>`
              : v.from_value;
            content.push({
              field: getFieldName(v.field),
              value: value ? <RichText text={value} /> : <RichText className="deleted" text={prevValue} />,
              prevValue: value ? prevValue ? <RichText text={prevValue} /> : 'None' : undefined,
            });
          });
          break;
        case 'profile_updated':
          title = `${user} has reviewed the candidate`;
          data.details.updated_values.forEach(v => {
            content.push({
              field: v.field.replace(/^[a-z]/, match => match.toUpperCase()).replace(/_/g, ' '),
              value: <RichText text={v.to} />,
              prevValue: v.from ? <RichText text={v.from} /> : undefined,
            });
          });
          break;
        case 'finish_review':
          title = `${user} has reviewed the candidate`;
          break;
      }
      break;
    case 'status':
      title = `${user} has changed candidate status`;
      if (data.details.status && data.details.previous_status) {
        content.push({
          field: 'Status',
          value: <Status type={getCandidateStatusColor(data.details.status)}>{data.details.status}</Status>,
          prevValue: (
            <Status type={getCandidateStatusColor(data.details.previous_status)}>{data.details.previous_status}</Status>
          ),
        });
      }
      switch (data.type) {
        case 'active':
          if (!data.details.status && !data.details.previous_status) {
            content.push({
              field: 'Status',
              value: <Status type={getCandidateStatusColor(STATUSES.ACTIVE)}>{STATUSES.ACTIVE}</Status>,
              prevValue: <Status type={getCandidateStatusColor(STATUSES.PASSIVE)}>{STATUSES.PASSIVE}</Status>,
            });
          }
          content.push({
            field: 'Job assigned',
            value: <a href={`/jobs/${data.details.job.uuid}/show/description`}>{data.details.job.name}</a>,
          });
          break;
        case 'passive':
          if (!data.details.status && !data.details.previous_status) {
            content.push({
              field: 'Status',
              value: <Status type={getCandidateStatusColor(STATUSES.PASSIVE)}>{STATUSES.PASSIVE}</Status>,
              prevValue: <Status type={getCandidateStatusColor(STATUSES.ACTIVE)}>{STATUSES.ACTIVE}</Status>,
            });
          }
          if (data.details.decision) {
            content.push({
              field: 'Rejected in the job',
              value: <a href={`/jobs/${data.details.job.uuid}/show/description`}>{data.details.job.name}</a>,
            });
            content.push({
              field: 'Rejection reason',
              value: data.details.decision.reason,
            });
          }
          break;
        case 'blacklisted':
          if (!data.details.status && !data.details.previous_status) {
            content.push({
              field: 'Status',
              value: <Status type={getCandidateStatusColor(STATUSES.BLACKLISTED)}>{STATUSES.BLACKLISTED}</Status>,
              prevValue: <Status type={getCandidateStatusColor(STATUSES.PASSIVE)}>{STATUSES.PASSIVE}</Status>,
            });
          }
          content.push({
            field: 'Comment',
            value: <RichText text={data.details.comment} />,
          });
          break;
        case 'hired':
          if (!data.details.status && !data.details.previous_status) {
            content.push({
              field: 'Status',
              value: <Status type={getCandidateStatusColor(STATUSES.HIRED)}>{STATUSES.HIRED}</Status>,
              prevValue: <Status type={getCandidateStatusColor(STATUSES.ACTIVE)}>{STATUSES.ACTIVE}</Status>,
            });
          }
          content.push({
            field: 'Hired to the job',
            value: <a href={`/jobs/${data.details.job.uuid}/show/hired`}>{data.details.job.name}</a>,
          });
          break;
        case 'fired':
          if (!data.details.status && !data.details.previous_status) {
            content.push({
              field: 'Status',
              value: <Status type={getCandidateStatusColor(STATUSES.FIRED)}>{STATUSES.FIRED}</Status>,
              prevValue: <Status type={getCandidateStatusColor(STATUSES.HIRED)}>{STATUSES.HIRED}</Status>,
            });
          }
          if (data.details.job) {
            content.push({
              field: 'Fired from the job',
              value: <a href={`/jobs/${data.details.job.uuid}/show/hired`}>{data.details.job.name}</a>,
            });
          }
          content.push({
            field: 'Comment',
            value: <RichText text={data.details.comment} />,
          });
          break;
      }
      break;
    case 'job_updates':
      // eslint-disable-next-line no-case-declarations
      const jobLink = job ? (
        <a
          href={
            job.active_step
              ? `/jobs/${job.uuid}/show/steps/${job.active_step.uuid}/${id}`
              : `/jobs/${job.uuid}/show/hired`
          }
          target="_blank"
          rel="noreferrer"
        >
          {data.details.job.name}
        </a>
      ) : !candidate ? (
        <a href={`/jobs/${data.details.job.uuid}/show/description`} target="_blank" rel="noreferrer">
          {data.details.job.name}
        </a>
      ) : (
        data.details.job.name
      );
      switch (data.type || data.event_type) {
        case 'assigned_to_job':
          title = (
            <>
              {user} has assigned the candidate to the job {jobLink}
            </>
          );
          break;
        case 'withdrawn_from_job':
          title = (
            <>
              {user} has withdrawn the candidate from the job {jobLink}
            </>
          );
          if (data.details.withdraw.comment) {
            content.push({
              field: 'Comment',
              value: <RichText text={data.details.withdraw.comment} />,
            });
          }
          break;
        case 'moved_within_job':
          title = (
            <>
              {user} has moved the candidate within the job {jobLink}
            </>
          );
          if (data.details.from_step && data.details.to_step) {
            content.push({
              field: 'Step',
              value: data.details.to_step.type,
              prevValue: data.details.from_step?.type,
            });
          }
          break;
        case 'interview_scheduled':
        case 'interview_changed':
        case 'interview_canceled':
          title = (
            <>
              {user} has{' '}
              {(data.type || data.event_type) === 'interview_scheduled'
                ? 'scheduled'
                : (data.type || data.event_type) === 'interview_changed'
                ? 'changed'
                : 'canceled'}{' '}
              interview in the job {jobLink}
            </>
          );
          content.push({
            field: 'Date and time',
            value: `${dateFormatter(
              `${data.details.interview.date} ${data.details.interview.from_time}`,
              'ddd MMM DD, YYYY H:mm'
            )} - ${dateFormatter(`${data.details.interview.date} ${data.details.interview.to_time}`, 'H:mm')}`,
          });
          content.push({
            field: 'Approvers',
            value: data.details.interview.attendees.map(u => u.full_name).join(', '),
          });
          break;
        case 'assigned_to_opening':
          title = (
            <>
              {user} has assigned the candidate to the opening within the job {jobLink}
            </>
          );
          if (data.details.opening) {
            content.push({
              field: 'Opening assigned',
              value: `${data.details.opening.level} ${data.details.job.name} expected on ${moment(
                data.details.opening.expected
              ).format('MMM DD, YYYY')}`,
            });
          }
          if (data.details.location) {
            content.push({
              field: 'Location',
              value: `${data.details.location.city} - ${data.details.location.name}`,
            });
          }
          if (data.details.entry_date) {
            content.push({
              field: 'Entry date',
              value: dateFormatter(data.details.entry_date),
            });
          }
          if (data.details.comment) {
            content.push({
              field: 'Comment',
              value: <RichText text={data.details.comment} />,
            });
          }
          break;
        case 'hiring_information_updated':
          title = (
            <>
              {user} has updated hiring information for the candidate in the job {jobLink}
            </>
          );
          data.details.updated_values.forEach(v => {
            content.push({
              field: v.field.replace(/^[a-z]/, match => match.toUpperCase()).replace(/_/g, ' '),
              value: v.field === 'entry_date' ? dateFormatter(v.to) : <RichText text={v.to} />,
              prevValue: v.field === 'entry_date' ? dateFormatter(v.from) : <RichText text={v.from} />,
            });
          });
          if (data.details.comment) {
            content.push({
              field: 'Comment',
              value: <RichText text={data.details.comment} />,
            });
          }
          break;
        case 'responsible_recruiter_is_changed':
          title = (
            <>
              {user} has changed responsible recruiter in the job {jobLink}
            </>
          );
          content.push({
            field: 'Recruiter',
            value: data.details.new_responsible_recruiter.full_name,
            prevValue: data.details.previous_responsible_recruiter.full_name,
          });
          break;
        case 'resolution_received':
          title = (
            <>
              {user} has submitted decision for the Candidate in the job {jobLink}
            </>
          );
          content.push({
            field: 'Step',
            value: data.details.step.type,
          });
          content.push({
            field: 'Decision',
            value: (
              <Status type={data.details.decision.positive ? STATUS_TYPES.GREEN : STATUS_TYPES.RED}>
                {data.details.decision.positive ? 'Approved' : 'Rejected'}
              </Status>
            ),
          });
          if (!data.details.decision.positive) {
            content.push({
              field: 'Rejection reason',
              value: data.details.decision.reason,
            });
          }
          if (data.details.decision.probation_period) {
            content.push({
              field: 'Probation period',
              value: `${data.details.decision.probation_period} month(s)`,
            });
          }
          if (data.details.decision.assumed_experience_level) {
            content.push({
              field: 'Assumed experience',
              value: data.details.decision.assumed_experience_level,
            });
          }
          if (data.details.decision.comment) {
            content.push({
              field: 'Comment',
              value: <RichText text={data.details.decision.comment} />,
            });
          }
          break;
        case 'final_resolution_received':
          title = (
            <>
              {user} has submitted final decision for the Candidate in the job {jobLink}
            </>
          );
          content.push({
            field: 'Step',
            value: data.details.step.type,
          });
          content.push({
            field: 'Decision',
            value: (
              <Status type={data.details.decision.positive ? STATUS_TYPES.GREEN : STATUS_TYPES.RED}>
                {data.details.decision.positive ? 'Approved' : 'Rejected'}
              </Status>
            ),
          });
          if (!data.details.decision.positive) {
            content.push({
              field: 'Rejection reason',
              value: data.details.decision.reason,
            });
          }
          if (data.details.decision.probation_period) {
            content.push({
              field: 'Probation period',
              value: `${data.details.decision.probation_period} month(s)`,
            });
          }
          if (data.details.decision.assumed_experience_level) {
            content.push({
              field: 'Assumed experience level',
              value: data.details.decision.assumed_experience_level,
            });
          }
          if (data.details.decision.comment) {
            content.push({
              field: 'Comment',
              value: <RichText text={data.details.decision.comment} />,
            });
          }
          break;
        case 'feedback_provided':
          title = (
            <>
              {user} has added feedback in the job {jobLink}
            </>
          );
          if (data.details.step?.type) {
            content.push({
              field: 'Step',
              value: data.details.step?.type,
            });
          }
          if (data.details.decision) {
            content.push({
              field: 'Decision',
              value: (
                <Status type={data.details.decision.positive ? STATUS_TYPES.GREEN : STATUS_TYPES.RED}>
                  {data.details.decision.positive ? 'Approved' : 'Rejected'}
                </Status>
              ),
            });
          }
          if (data.details.decision.english_level) {
            content.push({
              field: 'Assumed English level',
              value: data.details.decision.english_level,
            });
          }
          if (data.details.decision.experience_level) {
            content.push({
              field: 'Assumed experience level',
              value: data.details.decision.experience_level,
            });
          }
          if (data.details.interview_feedback?.comment) {
            content.push({
              field: 'Comment',
              value: <RichText text={data.details.interview_feedback.comment} />,
            });
          }
          break;
        case 'feedback_updated':
          title = (
            <>
              {user} has updated feedback in the job {jobLink}
            </>
          );
          if (data.details.step?.type) {
            content.push({
              field: 'Step',
              value: data.details.step?.type,
            });
          }
          if (data.details.decision) {
            content.push({
              field: 'Decision',
              value: (
                <Status type={data.details.decision.positive ? STATUS_TYPES.GREEN : STATUS_TYPES.RED}>
                  {data.details.decision.positive ? 'Approved' : 'Rejected'}
                </Status>
              ),
            });
            if (data.details.decision.english_level) {
              content.push({
                field: 'Assumed English level',
                value: data.details.decision.english_level,
              });
            }
            if (data.details.decision.experience_level) {
              content.push({
                field: 'Assumed experience level',
                value: data.details.decision.experience_level,
              });
            }
            if (data.details.interview_feedback?.comment) {
              content.push({
                field: 'Comment',
                value: <RichText text={data.details.interview_feedback.comment} />,
              });
            }
          }
          if (data.details.previous_feedback_version) {
            previous_content.fields = [];
            previous_content.created_at = data.details.previous_feedback_version.updated_at
              ? dateFormatter(data.details.previous_feedback_version.updated_at, 'MMM DD, YYYY [at] HH:mm')
              : null;
            if (data.details.previous_feedback_version.step?.type) {
              previous_content.fields.push({
                field: 'Step',
                value: data.details.previous_feedback_version.step.type,
              });
            }
            previous_content.fields.push({
              field: 'Decision',
              value: (
                <Status type={data.details.previous_feedback_version.positive ? STATUS_TYPES.GREEN : STATUS_TYPES.RED}>
                  {data.details.previous_feedback_version.positive ? 'Approved' : 'Rejected'}
                </Status>
              ),
            });
            if (data.details.previous_feedback_version.english_level) {
              previous_content.fields.push({
                field: 'Assumed English level',
                value: data.details.previous_feedback_version.english_level,
              });
            }
            if (data.details.previous_feedback_version.level) {
              previous_content.fields.push({
                field: 'Assumed experience level',
                value: data.details.previous_feedback_version.level,
              });
            }
            if (data.details.previous_feedback_version.comment) {
              previous_content.fields.push({
                field: 'Comment',
                value: <RichText text={data.details.previous_feedback_version.comment} />,
              });
            }
          }
          break;
        case 'offer_added':
          title = (
            <>
              {user} has added the offer in the job {jobLink}
            </>
          );
          content.push({
            field: 'Probation period',
            value: `${data.details.offer.probation_period} month(s)`,
          });
          content.push({
            field: 'Expected answer date',
            value: moment(data.details.offer.expected_answer_date).format('MMM DD, YYYY'),
          });
          if (data.details.offer.comment) {
            content.push({
              field: 'Comment',
              value: <RichText text={data.details.offer.comment} />,
            });
          }
          break;
        case 'offer_updated':
          title = (
            <>
              {user} has updated the offer in the job {jobLink}
            </>
          );
          data.details.updated_fields.forEach(v => {
            const value = v.to
              ? v.field === 'probation_period'
                ? `${v.to} month(s)`
                : v.field === 'expected_answer_date'
                ? dateFormatter(v.to)
                : v.to
              : 'None';
            const prevValue = v.from
              ? v.field === 'probation_period'
                ? `${v.from} month(s)`
                : v.field === 'expected_answer_date'
                ? dateFormatter(v.from)
                : v.from
              : 'None';
            content.push({
              field: v.field.replace(/^[a-z]/, match => match.toUpperCase()).replace(/_/g, ' '),
              value: <RichText text={value} />,
              prevValue: <RichText text={prevValue} />,
            });
          });
          break;
        case 'comment_was_left':
          title = (
            <>
              {user} has added comment in the job {jobLink}
            </>
          );
          content.push({
            field: 'Comment',
            value: <RichText text={data.details.comment.comment} />,
          });
          break;
      }
      break;
  }
  if (!title) title = 'Unknown event!';
  return { title, content, previous_content };
};
