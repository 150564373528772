import React from 'react';
import { Form, Select as SelectAntd, Tag } from 'antd';
import cn from 'classnames';
import { Typography } from '_atoms/Typography';
import { ReactComponent as CheckMark } from 'dist/icons/checkmark.svg';
import { ReactComponent as Arrow } from 'dist/icons/caret-down.svg';
import { ReactComponent as Clear } from 'dist/icons/clear.svg';
import { SELECT_TYPES } from './constants';
import './select.scss';

const tagRender = ({ label, closable, onClose }) => {
  const onPreventMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Tag
      className={cn('select-default__tag', { 'select-default__tag_disabled': !closable })}
      closable={closable}
      onMouseDown={onPreventMouseDown}
      onClose={onClose}
      closeIcon={<Clear />}
    >
      {label}
    </Tag>
  );
};
const extraTagRender = options => (
  <Tag className="select-default__tag select-default__tag_extra">+{options.length}</Tag>
);

export const Select = ({
  className,
  popupClassName,
  onChange,
  onClick,
  label,
  empty = null,
  inPopup,
  options,
  loading,
  itemProps,
  required,
  optional,
  ...props
}) => (
  <div className={cn('select-default', className)}>
    {label && (
      <Typography.Label>
        {label}
        {!!required && <span className="required"> *</span>}
        {optional && <span className="optional"> (optional)</span>}
      </Typography.Label>
    )}
    <Form.Item {...itemProps}>
      <SelectAntd
        showSearch
        onChange={(_, v) => onChange && onChange(v)}
        popupClassName={cn(
          'select-default__dropdown',
          { 'select-default__dropdown_multiple': props.mode === SELECT_TYPES.MULTIPLE },
          popupClassName
        )}
        showArrow
        allowClear={props.mode === SELECT_TYPES.MULTIPLE}
        clearIcon={<Clear />}
        getPopupContainer={() => (inPopup ? document.querySelector('.popup-default__wrap') : document.body)}
        menuItemSelectedIcon={<CheckMark />}
        suffixIcon={<Arrow />}
        maxTagCount="responsive"
        tagRender={tagRender}
        maxTagPlaceholder={extraTagRender}
        placeholder="Please select"
        options={loading ? [] : options}
        notFoundContent={loading ? <div>Loading...</div> : empty}
        optionFilterProp={props.labelInValue ? 'label' : 'value'}
        {...props}
      />
    </Form.Item>
  </div>
);
