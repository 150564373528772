import React, { useEffect, useMemo, useState } from 'react';
import { Popup, Select } from '_atoms';
import { Typography } from '_atoms/Typography';
import { BUTTON_TEXT } from 'constants/text';

export const PopupAssignToStep = ({ open, onClose, save, userData, stepsOptions }) => {
  const [step, setStep] = useState(undefined);
  const steps = useMemo(() => stepsOptions.map(el => ({ ...el, value: el.uuid, label: el.name })), [stepsOptions]);
  useEffect(() => setStep(undefined), [open]);
  return (
    <Popup
      open={open}
      title="Assign candidate to step"
      cancelButtonProps={{ onClick: onClose, children: BUTTON_TEXT.CANCEL }}
      okButtonProps={{ onClick: () => save(step), children: BUTTON_TEXT.CONFIRM, disabled: !step }}
    >
      <div className="user-info">
        <Typography.Text className="user-info__title">
          {userData.first_name_latin} {userData.last_name_latin}
        </Typography.Text>
        <Typography.Caption className="user-info__subtitle">
          {userData.first_name_local} {userData.last_name_local}
        </Typography.Caption>
        <Typography.Caption className="user-info__subtitle">{userData.speciality}</Typography.Caption>
      </div>
      <Select label="Step" options={steps} onChange={setStep} />
    </Popup>
  );
};
