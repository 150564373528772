import React from 'react';
import { Form, Radio } from 'antd';
import cn from 'classnames';
import { Input } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import './style.scss';

export const ButtonGroup = ({ className, label, itemProps, inputProps = {}, options = [], withCustom, ...props }) => (
  <div className={cn('button-group-default', className)}>
    {label && <Typography.Label weight={TYPOGRAPHY_WEIGHT.BOLD}>{label}</Typography.Label>}
    <div className="button-group-default__group-wrapper">
      <Form.Item {...itemProps}>
        <Radio.Group optionType="button" options={options} {...props} />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prev, curr) =>
          prev[itemProps.name] !== curr[itemProps.name] ||
          prev[`${itemProps?.name}_custom_value`] !== curr[`${itemProps?.name}_custom_value`]
        }
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue(itemProps.name) === options.at(-1)?.value && withCustom && <Input {...inputProps} />
        }
      </Form.Item>
    </div>
  </div>
);
