import { useMemo } from 'react';
import moment from 'moment';
import { possibleTime } from 'constants/constants';

window.moment = moment;

const getPossibleTime = (time, offset) => {
  const value = moment(time, 'HH:mm:ss').add(moment().utcOffset(), 'm').format('HH:mm');
  const timeObj = possibleTime.find(el => el.value === value);
  const index = possibleTime.findIndex(el => el.value === value) + offset;
  return { ...timeObj, index };
};

export const useInterviewData = (interview, accessToGoogleCalendar) =>
  useMemo(
    () => ({
      date: interview.date ? moment(interview.date, 'YYYY-MM-DD') : moment(),
      to_time: interview.to_time ? getPossibleTime(interview.to_time, -2) : { ...possibleTime[26], index: 24 },
      from_time: interview.from_time ? getPossibleTime(interview.from_time, 0) : { ...possibleTime[24], index: 24 },
      locations: interview.locations?.map(item => ({ value: item.id, label: item.name })) || [],
      uuid: interview.uuid,
      create_google_event: interview.create_google_event ?? accessToGoogleCalendar,
      type: interview.type ? { value: interview.type, label: interview.type } : undefined,
      link: interview.link || '',
      approvers: interview.approvers?.map(item => item.uuid) || [],
    }),
    [interview]
  );
