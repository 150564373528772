import React from 'react';
import cn from 'classnames';
import { Form, Input as InputAntd } from 'antd';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as Clear } from 'dist/icons/clear.svg';
import { ReactComponent as Search } from 'dist/icons/search.svg';
import { useDebounceEffect } from 'helpers/useDebounceEffect';
import './style.scss';

export const Input = ({ className, type = 'text', required, onDebounceChange, itemProps, delay, ...props }) => {
  const onDebounce = useDebounceEffect(onDebounceChange, delay);
  const onChange = e => {
    if (props.onChange) props.onChange(e);
    if (onDebounce) onDebounce(e);
  };
  return (
    <div
      className={cn('input-default-wrapper', className, {
        error: props.error,
      })}
    >
      {props.label !== undefined && (
        <Typography.Label weight={TYPOGRAPHY_WEIGHT.BOLD}>
          {props.label}
          {!!required && <span> *</span>}
        </Typography.Label>
      )}
      <Form.Item {...itemProps}>
        <InputAntd
          className="input-default"
          maxLength={props.maxLength || 255}
          onChange={onChange}
          allowClear={type === 'search' && { clearIcon: <Clear /> }}
          prefix={type === 'search' && <Search />}
          autoComplete="off"
          {...props}
        />
      </Form.Item>
      {!!props.error && <Typography.Caption className="input-default__error-message">{props.error}</Typography.Caption>}
    </div>
  );
};
