export const REQUIRED_JOB_NAME = { required: true, message: 'Please enter Job name' };
export const REQUIRED_SERVICE = { required: true, message: 'Please select Service' };
export const REQUIRED_TEAM_LEAD = { required: true, message: 'Please select Team Lead' };

export const INITIAL_BENEFITS = `
  <ul>
    <li>
      <p>Free corporate English and German courses</p>
    </li>
    <li>
      <p>All internal educational activities (technical bases and special courses; soft skill courses and EDU projects, etc)</p>
    </li>
    <li>
      <p>Gifts for special events from LeverX</p>
    </li>
    <li>
      <p>Sport compensation/sport card</p>
    </li>
  </ul>
`;
