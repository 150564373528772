import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { Popup } from '_atoms';
import { BreadCrumbs } from '_atoms/BreadCrumbs';
import { FormCreateEdit, PopupReassignRecruiter, JobCreationForm } from '_molecules';
import history from 'helpers/history';
import { MixpanelService } from 'helpers/Mixpanel';
import { ROLES } from 'permission';
import { getHrManagers, getRecruiterLeads, getServices } from 'store/selectors/commonSelectors';
import { INITIAL_BENEFITS } from '_molecules/JobCreationForm/constants';
import { JOB_STATUSES } from 'constants/job';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from 'constants/text';
import { getListServices, getListHrManagers, setDataJob, getJobInfo, getListUsersByRole, clearJobInfo } from 'actions';
import { dataMapper, formDataMapper, userMapper, useServiceOptions } from './utils';
import './style.scss';
import { setRecruiterLeads } from '../../store/reducers/commonReducer';

const AddJobs = props => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [teamLeadOptions, setTeamLeadOptions] = useState([]);
  const [jobStatus, setJobStatus] = useState(JOB_STATUSES.DRAFT);
  const [recruitersToReassign, setRecruitersToReassign] = useState([]);

  const services = useSelector(getServices);
  const recruiters = useSelector(getHrManagers);
  const recruiterLeads = useSelector(getRecruiterLeads);
  const serviceOptions = useServiceOptions(services);

  const onCloseExitConfirmation = () => setOpenConfirmation(false);
  const onCancel = () => {
    const { description, benefits, ...touchedValues } = form.getFieldsValue(null, meta => meta.touched);
    const description_initial = form.getFieldValue('description_initial');
    const benefits_initial = form.getFieldValue('benefits_initial');
    if (description !== description_initial || benefits !== benefits_initial || Object.keys(touchedValues).length) {
      setOpenConfirmation(true);
    } else {
      onOkExitConfirmation();
    }
  };

  const onOkSaveForm = values => {
    const recruitersCurrentIds = form.getFieldValue('recruiters')?.map(r => r.value) || [];
    const recruitersInitial = form.getFieldValue('reassign_recruiters') || [];
    const recruitersToCheck = recruitersInitial.filter(
      r => r.is_active_responsible_recruiter && !recruitersCurrentIds.includes(r.uuid)
    );
    if (recruitersToCheck.length) {
      setRecruitersToReassign(recruitersToCheck);
    } else {
      setLoading(true);
      setDataJob({ uuid: props.isClone ? undefined : id, ...formDataMapper(values) })
        .then(job => {
          setLoading(false);
          history.push(`/jobs/${job.uuid}/show/description`);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onOkExitConfirmation = () => {
    if (props.id) {
      history.push(`/jobs/${props.id}/show/steps/`);
    } else {
      history.push(`/jobs`);
    }
  };

  const onCloseReassignPopup = () => setRecruitersToReassign([]);
  const onAfterReassignRecruiters = r => {
    const reassign_recruiters =
      r && Object.keys(r)
        ? Object.entries(r).map(([key, v]) => ({ old_recruiter: key, new_recruiter: v.value }))
        : null;
    setDataJob({
      uuid: props.isClone ? undefined : id,
      ...formDataMapper({ ...form.getFieldsValue(), reassign_recruiters }),
    }).then(job => {
      history.push(`/jobs/${job.uuid}/show/description`);
    });
    onCloseReassignPopup();
  };

  const getTeamLeads = useCallback((service, resetTeamLead) => {
    setTeamLeadOptions([]);
    getListUsersByRole({ role: [ROLES.TEAM_LEAD, ROLES.SERVICE_HEAD], service }).then(data => {
      const teamLeads = userMapper(data);
      const teamLead = form.getFieldValue('team_lead');
      setTeamLeadOptions(teamLeads);
      if (resetTeamLead && teamLead && !teamLeads.find(tl => tl.value === teamLead.value)) {
        form.setFieldValue('team_lead', undefined);
      }
    });
  }, []);

  const onChangeValue = ({ service }) => {
    if (service) getTeamLeads(service.value, true);
  };

  useEffect(() => {
    dispatch(getListServices());
    dispatch(getListHrManagers({ role: ROLES.RECRUITER }));
    getListUsersByRole({ role: ROLES.RECRUITER_LEAD }).then(users => {
      dispatch(setRecruiterLeads(users.map(u => ({ value: u.uuid, label: u.full_name }))));
    });
    const mixpanel = new MixpanelService();
    if (props.id) {
      setLoading(true);
      dispatch(getJobInfo(props.id)).then(resp => {
        setLoading(false);
        form.setFields(dataMapper(resp, props.isClone));
        getTeamLeads(resp.service.uuid);
        setJobStatus(props.isClone ? JOB_STATUSES.DRAFT : resp.status);
      });
      mixpanel.track('View Page.Edit Job', { 'Job ID': props.id });
    } else {
      form.setFieldValue('benefits_initial', INITIAL_BENEFITS);
      dispatch(clearJobInfo());
      mixpanel.track('View Page.Create Job');
    }
    return () => dispatch(clearJobInfo());
  }, []);

  return (
    <>
      <Form form={form} onFinish={onOkSaveForm} onValuesChange={onChangeValue} validateTrigger="onSubmit">
        <div className="wrapper-content job-creation-page">
          <BreadCrumbs
            element="Jobs"
            elementName={props.id ? (props.isClone ? 'Clone job' : 'Job editing') : 'Job creation'}
            path="/jobs"
          />
          <FormCreateEdit
            title={props.id ? (props.isClone ? 'Clone job' : 'Job editing') : 'Job creation'}
            contentClassName="form-job-creation"
            onCancel={onCancel}
            okText={props.id ? (props.isClone ? 'Save' : 'Save changes') : 'Create job'}
            cancelText="Cancel"
            loading={loading}
          >
            <JobCreationForm
              serviceOptions={serviceOptions}
              teamLeadOptions={teamLeadOptions}
              recruiters={recruiters}
              recruiterLeads={recruiterLeads}
              jobStatus={jobStatus}
            />
          </FormCreateEdit>
        </div>
      </Form>
      <Popup
        open={openConfirmation}
        title={POPUP_LABELS.DISCARD_CHANGES}
        cancelButtonProps={{ onClick: onCloseExitConfirmation, children: BUTTON_TEXT.BACK_TO_EDIT }}
        okButtonProps={{ onClick: onOkExitConfirmation, children: BUTTON_TEXT.DISCARD }}
      >
        {POPUP_BODY.CHANGES_NOT_SAVED}
      </Popup>
      <PopupReassignRecruiter
        open={recruitersToReassign.length}
        onClose={onCloseReassignPopup}
        onConfirm={onAfterReassignRecruiters}
        recruitersReassignFrom={recruitersToReassign}
      />
    </>
  );
};

export default AddJobs;
