import { Title1, Title2, Title3, Title4, Caption, Text, Label, NumbersLarge, NumbersMedium } from './Typography';
import { TYPOGRAPHY_WEIGHT } from './constans';

const Typography = () => 'Please, provide name component';

Typography.Title1 = Title1;
Typography.Title2 = Title2;
Typography.Title3 = Title3;
Typography.Title4 = Title4;
Typography.Text = Text;
Typography.Caption = Caption;
Typography.Label = Label;
Typography.NumbersLarge = NumbersLarge;
Typography.NumbersMedium = NumbersMedium;

export { Typography, TYPOGRAPHY_WEIGHT };
