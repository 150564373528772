import { ROLES } from 'permission';
import { levels } from 'constants/constants';

export const filtersMapper = (filters, tableOptions) => ({
  search: filters.search,
  company: filters.companies.map(({ value }) => value).join(','),
  service: filters.services.map(({ value }) => value).join(','),
  team_lead: filters.team_leads.map(({ value }) => value).join(','),
  status: filters.jobs_statuses.map(({ value }) => value).join(','),
  recruiter: filters.recruiters.map(({ value }) => value).join(','),
  opening_status: filters.openings_statuses.map(({ value }) => value).join(','),
  offices: filters.offices.map(({ value }) => value).join(','),
  experience_level: filters.experience_levels
    .filter(({ value }) => levels.includes(value))
    .map(({ value }) => value)
    .join(','),
  experience_custom_level: filters.experience_levels
    .filter(({ value }) => !levels.includes(value))
    .map(({ value }) => value)
    .join(','),
  sorting_field: tableOptions.sorting.field,
  sorting: tableOptions.sorting.order,
  limit: tableOptions.limit.value,
  offset: tableOptions.offset,
});

export const defaultFilters = user => ({
  team_leads: [],
  // user.active_role?.name === ROLES.TEAM_LEAD
  //   ? [
  //       {
  //         label: user.full_name,
  //         value: user.uuid,
  //       },
  //     ]
  //   : [],
  recruiters: [ROLES.RECRUITER, ROLES.RECRUITER_LEAD].includes(user.active_role?.name)
    ? [
        {
          label: user.full_name,
          value: user.uuid,
        },
      ]
    : [],
  service: [],
  offices: [],
});
