import React from 'react';
import classNames from 'classnames';
import * as Variant from './variant';
import './avatar.scss';

const randomize = arr => arr[Math.floor(Math.random() * arr.length)];
const Placeholder = ({ blur }) => {
  const components = [
    <Variant.First />,
    <Variant.Second />,
    <Variant.Third />,
    <Variant.Fourth />,
    <Variant.Fifth />,
    <Variant.Sixth />,
    <Variant.Seventh />,
    <Variant.Eighth />,
  ];
  return blur ? <Variant.Blur /> : randomize(components);
};

export const Avatar = React.memo(({ className, onLoad, blur = false, src, variant = 'normal', bordered }) => (
  <div className={classNames('avatar-default', variant, className, { bordered })}>
    {!!src && (
      <img
        src={src}
        alt="avatar"
        onLoad={() => {
          if (onLoad) onLoad();
        }}
      />
    )}
    {!src && <Placeholder blur={blur} />}
  </div>
));
