import React from 'react';
import classNames from 'classnames';
import { Avatar } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import './avatarLabel.scss';

export const AvatarLabel = ({ src, blur = false, className, name, extraName, onClick }) => (
  <div role="none" className={classNames('avatar-label-default', className)} onClick={onClick}>
    <Avatar className="avatar-label-default__avatar" variant="normal" blur={blur} src={src} />
    <div>
      {name && (
        <Typography.Caption weight={TYPOGRAPHY_WEIGHT.BOLD} className="avatar-label-default__name">
          {name}
        </Typography.Caption>
      )}
      {extraName && <Typography.Caption className="avatar-label-default__name-extra">{extraName}</Typography.Caption>}
    </div>
  </div>
);
