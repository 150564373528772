import { fetchWrapper } from 'helpers/helpers';
import { ROLES } from 'permission';
import { ROUTES } from 'router/constants';
import {
  setAllCompanies,
  setForeignLanguages,
  setLevels,
  setListHRL,
  setListHrManagers,
  setListLocations,
  setListServices,
} from 'store/reducers/commonReducer';

export function getListServices(body = {}) {
  return dispatch =>
    fetchWrapper({
      url: 'services/',
      body,
    })
      .then(resp => {
        dispatch(setListServices(resp));
      })
      .catch(() => {});
}
export function getListLocations(body = {}) {
  return dispatch =>
    fetchWrapper({
      url: 'offices/',
      body,
    })
      .then(resp => {
        dispatch(setListLocations(resp));
      })
      .catch(() => {});
}

export const getForeignLanguages = () => dispatch =>
  fetchWrapper({
    url: 'foreign-languages/',
  })
    .then(resp => {
      dispatch(setForeignLanguages(resp.languages));
      dispatch(setLevels(resp.levels));
    })
    .catch(() => {});

export function getListUsersByRole(body) {
  return fetchWrapper({
    method: 'GET',
    url: `users/`,
    body,
  });
}

export function getListHrManagers(data, dashboardExtraHR) {
  return dispatch => {
    const body = data || { role: ROLES.RECRUITER };
    return fetchWrapper({
      method: 'GET',
      url: `users/`,
      body,
    })
      .then(resp => {
        const list = resp.filter(v => !v.is_deleted).map(v => ({ value: v.uuid, label: v.full_name }));
        if (dashboardExtraHR) list.push(dashboardExtraHR);
        dispatch(setListHrManagers(list));
        return list;
      })
      .catch(() => {});
  };
}

export function getStepsAssignToJob(id) {
  return fetchWrapper({
    url: `jobs/${id}/workflow/steps/`,
  })
    .then(resp => resp)
    .catch(() => {});
}

export function changeRole(userId, data) {
  return fetchWrapper({
    method: 'PATCH',
    url: `users/${userId}/`,
    body: data,
  }).then(() => {
    window.open(ROUTES.DASHBOARD, '_self');
  });
}

export function getListHRL() {
  return dispatch =>
    fetchWrapper({
      method: 'GET',
      url: `users/`,
      body: { role: ROLES.HR_LEAD },
    }).then(resp => {
      dispatch(setListHRL(resp.filter(hrl => !hrl.is_deleted)));
    });
}

export function getAllCompanies() {
  return dispatch =>
    fetchWrapper({
      method: 'GET',
      url: `companies/`,
    }).then(resp => {
      dispatch(setAllCompanies(resp));
    });
}
