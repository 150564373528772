import { useMemo } from 'react';
import { STEPS_LABELS } from 'constants/constants';
import { JOB_REJECTION_OPTIONS, JOB_SUBMIT_OPTIONS } from 'constants/job';

export const useDecisionOptions = step => {
  if (Object.values(STEPS_LABELS)[step] === STEPS_LABELS.TECHNICAL_INTERVIEW)
    return [
      { value: JOB_SUBMIT_OPTIONS.APPROVE, label: JOB_SUBMIT_OPTIONS.APPROVE },
      { value: JOB_SUBMIT_OPTIONS.REJECTED_BY_CANDIDATE, label: JOB_SUBMIT_OPTIONS.REJECTED_BY_CANDIDATE },
      { value: JOB_SUBMIT_OPTIONS.REJECTED_BY_TEAM_LEAD, label: JOB_SUBMIT_OPTIONS.REJECTED_BY_TEAM_LEAD },
    ];
  if (Object.values(STEPS_LABELS)[step] === STEPS_LABELS.MANAGEMENT_INTERVIEW)
    return [
      { value: JOB_SUBMIT_OPTIONS.APPROVE, label: JOB_SUBMIT_OPTIONS.APPROVE },
      { value: JOB_SUBMIT_OPTIONS.REJECTED_BY_CANDIDATE, label: JOB_SUBMIT_OPTIONS.REJECTED_BY_CANDIDATE },
      { value: JOB_SUBMIT_OPTIONS.REJECTED_BY_MANAGEMENT, label: JOB_SUBMIT_OPTIONS.REJECTED_BY_MANAGEMENT },
    ];
  return [
    { value: JOB_SUBMIT_OPTIONS.APPROVE, label: JOB_SUBMIT_OPTIONS.APPROVE },
    { value: JOB_SUBMIT_OPTIONS.REJECT, label: JOB_SUBMIT_OPTIONS.REJECT },
  ];
};

export const useRejectionOptions = (step, decision) =>
  useMemo(() => {
    switch (Object.values(STEPS_LABELS)[step]) {
      case STEPS_LABELS.CONTACT_WITH_CANDIDATE:
        return [
          JOB_REJECTION_OPTIONS.HIGH_MONEY_MOTIVATION,
          JOB_REJECTION_OPTIONS.LACK_OF_SKILLS,
          JOB_REJECTION_OPTIONS.NO_BAD_ENGLISH,
          JOB_REJECTION_OPTIONS.LOCATION_RELOCATION,
          JOB_REJECTION_OPTIONS.STOPPED_REPLYING,
          JOB_REJECTION_OPTIONS.OTHER,
        ].map(v => ({ value: v, label: v }));
      case STEPS_LABELS.CV_SCREENING:
        return [
          JOB_REJECTION_OPTIONS.HIGH_MONEY_MOTIVATION,
          JOB_REJECTION_OPTIONS.LACK_OF_SKILLS,
          JOB_REJECTION_OPTIONS.NO_BAD_ENGLISH,
          JOB_REJECTION_OPTIONS.LOCATION_RELOCATION,
          JOB_REJECTION_OPTIONS.OTHER,
        ].map(v => ({ value: v, label: v }));
      case STEPS_LABELS.TECHNICAL_INTERVIEW:
        if (decision?.value === JOB_SUBMIT_OPTIONS.REJECTED_BY_TEAM_LEAD) {
          return [
            JOB_REJECTION_OPTIONS.BAD_RECOMMENDATIONS,
            JOB_REJECTION_OPTIONS.LOW_TECHNICAL_LEVEL,
            JOB_REJECTION_OPTIONS.HIGH_MONEY_MOTIVATION,
            JOB_REJECTION_OPTIONS.JUNIOR_LEVEL_SPECIALIST,
            JOB_REJECTION_OPTIONS.NO_SOFT_SKILLS,
            JOB_REJECTION_OPTIONS.NO_BAD_ENGLISH,
            JOB_REJECTION_OPTIONS.OTHER,
          ].map(v => ({ value: v, label: v }));
        }
        return [
          JOB_REJECTION_OPTIONS.STAY_AT_CURRENT_COMPANY,
          JOB_REJECTION_OPTIONS.ANOTHER_COMPANYS_JOB_ACCEPTED,
          JOB_REJECTION_OPTIONS.WITHOUT_EXPLANATION,
          JOB_REJECTION_OPTIONS.DIDNT_SHOW_UP_TO_INTERVIEW,
          JOB_REJECTION_OPTIONS.PERSONAL_REASONS,
          JOB_REJECTION_OPTIONS.REFUSED_TO_DO_TASK,
          JOB_REJECTION_OPTIONS.OTHER,
        ].map(v => ({ value: v, label: v }));
      case STEPS_LABELS.MANAGEMENT_INTERVIEW:
        if (decision?.value === JOB_SUBMIT_OPTIONS.REJECTED_BY_MANAGEMENT) {
          return [
            JOB_REJECTION_OPTIONS.BAD_RECOMMENDATIONS,
            JOB_REJECTION_OPTIONS.LOW_TECHNICAL_LEVEL,
            JOB_REJECTION_OPTIONS.HIGH_MONEY_MOTIVATION,
            JOB_REJECTION_OPTIONS.JUNIOR_LEVEL_SPECIALIST,
            JOB_REJECTION_OPTIONS.NO_SOFT_SKILLS,
            JOB_REJECTION_OPTIONS.NO_BAD_ENGLISH,
            JOB_REJECTION_OPTIONS.OTHER,
          ].map(v => ({ value: v, label: v }));
        }
        return [
          JOB_REJECTION_OPTIONS.STAY_AT_CURRENT_COMPANY,
          JOB_REJECTION_OPTIONS.ANOTHER_COMPANYS_JOB_ACCEPTED,
          JOB_REJECTION_OPTIONS.WITHOUT_EXPLANATION,
          JOB_REJECTION_OPTIONS.DIDNT_SHOW_UP_TO_INTERVIEW,
          JOB_REJECTION_OPTIONS.PERSONAL_REASONS,
          JOB_REJECTION_OPTIONS.REFUSED_TO_DO_TASK,
          JOB_REJECTION_OPTIONS.OTHER,
        ].map(v => ({ value: v, label: v }));
      case STEPS_LABELS.MANAGEMENT_APPROVAL:
        return [
          JOB_REJECTION_OPTIONS.BAD_RECOMMENDATIONS,
          JOB_REJECTION_OPTIONS.LOW_TECHNICAL_LEVEL,
          JOB_REJECTION_OPTIONS.HIGH_MONEY_MOTIVATION,
          JOB_REJECTION_OPTIONS.JUNIOR_LEVEL_SPECIALIST,
          JOB_REJECTION_OPTIONS.NO_SOFT_SKILLS,
          JOB_REJECTION_OPTIONS.NO_BAD_ENGLISH,
          JOB_REJECTION_OPTIONS.OTHER,
        ].map(v => ({ value: v, label: v }));
      case STEPS_LABELS.OFFER:
        return [
          JOB_REJECTION_OPTIONS.STAY_AT_CURRENT_COMPANY,
          JOB_REJECTION_OPTIONS.LOW_SALARY_OFFERED,
          JOB_REJECTION_OPTIONS.PERSONAL_REASONS,
          JOB_REJECTION_OPTIONS.INTERESTED_IN_OTHER_COMPANYS_PROJECT,
          JOB_REJECTION_OPTIONS.LOCATION_RELOCATION,
          JOB_REJECTION_OPTIONS.BAD_RECOMMENDATIONS,
          JOB_REJECTION_OPTIONS.OTHER,
        ].map(v => ({ value: v, label: v }));
      case STEPS_LABELS.JOB_STARTED:
        return [
          JOB_REJECTION_OPTIONS.DIDNT_SHOW_UP,
          JOB_REJECTION_OPTIONS.PERSONAL_REASONS,
          JOB_REJECTION_OPTIONS.OTHER,
        ].map(v => ({ value: v, label: v }));
    }
  }, [step, decision]);

export const dataMapper = (
  isManagement,
  reason,
  comment,
  decision,
  probation_period,
  technical_level,
  custom_technical_level
) => {
  const positive = decision === JOB_SUBMIT_OPTIONS.APPROVE;
  const managementApprovalData = isManagement
    ? {
        probation_period,
        technical_level,
        custom_technical_level,
      }
    : {};
  const rejected_by = decision?.includes('Rejected by ') ? decision.replace('Rejected by ', '') : undefined;
  return {
    reason,
    comment,
    positive,
    rejected_by,
    ...managementApprovalData,
  };
};
