export const ROW_NAMES = [
  { name: 'Created by system', key: 'month_created_by_system', totalKey: 'total_created_by_system' },
  {
    name: 'Left from previous period',
    key: 'month_left_from_previous_period',
    totalKey: 'total_left_from_previous_period',
  },
  { name: 'Deleted', key: 'month_deleted_by_system', totalKey: 'total_deleted_by_system' },
  { name: 'Marked as reviewed', key: 'month_marked_as_reviewed', totalKey: 'total_marked_as_reviewed' },
  { name: 'Assigned to job', key: 'month_assigned_to_job', totalKey: 'total_assigned_to_job' },
  { name: 'Blacklisted', key: 'month_blacklisted', totalKey: 'total_blacklisted' },
  { name: 'Not reviewed', key: 'month_not_reviewed', totalKey: 'total_not_reviewed' },
  { name: 'Created manually', key: 'month_manually_created_candidates', totalKey: 'total_manually_created_candidates' },
];

export const COLUMNS = [
  {
    title: 'Month/Transition',
    dataIndex: 'transition',
    key: 'transition',
    fixed: 'left',
    width: 232,
  },
];

export const DATA = {
  analytics_by_month: [
    {
      year: 2024,
      month: 1,
      month_created_by_system: 0,
      month_deleted_by_system: 0,
      month_marked_as_reviewed: 0,
      month_assigned_to_job: 0,
      month_blacklisted: 0,
      month_not_reviewed: 503,
      month_manually_created_candidates: 0,
      month_left_from_previous_period: 0,
    },
    {
      year: 2024,
      month: 2,
      month_created_by_system: 0,
      month_deleted_by_system: 0,
      month_marked_as_reviewed: 0,
      month_assigned_to_job: 0,
      month_blacklisted: 0,
      month_not_reviewed: 503,
      month_manually_created_candidates: 0,
      month_left_from_previous_period: 0,
    },
    {
      year: 2024,
      month: 3,
      month_created_by_system: 0,
      month_deleted_by_system: 0,
      month_marked_as_reviewed: 0,
      month_assigned_to_job: 0,
      month_blacklisted: 0,
      month_not_reviewed: 503,
      month_manually_created_candidates: 0,
      month_left_from_previous_period: 0,
    },
  ],
  analytics_total: {
    total_created_by_system: 0,
    total_deleted_by_system: 0,
    total_marked_as_reviewed: 0,
    total_assigned_to_job: 0,
    total_blacklisted: 0,
    total_not_reviewed: 503,
    total_manually_created_candidates: 0,
    total_left_from_previous_period: 0,
  },
};
