import React from 'react';
import { notification as notificationAntd } from 'antd';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from 'dist/icons/close.svg';
import { ReactComponent as ErrorIcon } from 'dist/icons/error.svg';
import { ReactComponent as WarningIcon } from 'dist/icons/warning.svg';
import { ReactComponent as SuccessIcon } from 'dist/icons/accepted.svg';
import './style.scss';
import { NOTIFICATION_TYPES } from './constants';

export const notification = ({ className, type = NOTIFICATION_TYPES.ERROR, message, ...props }) =>
  notificationAntd.info({
    icon:
      type === NOTIFICATION_TYPES.SUCCESS ? (
        <SuccessIcon />
      ) : type === NOTIFICATION_TYPES.WARNING ? (
        <WarningIcon />
      ) : (
        <ErrorIcon />
      ),
    className: cn('notification-default', type, className),
    duration: 5,
    closeIcon: <CloseIcon className="close-notification" />,
    maxCount: 5,
    top: 80,
    description: '',
    message,
    ...props,
  });
