import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AtsSkeleton, Button, BUTTON_TYPES, Card, Tooltip } from '_atoms';
import { Priority } from '_atoms/Priority';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { OPENING_STATUS_TYPES } from 'constants/opening';
import { ROLES } from 'permission';
import { getTLActiveOpeningsDashboardAPI } from 'actions';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ReactComponent as JobImage } from 'dist/emptyStates/dashboardOpening.svg';
import './style.scss';
import cn from 'classnames';

export const ActiveOpenings = () => {
  const user = useSelector(getUser);
  const showServices = user.roles.filter(r => r.name === ROLES.TEAM_LEAD).length > 1;

  const [openings, setOpenings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allShown, setAllShown] = useState(false);

  const onShowMoreClick = () => setAllShown(s => !s);

  useEffect(() => {
    setLoading(true);
    getTLActiveOpeningsDashboardAPI()
      .then(setOpenings)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Card className="active-openings">
        <div className="active-openings__header">
          Active openings<span className="number">({openings.length})</span>
        </div>
        <div className="active-openings__content-loading">
          <AtsSkeleton height={140} />
        </div>
      </Card>
    );
  }

  if (!openings.length) {
    return (
      <Card className="active-openings">
        <div className="active-openings__header">
          Active openings<span className="number">0</span>
        </div>
        <div className="active-openings__content-empty">
          <JobImage className="empty-image" />
          <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You don&apos;t have active openings yet</Typography.Title3>
        </div>
      </Card>
    );
  }

  return (
    <Card className="active-openings">
      <div className="active-openings__header">
        Active openings<span className="number">{openings.length}</span>
      </div>
      <div className={cn('active-openings__content', { 'show-all': allShown })}>
        {openings.map(opening => (
          <div key={opening.uuid} className="active-opening">
            <Priority priority={opening.priority} />
            <Tooltip label={`${opening.level} ${opening.job_name}`}>
              <a
                href={`/jobs/${opening.job_uuid}/show/openings?scroll=${opening.uuid}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${opening.level} ${opening.job_name}`}
              </a>
            </Tooltip>
            {showServices && (
              <Typography.Caption className="active-opening__service">{opening.service_name}</Typography.Caption>
            )}
            <Status className="active-opening__status" type={OPENING_STATUS_TYPES[opening.status]}>
              {opening.status}
            </Status>
          </div>
        ))}
        {openings.length > 3 && (
          <Button className="show-more" type={BUTTON_TYPES.TEXT} onClick={onShowMoreClick}>
            {allShown ? 'Show less' : 'Show more'}
          </Button>
        )}
      </div>
    </Card>
  );
};
