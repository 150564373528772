import React from 'react';
import { Button, BUTTON_TYPES, RichText, User } from '_atoms';
import { Typography } from '_atoms/Typography';
import { Status, STATUS_TYPES } from '_atoms/Status';
import { dateFormatter } from 'helpers/dateFormatter';
import { ReactComponent as Edit } from 'dist/icons/edit.svg';

export const Feedback = ({ feedback, onEdit, onPostFeedback, showEdit }) => (
  <div className="feedback-info">
    <div className="feedback-info__header">
      <User
        className="feedback-info__user"
        avatar={feedback.approver.profile.avatar}
        fullName={feedback.approver.full_name}
        roles={feedback.approver.roles}
      />
      <div className="feedback-info__info">
        {feedback.is_draft && <Status>Draft</Status>}
        <Typography.Label className="created-at">
          {dateFormatter(feedback.updated_at, 'MMM DD, YYYY [at] HH:mm')}
        </Typography.Label>
        {showEdit && <Button onClick={onEdit} icon={<Edit />} />}
      </div>
    </div>
    <div className="feedback-info__content">
      {feedback.positive !== null && (
        <>
          <Typography.Label>Decision</Typography.Label>
          <Status type={feedback.positive ? STATUS_TYPES.GREEN : STATUS_TYPES.RED}>
            {feedback.positive ? 'Candidate approved' : 'Candidate rejected'}
          </Status>
        </>
      )}
      {feedback.english_level && (
        <>
          <Typography.Label>Assumed English level</Typography.Label>
          <Typography.Text>{feedback.english_level}</Typography.Text>
        </>
      )}
      {feedback.level && (
        <>
          <Typography.Label>Assumed experience level</Typography.Label>
          <Typography.Text>{feedback.level}</Typography.Text>
        </>
      )}
      <Typography.Label>Comment</Typography.Label>
      <RichText text={feedback.comment} />
      {feedback.is_draft && (
        <div className="actions">
          <Button onClick={() => onPostFeedback({ uuid: feedback.uuid })}>clear draft</Button>
          <Button type={BUTTON_TYPES.PRIMARY} onClick={() => onPostFeedback(feedback)}>
            post feedback
          </Button>
        </div>
      )}
    </div>
  </div>
);
