import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import moment from 'moment';
import { Checkbox, Select, Upload, Input, Button, RichArea, Popup } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ExperienceList, FormListDoubleInputSelect, FormListDoubleSelect, FormListSingle } from '_molecules';
import { FormListSources } from '_molecules/FormListSources';
import { validatorL } from '_templates/CreateOpening/utils';
import { getCandidateData, getCandidateSourcesAPI, getDocuments, importCandidateByUrl, uploadPdfCv } from 'actions';
import {
  getCandidateCompanies,
  getCandidateCountries,
  getCandidateSpecialities,
  getCandidateTags,
} from 'store/selectors/candidateSelectors';
import { getLanguages, getLevels, getLocations, getPositions } from 'store/selectors/commonSelectors';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { EMPTY_CANDIDATE_DATA } from '_templates/CandidateCreation/constants';
import { linkRegexp, TIME_ZONE } from 'constants/constants';
import { BUTTON_TEXT } from 'constants/text';
import { ReactComponent as Loader } from 'dist/icons/loader.svg';
import {
  EMAIL_VALIDATION,
  EXPERIENCE_LEVEL_OPTIONS,
  LATIN_VALIDATION,
  PHONE_VALIDATOR,
  SOCIAL_NETWORKS_OPTIONS,
  VALIDATION_MAX_100,
} from './constants';
import { dataMapper, deepEqual, detectNetwork } from './utyls';
import './styls.scss';

export const CandidateCreationForm = ({ form, onCheckDuplicates, isSSC, setLoading }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openLoaderInfoCandidate, setOpenLoaderInfoCandidate] = useState(false);
  const [openSpeciality, setOpenSpeciality] = useState('');
  const [openCity, setOpenCity] = useState(false);
  const [importUrl, setImportUrl] = useState('');

  const offices = useSelector(getLocations);
  const countries = useSelector(getCandidateCountries);
  const languages = useSelector(getLanguages);
  const languageLevels = useSelector(getLevels);
  const positions = useSelector(getPositions);
  const companies = useSelector(getCandidateCompanies);
  const specialties = useSelector(getCandidateSpecialities);
  const tags = useSelector(getCandidateTags);

  const officesOptions = useMemo(() => offices.map(el => ({ value: el.uuid, label: el.name })), [offices]);
  const countriesOptions = useMemo(
    () =>
      countries.map(el => ({
        states: el.states.map(s => ({
          value: s.name,
          label: s.name,
          cities: s.cities.map(c => ({ value: c, label: c })),
        })),
        value: el.name,
        label: el.name,
      })),
    [countries]
  );
  const languageLevelsOptions = useMemo(() => languageLevels.map(el => ({ value: el, label: el })), [languageLevels]);
  const languagesOptions = useMemo(() => languages.map(el => ({ value: el, label: el })), [languages]);
  const positionsOptions = useMemo(() => positions.map(el => ({ value: el, label: el })), [positions]);
  const companiesOptions = useMemo(() => companies.map(el => ({ value: el.uuid, label: el.name })), [companies]);
  const specialtiesOptions = useMemo(() => specialties.map(el => ({ value: el.name, label: el.name })), [specialties]);
  const tagsOptions = useMemo(() => tags.map(el => ({ value: el.uuid, label: el.name })), [tags]);

  const onChangeExperienceCurrent = key => {
    const fields = form.getFieldsValue();
    const { experience } = fields;
    Object.assign(experience[key] || {}, { worked_till: moment() });
    form.setFieldsValue({ experience });
  };

  const setCandidateData = response => {
    const resp = isSSC ? response.candidate_profile : response;
    const data = dataMapper(resp);
    form.setFields(data);
    if (resp.cvs.length) {
      dispatch(getDocuments(resp.cvs)).then(cvs => {
        form.setFieldValue(
          'cvs',
          cvs.map(d => ({ uuid: d.uuid, uid: d.uuid, name: d.filename }))
        );
      });
    }
  };

  const onDropPdfFiles = ({ file }) => {
    const formData = new FormData();
    formData.append('file', file);
    setOpenLoaderInfoCandidate(true);
    uploadPdfCv(formData)
      .then(setCandidateData)
      .finally(() => setOpenLoaderInfoCandidate(false));
  };

  const validImportUrl = () => {
    const patt = new RegExp(linkRegexp, 'i');
    const link = form.getFieldValue('resume_link');
    if (!patt.test(link)) {
      form.setFields([
        {
          name: 'resume_link',
          errors: ["The candidate hasn't been found. Please check the correctness of the link provided."],
        },
      ]);
    } else {
      form.setFields([{ name: 'resume_link', errors: [] }]);
      const { resume_link, ...values } = form.getFieldsValue();
      const { description_initial, ...initialValues } = EMPTY_CANDIDATE_DATA;
      if (deepEqual(values, initialValues)) {
        setImportUrl('');
        onImportUrl();
      } else {
        setImportUrl(link);
      }
    }
  };

  const onImportUrl = () => {
    setImportUrl('');
    const link = form.getFieldValue('resume_link');
    setOpenLoaderInfoCandidate(true);
    importCandidateByUrl(link)
      .then(setCandidateData)
      .then(onCheckDuplicates)
      .finally(() => setOpenLoaderInfoCandidate(false));
  };

  const onDetectLink = (value, key) => {
    const fields = form.getFieldsValue();
    const { social_networks } = fields;
    const network = detectNetwork(value);
    Object.assign(social_networks[key] || {}, { network });
    form.setFieldsValue({ social_networks });
  };

  const onCountryChange = c => {
    if (!c) {
      form.setFieldValue('state', undefined);
    } else if (c.states.length === 1 && c.states[0].value === 'Default state') {
      form.setFieldValue('state', c.states[0]);
    }
  };

  useEffect(() => {
    if (!openCity && !openSpeciality) document.activeElement.blur();
  }, [openCity, openSpeciality]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getCandidateData(id, isSSC).then(resp => {
        setCandidateData(resp);
        setLoading(false);
      });
    }
    dispatch(getCandidateSourcesAPI());
  }, []);

  return (
    <>
      <div className="form-candidate-creation__parsing">
        <Typography.Title2 className="form-candidate-creation__title" weight={TYPOGRAPHY_WEIGHT.BOLD} id="parsing">
          Parsing
        </Typography.Title2>
        <Row gutter={[32, 0]} align="bottom">
          <Col span={12}>
            <Input
              label="Import from rabota.by, hh.ru"
              placeholder="Please enter link"
              itemProps={{ name: 'resume_link' }}
            />
          </Col>
          <Col span={12}>
            <Button onClick={validImportUrl}>{BUTTON_TEXT.IMPORT}</Button>
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col span={12}>
            <Upload
              itemProps={{ name: '' }}
              className="parsing-pdf-block__upload"
              label="Parse PDF file"
              onChange={onDropPdfFiles}
              accept="application/pdf"
              fileList={[]}
              defaultFileList={[]}
            />
          </Col>
        </Row>
      </div>

      <Typography.Title2 className="form-candidate-creation__title" weight={TYPOGRAPHY_WEIGHT.BOLD} id="profile">
        Profile
      </Typography.Title2>
      <Row gutter={[32, 0]}>
        <Col span={24} lg={12} md={12} sm={24}>
          <Input
            label="First name (Latin)"
            placeholder="Please enter latin first name"
            maxLength={50}
            required
            itemProps={{
              name: 'first_name_latin',
              rules: [LATIN_VALIDATION],
            }}
            onBlur={onCheckDuplicates}
          />
        </Col>
        <Col span={24} lg={12} md={12} sm={24}>
          <Input
            label="Last name (Latin)"
            placeholder="Please enter latin last name"
            maxLength={50}
            required
            itemProps={{
              name: 'last_name_latin',
              rules: [LATIN_VALIDATION],
            }}
            onBlur={onCheckDuplicates}
          />
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col span={12} lg={12} md={12} sm={24}>
          <Input
            label="First name (Local)"
            placeholder="Please enter latin first name"
            maxLength={50}
            itemProps={{ name: 'first_name_local' }}
            onBlur={onCheckDuplicates}
          />
        </Col>
        <Col span={12} lg={12} md={12} sm={24}>
          <Input
            label="Last name (Local)"
            placeholder="Please enter latin first name"
            maxLength={50}
            itemProps={{ name: 'last_name_local' }}
            onBlur={onCheckDuplicates}
          />
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col span={24} lg={12} md={12} sm={24}>
          <Select
            labelInValue
            label="Country"
            options={countriesOptions}
            itemProps={{ name: 'country' }}
            onChange={onCountryChange}
            onClear={() => {
              form.setFieldValue('state', undefined);
              form.setFieldValue('city', []);
            }}
            allowClear
          />
        </Col>
        <Col span={24} lg={12} md={12} sm={24}>
          <Form.Item noStyle shouldUpdate={(prev, curr) => prev.state !== curr.state}>
            {({ getFieldValue }) => (
              <Select
                className="single-select-tags"
                open={openCity}
                onDropdownVisibleChange={setOpenCity}
                onChange={() => setOpenCity(false)}
                labelInValue
                label="City"
                options={
                  countriesOptions
                    .find(el => el.value === getFieldValue('country')?.value)
                    ?.states.find(el => el.value === getFieldValue('state')?.value)?.cities || []
                }
                itemProps={{ name: 'city' }}
                disabled={!getFieldValue('state')?.value}
                maxTagCount={1}
                mode={SELECT_TYPES.CREATABLE}
                allowClear
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.country !== curr.country}>
        {({ getFieldValue }) => {
          const options = countriesOptions.find(el => el.value === getFieldValue('country')?.value)?.states || [];
          if (!options.length || (options.length === 1 && options[0].label === 'Default state')) return null;
          return (
            <Row gutter={[32, 0]}>
              <Col span={24} lg={12} md={12} sm={24}>
                <Select
                  labelInValue
                  label="State"
                  options={options}
                  itemProps={{ name: 'state' }}
                  disabled={!getFieldValue('country')}
                  allowClear
                />
              </Col>
            </Row>
          );
        }}
      </Form.Item>
      <Row gutter={[32, 0]}>
        <Col span={24} lg={12} md={12} sm={24}>
          <Select
            labelInValue
            label="Office location"
            mode={SELECT_TYPES.MULTIPLE}
            options={officesOptions}
            itemProps={{ name: 'offices' }}
            allowClear
          />
        </Col>
        <Col span={24} lg={12} md={12} sm={24}>
          <Select labelInValue label="Time zone" options={TIME_ZONE} itemProps={{ name: 'timezone' }} />
        </Col>
      </Row>
      <FormListSources />
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.description_initial !== curr.description_initial}>
        {({ getFieldValue, setFieldValue }) => (
          <Form.Item noStyle name="description">
            <RichArea
              label="Description"
              placeholder="Please enter the description"
              className="form-candidate-creation__description"
              initialValue={getFieldValue('description_initial')}
              onChange={v => setFieldValue('description', v)}
            />
          </Form.Item>
        )}
      </Form.Item>

      <div className="form-candidate-creation__languages">
        <FormListDoubleSelect
          listName="foreign_languages"
          buttonText="Add language"
          firstSelectProps={{
            name: 'language',
            label: 'Foreign language',
            options: languagesOptions,
          }}
          secondSelectProps={{
            name: 'level',
            options: languageLevelsOptions,
          }}
          firstSelectValidator={validatorL('level', 'Please select language')}
          secondSelectValidator={validatorL('language', 'Please select language level')}
        />
      </div>

      <div className="form-candidate-creation__relocation">
        <Typography.Label className="form-candidate-creation__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
          Relocation
        </Typography.Label>
        <Checkbox label="Ready to relocate" itemProps={{ name: 'is_ready_to_relocate' }} />
        <Checkbox label="Ready for business trips" itemProps={{ name: 'is_ready_to_business_trips' }} />
      </div>

      <Typography.Title2
        className="form-candidate-creation__title"
        weight={TYPOGRAPHY_WEIGHT.BOLD}
        id="contact_information"
      >
        Contact information
      </Typography.Title2>
      <Row gutter={[32, 0]}>
        <Col span={24} lg={12} md={12} sm={24}>
          <FormListSingle
            listName="phone"
            label="Phone number"
            placeholder="Enter phone number"
            buttonText="Add phone number"
            maxCount={3}
            onBlur={onCheckDuplicates}
            maxLength={16}
            rules={[PHONE_VALIDATOR]}
          />
        </Col>
        <Col span={24} lg={12} md={12} sm={24}>
          <FormListSingle
            listName="email"
            label="Email address"
            placeholder="Enter email address"
            buttonText="Add email"
            maxCount={3}
            rules={[EMAIL_VALIDATION]}
            onBlur={onCheckDuplicates}
          />
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col span={24} lg={12} md={12} sm={24}>
          <Input
            label="Telegram"
            placeholder="Enter telegram profile"
            itemProps={{ name: 'telegram' }}
            maxLength={32}
          />
        </Col>
        <Col span={24} lg={12} md={12} sm={24}>
          <Input
            label="Skype"
            placeholder="Enter skype profile"
            itemProps={{ name: 'skype' }}
            onBlur={onCheckDuplicates}
            maxLength={32}
          />
        </Col>
      </Row>

      <FormListDoubleInputSelect
        listName="social_networks"
        label="Links"
        buttonText="Add link"
        firstItemName="link"
        secondItemName="network"
        inputProps={{
          placeholder: 'Please enter link',
          maxLength: 1000,
          onChange: (e, f) => onDetectLink(e.target.value, f.name),
        }}
        options={SOCIAL_NETWORKS_OPTIONS}
        maxCount={5}
      />

      <div className="form-candidate-creation__experience">
        <Typography.Title2 className="form-candidate-creation__title" weight={TYPOGRAPHY_WEIGHT.BOLD} id="experience">
          Experience
        </Typography.Title2>
        <FormListDoubleSelect
          listName="specialities"
          buttonText="Add Speciality"
          firstSelectValidator={validatorL('level', 'Please select speciality', 'experience')}
          secondSelectValidator={validatorL('speciality', 'Please select speciality', 'experience')}
          firstSelectProps={{
            name: 'speciality',
            label: 'Speciality',
            options: specialtiesOptions,
            open: openSpeciality,
            onDropdownVisibleChange: (open, field) => {
              setOpenSpeciality(open ? field : '');
            },
            onChange: () => setOpenSpeciality(''),
            labelInValue: true,
            className: 'single-select-tags',
            maxTagCount: 1,
            mode: SELECT_TYPES.CREATABLE,
            itemProps: { rules: [VALIDATION_MAX_100] },
          }}
          secondSelectProps={{
            name: 'level',
            label: 'Experience Level',
            options: EXPERIENCE_LEVEL_OPTIONS.slice(0, -1),
            allowClear: false,
          }}
          maxCount={10}
        />
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row gutter={[32, 0]}>
              <Col span={24} lg={12} md={12} sm={24}>
                <Upload
                  accept="application/pdf, .jpg, .doc, .docx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                  label="Documents"
                  multiple
                  maxCount={5}
                  itemProps={{ name: 'cvs' }}
                />
              </Col>
              <Col span={24} lg={12} md={12} sm={24}>
                <Input label="CV Links" placeholder="Please past CV link" itemProps={{ name: 'cv_link_0' }} />
                <Input placeholder="Please past CV link" itemProps={{ name: 'cv_link_1' }} />
              </Col>
            </Row>
          </Col>
          <Select
            labelInValue
            label="Tags"
            mode={SELECT_TYPES.CREATABLE}
            options={tagsOptions}
            itemProps={{ name: 'tags', rules: [VALIDATION_MAX_100] }}
          />
        </Row>
      </div>

      <Typography.Title2 className="form-candidate-creation__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
        Working experience
      </Typography.Title2>
      <ExperienceList
        onChangeExperienceCurrent={onChangeExperienceCurrent}
        companiesOptions={companiesOptions}
        positionsOptions={positionsOptions}
        maxCount={10}
        form={form}
      />
      <Popup open={openLoaderInfoCandidate} className="form-candidate-creation__extracting-popup">
        <Loader />
        <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>Please wait</Typography.Title3>
        <Typography.Text>Extracting information can take some time</Typography.Text>
      </Popup>
      <Popup
        open={!!importUrl}
        title="Content might be overwritten"
        cancelButtonProps={{
          children: 'Back to page',
          onClick: () => setImportUrl(''),
        }}
        okButtonProps={{
          children: 'Proceed import',
          onClick: onImportUrl,
        }}
        className="form-candidate-creation__overwrite-popup"
      >
        <Typography.Text>Some information about the candidate might be overwritten.</Typography.Text>
        <Typography.Text> </Typography.Text>
        <Typography.Text>Do you want to proceed import?</Typography.Text>
      </Popup>
    </>
  );
};
