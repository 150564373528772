import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  listJobsActive: [],
  listTasks: [],
  listInterviews: [],
  listReminders: [],
  listOpenings: [],
  listNewJobsHRL: [],
  listNewJobsRM: [],
  listHrTasks: [],
};

const dashboardSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    setListDataDashboard(state, { payload }) {
      state[payload.field] = payload.data;
    },
    resetDashboardReducer(state) {
      state = initialState;
    },
  },
});

export const { setListDataDashboard, resetDashboardReducer } = dashboardSlice.actions;
export default dashboardSlice.reducer;
