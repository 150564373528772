export const getJobListFilters = state => state.job.filter;
export const getJobData = state => state.job.jobData;
export const getOpeningsList = state => state.job.openingsList;
export const getOpeningCandidate = state => state.job.openingCandidate;
export const getJobSteps = state => state.job.steps;
export const getRooms = state => state.job.rooms;
export const getJobCandidates = state => state.job.candidates;
export const getIsCandidateExistsOnJob = state => state.job.isCandidateExistsOnJob;
export const getJobLoader = state => state.job.loader;
export const getJobCandidateDataLoading = state => state.job.candidateDataLoading;
export const getJobApprovers = state => state.job.approversCandidate;
export const getJobApproversLoading = state => state.job.approversCandidateLoading;
export const getJobCVS = state => state.job.cvs;
export const getJobCVSLinks = state => state.job.cvsLinks;
export const getJobUserData = state => state.job.userData;
export const getJobScorecard = state => state.job.scorecard;
export const getJobInterview = state => state.job.interview;
export const getJobInterviewLoading = state => state.job.interview_loading;
export const getJobOffering = state => state.job.offeringCandidate;
export const getJobOfferingLoading = state => state.job.offeringCandidateLoading;
export const getJobOfferRecommendation = state => state.job.offerRecommendation;
export const getJobProbationPeriod = state => state.job.probationPeriod;
export const getJobEntryDateLoading = state => state.job.entryDateLoading;
export const getJobActivityListLoading = state => state.job.activityListLoading;
export const getJobTimelineCommentLoading = state => state.job.timelineCommentLoading;
export const getTimelineLoading = state => state.job.timelineLoading;
