import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import './dropDownMenu.scss';
import { Menu } from '../Icons';

export const DropDownMenu = ({ className, children, defaultOpen, triggerElement = <Menu />, ...props }) => {
  const [open, setOpen] = useState(defaultOpen);
  const ref = useRef();
  const toggleOpen = useCallback(() => setOpen(o => !o), []);
  const closeMenu = useCallback(e => {
    if (ref && !ref.current?.contains(e.target)) setOpen(false);
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', closeMenu);
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, []);
  return (
    <div role="none" ref={ref} className={cn('dropdown-default', { open }, className)} onClick={toggleOpen} {...props}>
      {triggerElement}
      <ul>{children}</ul>
    </div>
  );
};
