import React from 'react';
import { Col, Form, Row } from 'antd';
import { Button, BUTTON_TYPES, Input, Select } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as Union } from 'dist/icons/Union.svg';
import { ReactComponent as Bin } from 'dist/icons/bin.svg';
import './style.scss';

export const FormListDoubleInputSelect = ({
  label,
  listName,
  firstItemName,
  secondItemName,
  options,
  buttonText,
  inputProps,
  maxCount = 5,
}) => (
  <Form.List name={listName}>
    {(fields, { add, remove }) => (
      <div className="form-list">
        <Typography.Label weight={TYPOGRAPHY_WEIGHT.BOLD}>{label}</Typography.Label>
        {fields.map((field, index) => (
          <Row key={field.key} gutter={[32, 8]} className="form-list__item">
            <Col span={24} lg={12} md={12} sm={24} className="form-list__item__first">
              <Input
                {...inputProps}
                onChange={e => inputProps?.onChange && inputProps?.onChange(e, field)}
                itemProps={{ ...field, name: [field.name, firstItemName], fieldKey: [field.fieldKey, firstItemName] }}
              />
            </Col>
            <Col span={24} lg={12} md={12} sm={24} className="form-list__item__second">
              <div>
                <Row align="middle" gutter={[20, 0]}>
                  <Col flex="1 1 0">
                    <Select
                      options={options}
                      itemProps={{
                        ...field,
                        name: [field.name, secondItemName],
                        fieldKey: [field.fieldKey, secondItemName],
                      }}
                      allowClear
                    />
                  </Col>
                  {!!index && (
                    <Col>
                      <Button
                        className="original-svg form-list__item__delete"
                        type={BUTTON_TYPES.GHOST}
                        onClick={() => remove(field.name)}
                        icon={<Bin />}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        ))}
        {fields.length < maxCount && (
          <Button icon={<Union />} onClick={() => add()}>
            {buttonText}
          </Button>
        )}
      </div>
    )}
  </Form.List>
);
