export const ANALYTICS_COLORS = [
  '#821F00',
  '#E13800',
  '#FC4C02',
  '#FF9D5B',
  '#FFDABB',
  '#225FBF',
  '#629CF8',
  '#ADCDFF',
  '#E3EDFD',
  '#156F2F',
  '#22B44B',
  '#88E7A1',
  '#D3F6DC',
  '#E09F00',
  '#FFB500',
  '#FFDA80',
];
