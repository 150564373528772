import React from 'react';
import { Link } from 'react-router-dom';
import history from 'helpers/history';
import { Tooltip, Button, BUTTON_TYPES } from '_atoms';
import { Priority } from '_atoms/Priority';
import { Typography } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { getJobStatusType } from '_molecules/JobHeader/utils';
import { OPENING_STATUS_TYPES } from 'constants/opening';
import { dateFormatter } from 'helpers/dateFormatter';
import emptyImg from 'dist/emptyStates/dashboardOpening.svg';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import { ReactComponent as CircularProgress } from 'dist/icons/loading.svg';

export const headerCellRender = (title, field, key, sort, setSorting) => () => {
  const order = sort.key === key ? sort.order : null;
  const onClick = () => setSorting({ field, key, order: order === 'asc' ? 'desc' : 'asc' });
  return (
    <>
      {title}
      <Sort className={order} onClick={onClick} />
    </>
  );
};

export const expandedRowRender = (openings, job_uuid) => {
  const toOpenings = () => history.push(`/jobs/${job_uuid}/show/openings?statuses=Open`);
  return openings.openings?.length ? (
    <>
      {openings.openings.map(
        (opening, idx) =>
          idx < 6 && (
            <Link
              key={`opening_${idx + 1}`}
              className="table-jobs__opening"
              to={`/jobs/${job_uuid}/show/openings?scroll=${opening.uuid}`}
            >
              <Priority priority={opening.priority} />
              <Typography.Caption className="level">{opening.level}</Typography.Caption>
              <Typography.Caption className="expected-date">
                Expected on {dateFormatter(opening.expected)}
              </Typography.Caption>
              <div className="status">
                <Status type={OPENING_STATUS_TYPES[opening.status]}>{opening.status}</Status>
              </div>
              <Tooltip title={opening.resolution}>
                <Typography.Caption ellipsis className="resolution">
                  {opening.resolution}
                </Typography.Caption>
              </Tooltip>
            </Link>
          )
      )}
      {openings?.openings?.length > 6 && (
        <div className="table-jobs__opening__link">
          <Button type={BUTTON_TYPES.TEXT} onClick={() => toOpenings(job_uuid)}>
            {`+ ${openings.openings.length - 6} more openings`}
          </Button>
        </div>
      )}
    </>
  ) : (
    <Typography.Caption className="table-jobs__opening_empty">
      There are no active openings
      {openings?.loading && (
        <div className="loading-overflow">
          <CircularProgress />
        </div>
      )}
    </Typography.Caption>
  );
};

export const COLUMNS = (sort, setSorting) => [
  {
    title: headerCellRender('Job', 'name', 'name', sort, setSorting),
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    className: 'table-jobs__name',
    render: (name, { uuid }) => (
      <Tooltip label={name}>
        <Link to={`/jobs/${uuid}/show/steps`}>{name}</Link>
      </Tooltip>
    ),
  },
  {
    title: headerCellRender('Team Lead', 'team_lead_full_name', 'team_lead_full_name', sort, setSorting),
    dataIndex: 'team_lead_full_name',
    key: 'team_lead_full_name',
    width: 168,
    sorter: true,
    className: 'table-jobs__team-lead',
    render: name => <Tooltip label={name}>{name}</Tooltip>,
  },
  {
    title: headerCellRender('Job status', 'status', 'status', sort, setSorting),
    dataIndex: 'status',
    key: 'status',
    width: 138,
    sorter: true,
    className: 'table-jobs__status',
    render: status => <Status type={getJobStatusType(status)}>{status}</Status>,
  },
  {
    title: headerCellRender('Active openings', 'openings_count', 'openings_count', sort, setSorting),
    dataIndex: 'openings_count',
    key: 'openings_count',
    width: 110,
    sorter: true,
    align: 'right',
    className: 'table-jobs__openings',
  },
  {
    title: headerCellRender('Active candidates', 'candidates_count', 'candidates_count', sort, setSorting),
    dataIndex: 'candidates_count',
    key: 'candidates_count',
    width: 120,
    sorter: true,
    align: 'right',
    className: 'table-jobs__candidates-count',
  },
];

export const EMPTY_OPTIONS = {
  image: emptyImg,
  title: 'No jobs found',
  subtitle: 'Please try different search criteria',
};

export const TEXT = {
  TITLE: 'Jobs',
  TOOLTIP: 'Job is considered as a position with a certain description and requirements',
};

export const INITIAL_VALUES = {
  sorting: { key: 'status', order: 'asc', field: 'status' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};
