import React from 'react';
import { Form, Input } from 'antd';
import cn from 'classnames';
import { Typography } from '_atoms/Typography';
import './style.scss';

const { TextArea: TextAreaAntd } = Input;

export const TextArea = ({ className, value, onChange, disabled, error, itemProps, ...props }) => (
  <div className={cn('textarea-default-wrapper', className, { error })}>
    {props.label !== undefined && (
      <Typography.Label>{props.required ? `${props.label} *` : props.label}</Typography.Label>
    )}
    <Form.Item {...itemProps}>
      <TextAreaAntd
        className="textarea-default"
        disabled={disabled}
        maxLength={props.maxLength || 2000}
        placeholder={props.placeholder}
        value={value}
        onChange={onChange}
        autoFocus={props.autoFocus}
        onBlur={props.onBlur}
        showCount
        rows={5}
      />
    </Form.Item>
  </div>
);
