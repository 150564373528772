import React from 'react';
import { Link } from 'react-router-dom';
import { Label } from '_atoms/Typography/Typography';
import { TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as BreadArrow } from 'dist/icons/Vector1.svg';
import './breadCrumbs.scss';

export const BreadCrumbs = ({ path, element, elementName }) => (
  <div className="bread-crumbs__wrapper">
    <div className="bread-crumbs">
      <Link className=" bread-crumbs_element" to={`${path}`}>
        {element}
      </Link>
      <BreadArrow className="bread-crumbs_arrow" />
      <Label className=" bread-crumbs_name" weight={TYPOGRAPHY_WEIGHT.NORMAL}>
        {elementName}
      </Label>
    </div>
  </div>
);
