import React from 'react';
import cn from 'classnames';
import { Button, BUTTON_TYPES, Card } from '_atoms';
import { Typography } from '_atoms/Typography';
import { ScrollPanel } from './ScrollPanel';
import './style.scss';

export const FormCreateEdit = ({ className, options = [], children, title, loading, ...props }) => (
  <div className={cn('form-wrapper', className)}>
    <div className="form-header__wrapper">
      <Card className="form-header">
        <Typography.Title1 className="form-header__title">{title}</Typography.Title1>
        <div className="form-header__buttons">
          <Button onClick={props.onCancel} disabled={loading}>
            {props.cancelText}
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} htmlType="submit" onClick={props.onOk} loading={loading}>
            {props.okText}
          </Button>
        </div>
      </Card>
    </div>
    <div className="form-body">
      {!!options.length && (
        <Card className="form-body__tabs">
          <ScrollPanel options={options} />
        </Card>
      )}
      <Card className={cn('form-body__content', props.contentClassName)}>{children}</Card>
    </div>
  </div>
);
