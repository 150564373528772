import React from 'react';
import { useParams } from 'react-router-dom';
import { subject } from '@casl/ability';
import { Col, Form, Row } from 'antd';
import { RichArea, Select, Input } from '_atoms';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { Typography } from '_atoms/Typography';
import { ACTIONS, Can, UI } from 'permission';
import { REQUIRED_JOB_NAME, REQUIRED_SERVICE, REQUIRED_TEAM_LEAD } from './constants';
import './styles.scss';

export const JobCreationForm = ({ serviceOptions, teamLeadOptions, recruiters, recruiterLeads, jobStatus }) => {
  const { id } = useParams();

  return (
    <Row>
      <Col flex="900px 0 0">
        <Row>
          <Col lg={12} md={12} sm={24}>
            <Typography.Title2>General</Typography.Title2>
            <Can I={ACTIONS.UPDATE} a={UI.JOB_INFO_NAME} passThrough>
              {canEdit => (
                <Input
                  label="Job name"
                  placeholder="Please enter Job name"
                  maxLength={100}
                  disabled={!canEdit}
                  itemProps={{ name: 'name', rules: [REQUIRED_JOB_NAME] }}
                />
              )}
            </Can>
            <Can I={ACTIONS.UPDATE} a={UI.JOB_SERVICE} passThrough>
              {canEdit => (
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.service !== curr.service}>
                  {({ setFields }) => (
                    <Select
                      labelInValue
                      label="Service"
                      placeholder="Please select Service"
                      options={serviceOptions}
                      disabled={!canEdit}
                      itemProps={{ name: 'service', rules: [REQUIRED_SERVICE] }}
                      onChange={({ resource_manager, service_head, company }) =>
                        setFields([
                          { name: 'resource_manager', value: resource_manager },
                          { name: 'service_head', value: service_head },
                          { name: 'company', value: company },
                        ])
                      }
                    />
                  )}
                </Form.Item>
              )}
            </Can>
            <Select label="Company" placeholder="Please select Company" disabled itemProps={{ name: 'company' }} />
          </Col>
          <Can I={ACTIONS.UPDATE} a={UI.JOB_DESCRIPTION} passThrough>
            {canEdit => (
              <Form.Item noStyle shouldUpdate={(prev, curr) => prev.description_initial !== curr.description_initial}>
                {({ getFieldValue, setFieldValue }) => (
                  <Form.Item name="description" className="form-job-creation__form-item">
                    <RichArea
                      label="Description"
                      placeholder="Enter description here"
                      disabled={!canEdit}
                      initialValue={getFieldValue('description_initial')}
                      onChange={v => setFieldValue('description', v)}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            )}
          </Can>
          <Can I={ACTIONS.UPDATE} a={UI.JOB_BENEFITS} passThrough>
            {canEdit => (
              <Form.Item noStyle shouldUpdate={(prev, curr) => prev.benefits_initial !== curr.benefits_initial}>
                {({ getFieldValue, setFieldValue }) => (
                  <Form.Item name="benefits" className="form-job-creation__form-item">
                    <RichArea
                      label="Benefits"
                      placeholder="Enter benefits here"
                      disabled={!canEdit}
                      initialValue={getFieldValue('benefits_initial')}
                      onChange={v => setFieldValue('benefits', v)}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            )}
          </Can>
        </Row>
        <Row className="form-job-creation__team">
          <Col span={24}>
            <Typography.Title2>Team</Typography.Title2>
            <Row gutter={[32, 0]}>
              <Col lg={12} md={12} sm={24}>
                <Can I={ACTIONS.UPDATE} a={UI.JOB_TEAM_LEAD} passThrough>
                  {canEdit => (
                    <Select
                      labelInValue
                      label="Team Lead"
                      options={teamLeadOptions}
                      disabled={!canEdit}
                      itemProps={{ name: 'team_lead', rules: [REQUIRED_TEAM_LEAD] }}
                    />
                  )}
                </Can>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Can I={ACTIONS.READ} a={subject(UI.UNASSIGN_RECRUITER_LEAD, { status: jobStatus })} passThrough>
                  {access => (
                    <Select
                      labelInValue
                      label="Recruiter Lead(s)"
                      options={recruiterLeads}
                      disabled={!id || !access}
                      mode={SELECT_TYPES.MULTIPLE}
                      itemProps={{ name: 'recruiter_leads' }}
                    />
                  )}
                </Can>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col lg={12} md={12} sm={24}>
                <Select labelInValue label="Resources manager" disabled itemProps={{ name: 'resource_manager' }} />
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Can I={ACTIONS.UPDATE} a={subject(UI.JOB_RECRUITER, { status: jobStatus })} passThrough>
                  {canEdit => (
                    <Select
                      labelInValue
                      label="Recruiter(s)"
                      options={recruiters}
                      disabled={!canEdit}
                      mode={SELECT_TYPES.MULTIPLE}
                      itemProps={{ name: 'recruiters' }}
                    />
                  )}
                </Can>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col lg={12} md={12} sm={24}>
            <Select labelInValue label="Service head" disabled itemProps={{ name: 'service_head' }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
