import moment from 'moment';

export const dateFormatter = (date, format = 'MMM DD, YYYY') =>
  moment.utc(date).add(moment().utcOffset(), 'm').format(format);
export const dateForInterview = (date, format = 'LT') => moment(date, format).add(30, 'minutes').format(format);
export const timeValue = (date, format = 'YYYY-MM-DD HH:mm:ssZ') => moment(date, format).format('HH:mm');
export const allDate = (date, format = 'YYYY-MM-DDTHH:mm:ssZ') => moment(date, format).toDate();
export const utcTime = date => moment.utc(moment(date, 'HH:mm')).format('HH:mm:ssZ');
export const utcTimeForRooms = date => moment.utc(moment(date, 'HH:mm')).format('HH:mm');
export const utcFormatDate = date => moment.utc(date).toISOString().split('.')[0] + moment().format('Z');
export const getTotalTime = (date, type) => moment(date, 'HH:mm').get(type);
export const reminderDate = data =>
  moment.utc(moment(new Date(data.datetime).toUTCString()), 'YYYY-MM-DD-HH-mm').toISOString();
export const getDateForAnalitics = value => moment(value).format('YYYY-MM-DD');
