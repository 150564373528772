import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { Button, Card, Popup, Table } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Context } from '_templates/AnalyticsDashboard/analytics-dashboard';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from 'constants/text';
import { TableHeader } from '_molecules';
import { ReactComponent as Upload } from 'dist/icons/upload.svg';
import { ReactComponent as EmptyImage } from 'dist/images/common/icons/changelog.svg';
import { ReactComponent as CircularProgress } from 'dist/icons/loading.svg';
import { analyticWidgets } from 'actions/analyticActions';
import {
  hiringStatisticsColumnsMapper,
  hiringStatisticsDataMapper,
  successRateColumnsMapper,
  successRateDataMapper,
  useExpandableColumn,
} from './utils';
import { COLUMNS } from './constants';
import './style.scss';

export const AnalyticsReportingInfo = () => {
  const { appliedFilters } = useContext(Context);
  const [hiringStatisticsExpanded, onChangeStatisticsExpanded] = useState([]);
  const [{ hiringData, hiringLoading, hiringEmpty }, setHiringState] = useState({
    hiringData: [],
    hiringLoading: false,
    hiringEmpty: true,
  });
  const [{ successData, successLoading, successEmpty }, setSuccessState] = useState({
    successData: [],
    successLoading: false,
    successEmpty: true,
  });
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);

  const onChangeOpen1 = ({ id }) => {
    if (hiringStatisticsExpanded.includes(id)) {
      onChangeStatisticsExpanded(s => s.filter(el => el !== id));
    } else {
      onChangeStatisticsExpanded(s => [...s, id]);
    }
  };

  const column = useExpandableColumn(hiringStatisticsExpanded, onChangeOpen1);

  const [col1, setCol1] = useState([]);
  const [col2, setCol2] = useState([]);

  const exportExcel = () => {
    analyticWidgets('hiring-statistics/export/xlsx', appliedFilters).then(() => {
      setSuccessPopupOpen(true);
    });
  };

  const onApplyAnalytics = () => {
    setHiringState(d => ({ ...d, hiringLoading: true }));
    setSuccessState(d => ({ ...d, successLoading: true }));
    analyticWidgets('hiring-statistics', appliedFilters)
      .then(resp => {
        const empty = !resp.reduce(
          (s, r) =>
            s +
            r.transitions.reduce((sum, t) => sum + t.periods.reduce((per, p) => per + p.confirmed_on_transition, 0), 0),
          0
        );
        setCol1(hiringStatisticsColumnsMapper(resp[0].transitions[0].periods));
        setHiringState(d => ({
          ...d,
          hiringLoading: false,
          hiringData: hiringStatisticsDataMapper(resp),
          hiringEmpty: empty,
        }));
      })
      .catch(() => {
        setCol1([]);
        setHiringState({ hiringData: [], hiringLoading: false, hiringEmpty: true });
      });

    analyticWidgets('success-rate', appliedFilters)
      .then(resp => {
        const empty = !resp.reduce((s, r) => s + r.periods.reduce((per, p) => per + p.confirmed_on_transition, 0), 0);
        setCol2([...COLUMNS, ...successRateColumnsMapper(resp)]);
        setSuccessState({ successData: successRateDataMapper(resp), successLoading: false, successEmpty: empty });
      })
      .catch(() => {
        setCol2([]);
        setSuccessState({ successData: [], successLoading: false, successEmpty: true });
      });
  };

  useEffect(onApplyAnalytics, [appliedFilters]);

  return (
    <>
      <Card className="hiring-statistics">
        <TableHeader
          title="Hiring statistics"
          extra={
            <Button icon={<Upload />} onClick={exportExcel}>
              Export Excel
            </Button>
          }
        />
        {!hiringEmpty ? (
          <Table
            loading={hiringLoading}
            rowKey="id"
            rowClassName={r =>
              cn(
                { 'hiring-statistics__bold-row': r.title },
                { 'hiring-statistics__hide-row': r.relatedId },
                { hidden: r.relatedId && !hiringStatisticsExpanded.includes(r.relatedId) }
              )
            }
            columns={[column, ...col1]}
            data={col1.length > 1 ? hiringData : []}
            showCardHeader={false}
            showCardFooter={false}
          />
        ) : (
          <EmptyTable loading={hiringLoading} />
        )}
      </Card>
      <Card className="success-rate">
        <TableHeader title="Success rate" />
        {!successEmpty ? (
          <Table
            rowKey="id"
            rowClassName={r => cn({ 'hiring-statistics__bold-row': r.title })}
            loading={successLoading}
            columns={col2}
            data={successData}
            showCardHeader={false}
            showCardFooter={false}
          />
        ) : (
          <EmptyTable loading={successLoading} />
        )}
      </Card>
      <Popup
        open={successPopupOpen}
        title={POPUP_LABELS.SUCCESS}
        okButtonProps={{ onClick: () => setSuccessPopupOpen(false), children: BUTTON_TEXT.OK }}
      >
        {POPUP_BODY.FILE_SENT}
      </Popup>
    </>
  );
};

const EmptyTable = ({ loading }) => (
  <div className="empty-table">
    <EmptyImage className="empty-table__image" />
    <Typography.Title2 className="empty-table__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
      No data found
    </Typography.Title2>
    <Typography.Title3 className="empty-table__subtitle" weight={TYPOGRAPHY_WEIGHT.BOLD}>
      Please try another search criteria
    </Typography.Title3>
    {loading && (
      <div className="empty-table__loading">
        <CircularProgress width={40} height={40} />
      </div>
    )}
  </div>
);
