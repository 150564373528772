export const formValuesMapper = values => ({
  ...values,
  level: values.level?.value,
  english_level: values.english_level?.value,
});

export const dataMapper = (resp, userUuid) => {
  const empty = resp.find(f => !f.comment && f.approver.uuid === userUuid);
  const otherFeedbacks = resp.filter(f => !!f.comment);

  if (empty) otherFeedbacks.unshift(empty);
  return otherFeedbacks;
};
