import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { getStatusType } from '_molecules/JobCandidateInfo/utils';

export const COLUMNS = [
  {
    title: 'Job & Step',
    dataIndex: 'job_step',
    key: 'job_step',
    width: 180,
    render: data => (
      <Link to={data.link} className="candidate-link">
        <Typography.Text>{data.job}</Typography.Text>
        <Typography.Caption>{data.step}</Typography.Caption>
      </Link>
    ),
  },
  {
    title: 'Candidate status',
    dataIndex: 'status',
    key: 'status',
    width: 164,
    render: data => <Status type={getStatusType(data)}>{data}</Status>,
  },
  {
    title: 'Responsible Recruiter',
    dataIndex: 'recruiter',
    key: 'recruiter',
    width: 180,
  },
  {
    title: 'Responsible Team Lead',
    dataIndex: 'team_lead',
    key: 'team_lead',
    width: 180,
  },
];
