import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AtsSkeleton, Button, Card, Checkbox, Input, notification, NOTIFICATION_TYPES, Select, Table } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { PopupEditHired, TableFooter } from '_molecules';
import { Status } from '_atoms/Status';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { ACTIONS, Can, UI } from 'permission';
import { getHiredCandidates, updateHiredCandidate } from 'actions';
import { ReactComponent as EmptyImg } from 'dist/emptyStates/dashboardOpening.svg';
import { ReactComponent as Edit } from 'dist/icons/edit.svg';
import { ReactComponent as CircularProgress } from 'dist/icons/loading.svg';
import { INITIAL_VALUES, COLUMNS as useColumns, STATUSES_OPTIONS, SUCCESS_MESSAGE } from './constants';
import { dataMapper } from './utils';
import './style.scss';

export const JobHiredTab = ({ jobName }) => {
  const { id: jobId } = useParams();
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES);
  const [candidatesList, setCandidatesList] = useState([]);
  const [search, setSearch] = useState('');
  const [onlyMyCandidates, setOnlyMyCandidates] = useState(false);
  const [status, setStatus] = useState([STATUSES_OPTIONS[0]]);
  const [loading, setLoading] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(null);
  const [popupLoading, setPopupLoading] = useState(false);

  const handleChangeDate = (field, item) => {
    setTableOptions(s => ({ ...s, [field]: item }));
    if (field === 'page') {
      setTableOptions(s => ({ ...s, offset: (item - 1) * s.limit.value }));
    } else if (field === 'limit') {
      setTableOptions(s => ({ ...s, offset: 0, page: 1 }));
    }
  };

  const setSorting = sort => handleChangeDate('sorting', sort);

  const columns = useColumns(tableOptions.sorting, setSorting);

  const getHiredData = () => {
    setLoading(true);
    const filters = {
      search,
      only_my_candidates: onlyMyCandidates,
      sorting_field: tableOptions.sorting.field,
      sorting_order: tableOptions.sorting.order,
      limit: tableOptions.limit.value,
      offset: tableOptions.offset,
      status: status.map(s => s.value).join(','),
    };
    getHiredCandidates(jobId, filters)
      .then(resp => {
        setCandidatesList(dataMapper(resp.results, setOpenEditPopup));
        setTableOptions(s => ({ ...s, itemsCount: resp.count }));
      })
      .catch(() => setCandidatesList([]))
      .finally(() => setLoading(false));
  };

  const onCloseEditPopup = () => setOpenEditPopup(null);
  const onSaveEditPopup = data => {
    setPopupLoading(true);
    updateHiredCandidate(jobId, openEditPopup.uuid, data)
      .then(() => {
        setPopupLoading(false);
        setOpenEditPopup(null);
        notification({ type: NOTIFICATION_TYPES.SUCCESS, message: SUCCESS_MESSAGE });
        getHiredData();
      })
      .catch(() => {
        setPopupLoading(false);
      });
  };

  useEffect(getHiredData, [
    search,
    onlyMyCandidates,
    tableOptions.sorting,
    tableOptions.limit,
    tableOptions.offset,
    tableOptions.page,
    status,
  ]);

  return (
    <div className="job-hired">
      <div className="job-hired__filters">
        <Input type="search" placeholder="Search by name" onDebounceChange={e => setSearch(e.target.value)} />
        <Can I={ACTIONS.READ} a={UI.JOB_HIRED_ONLY_MY}>
          <Checkbox
            label="Only my candidates"
            isSelected={onlyMyCandidates}
            onChange={e => setOnlyMyCandidates(e.target.checked)}
          />
        </Can>
        <Select
          className="candidate-status"
          options={STATUSES_OPTIONS}
          mode={SELECT_TYPES.MULTIPLE}
          value={status}
          onChange={setStatus}
        />
      </div>
      {candidatesList.length ? (
        <>
          <Table
            rowKey="uuid"
            className="job-hired__table"
            cardTitle="Hired candidates"
            cardCount={candidatesList.length}
            columns={columns}
            loading={loading}
            data={candidatesList}
            limit={tableOptions.limit}
            page={tableOptions.page}
            itemsCount={tableOptions.itemsCount}
            updateParams={handleChangeDate}
          />
          <Card className="job-hired__list">
            <div className="header">
              <Typography.Title2>
                Hired candidates <span>{candidatesList.length}</span>
              </Typography.Title2>
            </div>
            <div className="content">
              {!loading ? (
                candidatesList.map(c => (
                  <div key={c.uuid} className="hired-item">
                    {c.action.show && (
                      <Can I={ACTIONS.READ} a={UI.JOB_HIRED_ACTION}>
                        <div className="hired-item__action">
                          <Button icon={<Edit />} onClick={() => c.action.action(c)} />
                        </div>
                      </Can>
                    )}
                    <div className="row">
                      <Typography.Text className="label">Candidate</Typography.Text>
                      <a href={`/candidates/${c.uuid}/show`} target="_blank" rel="noopener noreferrer">
                        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{c.candidate.fullNameLatin}</Typography.Text>
                        {c.candidate.fullNameLocal && (
                          <Typography.Caption>{c.candidate.fullNameLocal}</Typography.Caption>
                        )}
                      </a>
                    </div>
                    <div className="row">
                      <Typography.Text className="label">Team Lead</Typography.Text>
                      <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{c.team_lead}</Typography.Text>
                    </div>
                    <div className="row">
                      <Typography.Text className="label">Recruiter</Typography.Text>
                      <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{c.recruiter}</Typography.Text>
                    </div>
                    <div className="row">
                      <Typography.Text className="label">Experience level</Typography.Text>
                      <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{c.experience_level}</Typography.Text>
                    </div>
                    <div className="row">
                      <Typography.Text className="label">Location</Typography.Text>
                      <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{c.location.label}</Typography.Text>
                    </div>
                    <div className="row">
                      <Typography.Text className="label">Status</Typography.Text>
                      <Status type={c.status.type}>{c.status.status}</Status>
                    </div>
                    <div className="row">
                      <Typography.Text className="label">Entry date</Typography.Text>
                      <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{c.entry_date}</Typography.Text>
                    </div>
                    <div className="row">
                      <Typography.Text className="label">Probation period</Typography.Text>
                      <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{c.probation_period} month(s)</Typography.Text>
                    </div>
                  </div>
                ))
              ) : (
                <AtsSkeleton height={120} />
              )}
              <TableFooter
                onChangePage={d => handleChangeDate('page', d)}
                onChangeLimit={d => handleChangeDate('limit', d)}
                itemsCount={tableOptions.itemsCount}
                loading={loading}
                limit={tableOptions.limit}
                page={tableOptions.page}
                newLoading
              />
            </div>
          </Card>
        </>
      ) : (
        <Empty loading={loading} />
      )}
      <PopupEditHired
        open={openEditPopup}
        data={openEditPopup}
        jobName={jobName}
        loading={popupLoading}
        onClose={onCloseEditPopup}
        onSave={onSaveEditPopup}
      />
    </div>
  );
};

const Empty = ({ loading }) => (
  <Card className="job-hired__table_empty">
    <EmptyImg className="empty-image" />
    <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>No employees found</Typography.Title2>
    {loading && (
      <div className="job-hired__table_empty__loading">
        <CircularProgress width={40} height={40} />
      </div>
    )}
  </Card>
);
