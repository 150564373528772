import { notification } from '_atoms';
import { asyncForEach } from 'helpers/helpers';
import { uploadDocument } from 'actions';

const processFile = async attachedFile => {
  try {
    const formData = new FormData();
    formData.append('file', attachedFile);
    return await uploadDocument(formData);
  } catch {
    notification({ message: `Error while uploading file "${attachedFile.name}".` });
  }
  return null;
};

export const uploadDocs = async cvs => {
  const uploadedDocs = cvs.filter(cv => typeof cv === 'string');
  await asyncForEach(
    cvs.filter(cv => typeof cv === 'object').map(d => d.originFileObj),
    async f => {
      const { uuid } = await processFile(f);
      if (uuid) uploadedDocs.push(uuid);
    }
  );
  return uploadedDocs;
};
