import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { Card, RichText, User, AtsSkeleton } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import './style.scss';

export const JobDescriptionTab = ({
  createdAt,
  lastOpenedAt,
  lastArchivedAt,
  lastArchivedBy,
  teamLead,
  resourceManager,
  recruiters = [],
  recruiterLeads = [],
  service,
  company,
  description,
  benefits,
}) =>
  service ? (
    <Card className="job-description">
      <div className="job-description__general">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>General</Typography.Title2>
        <Row className="margin-description" gutter={{ lg: 24, md: 24, sm: 16 }}>
          <Col lg={8} md={12} sm={24}>
            <Row>
              <Col span={24} className="margin-description__item">
                <Typography.Label className="gray-label">Service</Typography.Label>
                <div>{service.name}</div>
              </Col>
              <Col span={24}>
                <Typography.Label className="gray-label">Company</Typography.Label>
                <div>{company.name}</div>
              </Col>
            </Row>
          </Col>
          <Col lg={8} md={12} sm={24}>
            <Row>
              <Col span={24} className="margin-description__item">
                <Typography.Label className="gray-label">Creation date</Typography.Label>
                <div>{moment(createdAt).format('MMM DD, YYYY [at] HH:mm')}</div>
              </Col>
              <Col span={24}>
                <Typography.Label className="gray-label">Opened since</Typography.Label>
                <div>{lastOpenedAt ? moment(lastOpenedAt).format('MMM DD, YYYY [at] HH:mm') : '-'}</div>
              </Col>
            </Row>
          </Col>
          {(lastArchivedAt || lastArchivedBy) && (
            <Col lg={8} md={12} sm={24}>
              <Row>
                {lastArchivedAt && (
                  <Col span={24} className="margin-description__item">
                    <Typography.Label className="gray-label">Last Archived at</Typography.Label>
                    <div>{moment(lastArchivedAt).format('MMM DD, YYYY [at] hh:mm')}</div>
                  </Col>
                )}
                {lastArchivedBy && (
                  <Col span={24}>
                    <Typography.Label className="gray-label">Last Archived by</Typography.Label>
                    <User fullName={lastArchivedBy.full_name} avatar={lastArchivedBy.avatar} />
                  </Col>
                )}
              </Row>
            </Col>
          )}
        </Row>
        <div className="margin-description">
          <Typography.Label className="gray-label">Description</Typography.Label>
          <RichText text={description || '-'} />
        </div>
        <div className="benefits">
          <Typography.Label className="gray-label">Benefits</Typography.Label>
          <RichText text={benefits || '-'} />
        </div>
      </div>

      <div className="job-description__team">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Team</Typography.Title2>
        <div className="margin-user">
          <Typography.Label>Team Lead</Typography.Label>
          <User fullName={teamLead.full_name} avatar={teamLead.avatar} />
        </div>
        <Row className="job-description__rm-sh margin-user" gutter={{ lg: 24, md: 24, sm: 16 }}>
          <Col lg={8} md={12} sm={24}>
            <Typography.Label>Resource Manager</Typography.Label>
            <User fullName={resourceManager?.full_name} avatar={resourceManager?.avatar} />
          </Col>
          {service.service_head && (
            <Col lg={8} md={12} sm={24}>
              <Typography.Label>Service head</Typography.Label>
              <User fullName={service.service_head.full_name} avatar={service.service_head.avatar} />
            </Col>
          )}
        </Row>
        <Typography.Label>Recruiter Lead(s)</Typography.Label>
        <Row className="job-description__recruiters margin-user" gutter={{ lg: 24, md: 24, sm: 16 }}>
          {recruiterLeads.length ? (
            recruiterLeads.map((r, index) => (
              <Col key={index} lg={8} md={12} sm={24}>
                <User fullName={r.full_name} avatar={r.avatar} />
              </Col>
            ))
          ) : (
            <Col span={24}>
              <div className="empty-recruiters">Recruiter Lead(s) aren&apos;t assigned</div>
            </Col>
          )}
        </Row>
        <Typography.Label>Recruiter(s)</Typography.Label>
        <Row className="job-description__recruiters" gutter={{ lg: 24, md: 24, sm: 16 }}>
          {recruiters.length ? (
            recruiters.map((r, index) => (
              <Col key={index} lg={8} md={12} sm={24}>
                <User fullName={r.full_name} avatar={r.avatar} />
              </Col>
            ))
          ) : (
            <Col span={24}>
              <div className="empty-recruiters">Recruiter(s) aren&apos;t assigned</div>
            </Col>
          )}
        </Row>
      </div>
    </Card>
  ) : (
    <Card className="job-description">
      <AtsSkeleton height={400} />
    </Card>
  );
