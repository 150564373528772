import React from 'react';
import { useSelector } from 'react-redux';
import { AtsSkeleton, Table } from '_atoms';
import { Typography } from '_atoms/Typography';
import { getCandidateJobsLoading } from 'store/selectors/candidateSelectors';
import { ReactComponent as EmptyImage } from 'dist/emptyStates/dashboardOpening.svg';
import { useJobAssignedData } from './utils';
import { COLUMNS } from './constants';
import './style.scss';

export const JobAssignedTabContent = ({ jobs }) => {
  const data = useJobAssignedData(jobs);
  const loading = useSelector(getCandidateJobsLoading);
  if (loading)
    return (
      <div className="job-assigned__loading">
        <AtsSkeleton count={3} height={145} />
      </div>
    );
  if (!jobs.length)
    return (
      <div className="job-assigned__empty">
        <EmptyImage />
        <Typography.Text>Candidate is not assigned to any job yet</Typography.Text>
      </div>
    );

  return (
    <div className="job-assigned">
      <Table rowKey="id" columns={COLUMNS} data={data} showCardHeader={false} showCardFooter={false} />
      <JobAssignmentList data={data} />
    </div>
  );
};

const JobAssignmentList = ({ data }) => (
  <div className="job-assigned_mobile">
    {data.map(d => (
      <div className="assignment-item">
        {COLUMNS.map(c => (
          <div className="assignment-item__row">
            <Typography.Text className="title">{c.title}:</Typography.Text>
            <div className="content">{c.render?.(d[c.key]) || d[c.key]}</div>
          </div>
        ))}
      </div>
    ))}
  </div>
);
