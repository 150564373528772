import moment from 'moment';
import { dateFormatter } from 'helpers/dateFormatter';
import { STEPS_LABELS, stepsStatuses } from 'constants/constants';
import { BUTTON_LABELS, CANDIDATES_GROUP } from './constants';

const getAction = c =>
  [stepsStatuses.provideFeedback].includes(c.assignment?.status) && c.is_action_required
    ? {
        text: '',
        subtext: '',
        time: '',
        link: '',
        button: {
          label: BUTTON_LABELS[c.assignment.status],
          link: `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`,
        },
      }
    : stepsStatuses.waitForResolution === c.assignment?.status
    ? c.assignment.step_type === 'Job started'
      ? {
          text: 'Entry date:',
          subtext: moment(c.start_date, 'YYYY-MM-DD').format('MMM DD, YYYY'),
          time: '',
          link: '',
          button: '',
        }
      : {
          text: '',
          subtext: '',
          time: '',
          link: '',
          button: c.is_action_required
            ? {
                label: BUTTON_LABELS[c.assignment.status],
                link: `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`,
              }
            : '',
        }
    : c.expected_answer_date
    ? {
        text: 'Expected answer date:',
        subtext: moment(c.expected_answer_date).format('MMM DD, YYYY'),
        time: '',
        link: '',
        button: '',
      }
    : c.interview && stepsStatuses.waitingForFinalDecision !== c.assignment?.status
    ? {
        text: moment(c.interview.date).format('ddd MMM DD, YYYY'),
        subtext: `${dateFormatter(`${c.interview.date} ${c.interview.from_time}`, 'H:mm')} - ${dateFormatter(
          `${c.interview.date} ${c.interview.to_time}`,
          'H:mm'
        )}`,
        time: '',
        link: c.interview.link,
        button: '',
      }
    : c.reminder
    ? {
        text: `Reminder: ${c.reminder.type}`,
        subtext: '',
        time: moment(c.reminder.datetime).format('ddd MMM DD, YYYY [at] H:mm'),
        link: '',
        button: '',
      }
    : {
        text: '',
        subtext: '',
        time: '',
        link: '',
        button: '',
      };

export const candidatesTableDataMapper = candidates =>
  candidates.map((c, index) => ({
    uuid: c.uuid + index,
    candidate_name: {
      url: c.assignment
        ? `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`
        : `/candidates/${c.uuid}/show`,
      full_name_latin: c.full_name_latin,
      full_name_local: c.full_name_local,
      hot: false,
    },
    level: c.level,
    job: {
      job_name: c.assignment?.job_name,
      service: c.assignment?.service,
    },
    status_step: {
      status: c.assignment?.status,
      step:
        c.assignment &&
        `${Object.values(STEPS_LABELS).findIndex(l => l === c.assignment.step_type) + 1} - ${c.assignment.step_type}`,
    },
    action: getAction(c),
  }));

export const candidatesActionTableDataMapper = actions =>
  actions.map((a, index) => ({
    uuid: index,
    name: a.group_name,
    count: a.candidates_count,
    candidates: a.candidates.map(c => ({
      uuid: c.uuid,
      candidate_name: {
        url: c.assignment
          ? `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`
          : `/candidates/${c.uuid}/show`,
        full_name_latin: c.full_name_latin,
        full_name_local: c.full_name_local,
        hot: false,
      },
      level: c.level,
      job: {
        job_name: c.assignment?.job_name,
        service: c.assignment?.service,
      },
      status_step: {
        status: c.assignment?.status,
        step:
          c.assignment &&
          `${Object.values(STEPS_LABELS).findIndex(l => l === c.assignment.step_type) + 1} - ${c.assignment.step_type}`,
      },
      action: getAction(c),
    })),
  }));

export const candidatesJobTableDataMapper = actions =>
  actions.map((a, index) => ({
    uuid: index,
    name: a.group_name || 'Job not assigned',
    extra: a.service_name,
    count: a.candidates_count,
    candidates: a.candidates.map(c => ({
      uuid: c.uuid,
      candidate_name: {
        url: c.assignment
          ? `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`
          : `/candidates/${c.uuid}/show`,
        full_name_latin: c.full_name_latin,
        full_name_local: c.full_name_local,
        hot: false,
      },
      level: c.level,
      job: {
        job_name: c.assignment?.job_name,
        service: c.assignment?.service,
      },
      status_step: {
        status: c.assignment?.status,
        step:
          c.assignment &&
          `${Object.values(STEPS_LABELS).findIndex(l => l === c.assignment.step_type) + 1} - ${c.assignment.step_type}`,
      },
      action: getAction(c),
    })),
  }));

export const candidatesStepTableDataMapper = actions =>
  actions.map((a, index) => ({
    uuid: index,
    name: a.group_name || 'No step',
    count: a.candidates_count,
    candidates: a.candidates.map(c => ({
      uuid: c.uuid,
      candidate_name: {
        url: c.assignment
          ? `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`
          : `/candidates/${c.uuid}/show`,
        full_name_latin: c.full_name_latin,
        full_name_local: c.full_name_local,
        hot: false,
      },
      level: c.level,
      job: {
        job_name: c.assignment?.job_name,
        service: c.assignment?.service,
      },
      status_step: {
        status: c.assignment?.status,
        step:
          c.assignment &&
          `${Object.values(STEPS_LABELS).findIndex(l => l === c.assignment.step_type) + 1} - ${c.assignment.step_type}`,
      },
      action: getAction(c),
    })),
  }));

export const CANDIDATES_TABLES_MAPPERS = {
  [CANDIDATES_GROUP[0].value]: candidatesTableDataMapper,
  [CANDIDATES_GROUP[1].value]: candidatesActionTableDataMapper,
  [CANDIDATES_GROUP[2].value]: candidatesJobTableDataMapper,
  [CANDIDATES_GROUP[3].value]: candidatesStepTableDataMapper,
};
