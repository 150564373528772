import React from 'react';
import { Button, BUTTON_TYPES } from '_atoms';
import { BUTTON_TEXT } from 'constants/text';
import './footerButton.scss';

export const FooterButton = ({ onReset, onApply }) => (
  <div className="button-footer">
    <Button htmlType="reset" onClick={onReset}>
      {BUTTON_TEXT.RESET_FILTERS}
    </Button>
    <Button type={BUTTON_TYPES.PRIMARY} htmlType="submit" onClick={onApply}>
      {BUTTON_TEXT.APPLY}
    </Button>
  </div>
);
