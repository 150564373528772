import React from 'react';
import { Tooltip as TooltipAntd } from 'antd';
import cn from 'classnames';
import './tooltip.scss';

export const Tooltip = ({ children, className, placement, label, white }) => (
  <TooltipAntd
    overlayClassName={cn('tooltip-default', { white }, className)}
    overlay={label}
    placement={placement || 'topLeft'}
    destroyTooltipOnHide
  >
    {children}
  </TooltipAntd>
);
