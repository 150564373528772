import { POPUP_BODY, POPUP_LABELS } from 'constants/text';
import { LOGIN_TYPES } from './constants';

export const useLoginState = condition => {
  switch (condition) {
    case LOGIN_TYPES.WELCOME:
      return [POPUP_LABELS.LOGIN_WELCOME, POPUP_BODY.LOGIN_WELCOME];
    case LOGIN_TYPES.ERROR_EMAIL:
      return [POPUP_LABELS.LOGIN_EMAIL_ERROR, POPUP_BODY.LOGIN_EMAIL_ERROR];
    case LOGIN_TYPES.APP_CRASH:
      return [POPUP_LABELS.LOGIN_EMAIL_ERROR, POPUP_BODY.CRITICAL_ERROR];
    case LOGIN_TYPES.ERROR:
    default:
      return [POPUP_LABELS.LOGIN_ERROR, POPUP_BODY.LOGIN_ERROR];
  }
};
