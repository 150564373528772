import React from 'react';
import { Button, BUTTON_TYPES, Tooltip } from '_atoms';
import { Status } from '_atoms/Status';
import { Typography } from '_atoms/Typography';
import { STATUSES } from 'constants/user';
import { ACTIONS, Can, UI, useAbility } from 'permission';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import { ReactComponent as Edit } from 'dist/icons/edit.svg';

const headerCellRender = (title, field, key, sort, setSorting) => () => {
  const order = sort.key === key ? sort.order : null;
  const onClick = () => setSorting({ field, key, order: order === 'asc' ? 'desc' : 'asc' });
  return (
    <>
      {title}
      <Sort className={order} onClick={onClick} />
    </>
  );
};

export const SUCCESS_MESSAGE = 'Candidate’s hiring information has been updated.';

export const STATUSES_OPTIONS = [
  { value: STATUSES.HIRED, label: STATUSES.HIRED },
  { value: STATUSES.FIRED, label: STATUSES.FIRED },
];

export const INITIAL_VALUES = {
  sorting: { key: 'entry_date', order: 'desc', field: 'entry_date' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};

const COLUMNS_WIDTH = {
  candidate: 190,
  team_lead: 190,
  recruiter: 190,
  experience_level: 120,
  location: 120,
  status: 96,
  entry_date: 116,
  probation_period: 120,
};

const COLUMNS_WIDTH_ACTION = {
  candidate: 168,
  team_lead: 168,
  recruiter: 168,
  experience_level: 120,
  location: 120,
  status: 96,
  entry_date: 116,
  probation_period: 120,
};

const ACTION_COLUMN = {
  title: 'Action',
  dataIndex: 'action',
  rowKey: 'action',
  className: 'job-hired__table-cell',
  render: ({ action, show }, record) =>
    show && (
      <Can I={ACTIONS.READ} a={UI.JOB_HIRED_ACTION}>
        <Button type={BUTTON_TYPES.GHOST} icon={<Edit />} onClick={() => action(record)} />
      </Can>
    ),
};

export const COLUMNS = (sort, setSorting) => {
  const ability = useAbility();
  const action = ability.can(ACTIONS.READ, UI.JOB_HIRED_ACTION);
  const widths = action ? COLUMNS_WIDTH_ACTION : COLUMNS_WIDTH;

  const columns = [
    {
      title: headerCellRender('Candidate', 'candidate', 'candidate', sort, setSorting),
      sorter: true,
      dataIndex: 'candidate',
      rowKey: 'candidate',
      width: widths.candidate,
      ellipsis: true,
      className: 'job-hired__table-cell',
      render: data => (
        <Tooltip label={data.fullNameLatin}>
          <a
            className="job-hired__table-cell__link"
            href={`/candidates/${data.uuid}/show`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography.Text>{data.fullNameLatin}</Typography.Text>
            <Typography.Text className="subtitle">{data.fullNameLocal}</Typography.Text>
          </a>
        </Tooltip>
      ),
    },
    {
      title: headerCellRender('Team Lead', 'team_lead', 'team_lead', sort, setSorting),
      sorter: true,
      dataIndex: 'team_lead',
      rowKey: 'team_lead',
      width: widths.team_lead,
      className: 'job-hired__table-cell',
      render: team_lead => <Tooltip label={team_lead}>{team_lead}</Tooltip>,
    },
    {
      title: headerCellRender('Recruiter', 'recruiter', 'recruiter', sort, setSorting),
      sorter: true,
      dataIndex: 'recruiter',
      rowKey: 'recruiter',
      width: widths.recruiter,
      className: 'job-hired__table-cell',
      render: recruiter => <Tooltip label={recruiter}>{recruiter}</Tooltip>,
    },
    {
      title: headerCellRender('Experience level', 'experience_level', 'experience_level', sort, setSorting),
      sorter: true,
      dataIndex: 'experience_level',
      rowKey: 'experience_level',
      width: widths.experience_level,
      className: 'job-hired__table-cell',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      rowKey: 'location',
      width: widths.location,
      ellipsis: true,
      className: 'job-hired__table-cell',
      render: data => data.label,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      rowKey: 'status',
      width: widths.status,
      className: 'job-hired__table-cell',
      render: ({ status, type }) => <Status type={type}>{status}</Status>,
    },
    {
      title: headerCellRender('Entry date', 'entry_date', 'entry_date', sort, setSorting),
      sorter: true,
      dataIndex: 'entry_date',
      rowKey: 'entry_date',
      width: widths.entry_date,
      className: 'job-hired__table-cell',
    },
    {
      title: headerCellRender('Probation period', 'probation_period', 'probation_period', sort, setSorting),
      sorter: true,
      dataIndex: 'probation_period',
      rowKey: 'probation_period',
      width: widths.probation_period,
      className: 'job-hired__table-cell',
      render: data => <Typography.Text>{data} month(s)</Typography.Text>,
    },
  ];
  return React.useMemo(() => (action ? [...columns, ACTION_COLUMN] : columns), [action, columns]);
};
