import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { Tabs, DropDownMenu } from '_atoms';
import { ACTIONS, UI, useAbility } from 'permission';
import history from 'helpers/history';
import { changeRole, signOut } from 'actions';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ROUTES } from 'router/constants';
import { HEADER_TABS } from 'constants/constants';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as Arrow } from 'dist/icons/caret-down.svg';
import { ReactComponent as Logo } from 'dist/icons/logo.svg';
import { ReactComponent as CheckMark } from 'dist/icons/checkmark.svg';
import { ReactComponent as Person } from 'dist/icons/person.svg';
import { ReactComponent as Logout } from 'dist/icons/logout.svg';
import './header.scss';

export const Header = () => {
  const ability = useAbility();
  const { path } = useRouteMatch();
  const user = useSelector(getUser);
  const isAnalyticsAble = user && ability.can(ACTIONS.READ, UI.ANALYTICS_PAGE);

  const tabs = useMemo(() => {
    const options = Object.values(HEADER_TABS).map(name => ({ key: name.toLowerCase(), label: name }));
    if (!isAnalyticsAble) return options.filter(({ label }) => label !== HEADER_TABS.ANALYTICS);
    return options;
  }, [isAnalyticsAble]);

  const setActiveTab = tab => history.push(`/${tab}`);
  const activeRole = user?.active_role;
  const roles = user?.roles || [];

  const onChangeRole = active_role => () => {
    if (active_role !== activeRole.pk) {
      changeRole(user.uuid, { active_role });
    }
  };

  return (
    <div className="header-block">
      <div className="header-block__content">
        <Link to={ROUTES.DASHBOARD} className="header-block__logo">
          <Logo />
        </Link>
        <Tabs activeTab={path.slice(1)} onTabClick={setActiveTab} items={tabs} className="header-block__tabs" />
        <div className="header-block__logout">
          {roles.length > 1 && (
            <div className="change-role">
              <DropDownMenu
                className="change-role__menu"
                triggerElement={
                  <div className="change-role__trigger">
                    <Typography.Text className="change-role__trigger-text" weight={TYPOGRAPHY_WEIGHT.BOLD}>
                      {activeRole.name}
                    </Typography.Text>
                    <Arrow className="change-role__trigger-icon" />
                    <Person className="change-role__trigger-icon-mobile" />
                  </div>
                }
              >
                {roles.map(role => {
                  const companyAndService =
                    role.company && role.service
                      ? `${role.company.name} - ${role.service.name}`
                      : role.service
                      ? role.service.name
                      : role.company?.name;
                  return (
                    <li key={role.pk} role="none" onClick={onChangeRole(role.pk)}>
                      <div className="role-name">{role.name}</div>
                      <div className="role-info">{companyAndService}</div>
                      {role.pk === activeRole.pk && <CheckMark className="role-checkmark" />}
                    </li>
                  );
                })}
              </DropDownMenu>
            </div>
          )}
          <button type="button" className="logout" onClick={signOut}>
            Log out
          </button>
          <Logout className="logout-mobile" onClick={signOut} />
        </div>
      </div>
    </div>
  );
};
