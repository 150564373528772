import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  filter: {},
  jobData: {},
  openingsList: [],
  openingCandidate: null,
  steps: [],
  rooms: [],
  candidates: {},
  isCandidateExistsOnJob: false,
  loader: {},
  candidateDataLoading: false,
  approversCandidate: [],
  approversCandidateLoading: false,
  cvs: [],
  userData: {},
  scorecard: [],
  interview: {},
  interview_loading: false,
  offeringCandidate: {},
  offeringCandidateLoading: false,
  offerRecommendation: null,
  probationPeriod: {},
  entryDateLoading: false,
  activityList: [],
  activityListLoading: false,
  cvsLinks: [],
  timelineCommentLoading: false,
  timelineLoading: false,
};

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    changeJobFilter(state, { payload }) {
      state.filter = payload;
    },
    setJobInfo(state, { payload }) {
      state.jobData = payload;
    },
    setOpeningsList(state, { payload }) {
      state.openingsList = payload;
    },
    setStepsToJob(state, { payload }) {
      state.steps = payload;
    },
    setCandidatesJob(state, { payload }) {
      const filteredCandidates = state.candidates[payload.status]?.list || [];
      const list = payload.clear ? payload.results : [...filteredCandidates, ...payload.results];
      state.candidates[payload.status] = {
        list,
        hasMore: !!payload.next,
        count: payload.count,
      };
    },
    clearCandidatesJob(state) {
      state.candidates = {};
    },
    setCandidatesLoader(state, { payload }) {
      state.loader[payload.status] = payload.value;
    },
    setLoadingCandidateData(state, { payload }) {
      state.candidateDataLoading = payload;
    },
    setApproversCandidate(state, { payload }) {
      state.approversCandidate = payload;
    },
    setApproversCandidateLoading(state, { payload }) {
      state.approversCandidateLoading = payload;
    },
    setCVSCandidate(state, { payload }) {
      state.cvs = payload;
    },
    setCVSLinks(state, { payload }) {
      state.cvsLinks = payload;
    },
    setUserData(state, { payload }) {
      state.userData = payload;
    },
    setScorecardCandidate(state, { payload }) {
      state.scorecard = payload;
    },
    setInterview(state, { payload }) {
      state.interview = payload;
    },
    setInterviewLoading(state, { payload }) {
      state.interview_loading = payload;
    },
    setOfferingCandidate(state, { payload }) {
      state.offeringCandidate = payload;
    },
    setOfferingCandidateLoading(state, { payload }) {
      state.offeringCandidateLoading = payload;
    },
    setOfferRecommendation(state, { payload }) {
      state.offerRecommendation = payload;
    },
    setProbationPeriodData(state, { payload }) {
      state.probationPeriod = payload;
    },
    setEntryDateLoading(state, { payload }) {
      state.entryDateLoading = payload;
    },
    setOpeningCandidate(state, { payload }) {
      state.openingCandidate = payload;
    },
    setActivityList(state, { payload }) {
      state.activityList = payload;
    },
    setActivityListLoading(state, { payload }) {
      state.activityListLoading = payload;
    },
    setTimelineCommentLoading(state, { payload }) {
      state.timelineCommentLoading = payload;
    },
    setTimelineLoading(state, { payload }) {
      state.timelineLoading = payload;
    },
    setRoomsOptions(state, { payload }) {
      state.rooms = payload;
    },
    setCandidateExists(state, { payload }) {
      state.isCandidateExistsOnJob = payload;
    },
    resetJobReducer(state) {
      state = initialState;
    },
  },
});

export const {
  changeJobFilter,
  setJobInfo,
  setOpeningsList,
  setStepsToJob,
  setCandidatesJob,
  clearCandidatesJob,
  setCandidatesLoader,
  setLoadingCandidateData,
  setApproversCandidate,
  setApproversCandidateLoading,
  setCVSCandidate,
  setCVSLinks,
  setScorecardCandidate,
  setInterview,
  setInterviewLoading,
  setOfferingCandidate,
  setOfferingCandidateLoading,
  setOfferRecommendation,
  setProbationPeriodData,
  setEntryDateLoading,
  setOpeningCandidate,
  setActivityList,
  setActivityListLoading,
  setTimelineCommentLoading,
  setTimelineLoading,
  setRoomsOptions,
  setCandidateExists,
  resetJobReducer,
  setUserData,
} = jobSlice.actions;
export default jobSlice.reducer;
