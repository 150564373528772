import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Form, Row } from 'antd';
import { DatePicker, Select, TextArea, Popup } from '_atoms';
import { REQUIRED_RULE } from '_organisms/InterviewFeedback/constants';
import { getUser } from 'store/selectors/authorizeSelectors';
import * as actions from 'actions';
import { ROLES } from 'permission';
import { possibleTime } from 'constants/constants';
import { BUTTON_TEXT } from 'constants/text';

const reminderType = [
  { value: 'Phone call', label: 'Phone call' },
  { value: 'Skype', label: 'Skype' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Email', label: 'Email' },
  { value: 'Telegram', label: 'Telegram' },
  { value: 'Viber', label: 'Viber' },
  { value: 'Interview', label: 'Interview' },
  { value: 'Other interaction', label: 'Other interaction' },
];

export const PopupCreateInteraction = ({ open, onClose, data }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  const user = useSelector(getUser);

  const [recruiterOptions, setRecruiterOptions] = useState([]);

  const initialValues = useMemo(
    () => ({
      date: moment().set({ hour: 13, minute: 0, second: 0 }),
      time: possibleTime[26].value,
      recruiter: user.uuid,
      comment: '',
      type: undefined,
    }),
    [user]
  );

  const onSave = ({ date, time, ...values }) => {
    const hour = time.split(':')[0];
    const minute = time.split(':')[1];
    dispatch(
      actions.addInteractionAPI(
        {
          ...values,
          datetime: date.set({ hour, minute, second: 0 }),
          candidate: id,
          in_progress: true,
        },
        data?.uuid,
        id
      )
    ).then(onClose);
  };

  useEffect(() => {
    if (open) {
      actions
        .getListUsersByRole({ role: [ROLES.HR_LEAD, ROLES.RECRUITER_LEAD, ROLES.RECRUITER] })
        .then(resp => setRecruiterOptions(resp.map(r => ({ label: r.full_name, value: r.uuid }))))
        .catch(() => setRecruiterOptions([]));
    } else {
      setRecruiterOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (data) form.setFieldsValue(data);
    else form.resetFields();
  }, [data]);

  return (
    <Popup
      open={open}
      title={data?.uuid ? 'Edit interaction' : 'New interaction'}
      cancelButtonProps={{ onClick: onClose, children: BUTTON_TEXT.CANCEL }}
      okButtonProps={{
        children: data?.uuid ? BUTTON_TEXT.SAVE : BUTTON_TEXT.CREATE,
        form: 'form-interaction',
        htmlType: 'submit',
      }}
      onClick={onClose}
    >
      <Form name="form-interaction" form={form} initialValues={initialValues} onFinish={onSave}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <DatePicker
              label="Date"
              placeholder="Expected date"
              maxDate={moment().endOf('day')}
              itemProps={{ name: 'date', rules: [REQUIRED_RULE] }}
            />
          </Col>
          <Col span={12}>
            <Select
              label="Time"
              placeholder="Expected time"
              options={possibleTime}
              itemProps={{ name: 'time', rules: [REQUIRED_RULE] }}
            />
          </Col>
        </Row>
        <Select
          label="Communication type"
          options={reminderType}
          itemProps={{ name: 'type', rules: [REQUIRED_RULE] }}
        />
        <Select
          label={ROLES.RECRUITER}
          placeholder="Please select Recruiter"
          options={recruiterOptions}
          itemProps={{ name: 'recruiter', rules: [REQUIRED_RULE] }}
          optionFilterProp="label"
        />
        <TextArea label="Comment" placeholder="Add a comment" itemProps={{ name: 'comment' }} />
      </Form>
    </Popup>
  );
};
