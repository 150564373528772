import React from 'react';
import { Col, Form, Row } from 'antd';
import { Button, BUTTON_TYPES, Input } from '_atoms';
import { ReactComponent as Union } from 'dist/icons/Union.svg';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as Bin } from 'dist/icons/bin.svg';
import './style.scss';

export const FormListSingle = ({ label, listName, buttonText, maxCount = 5, rules, ...props }) => (
  <Form.List name={listName}>
    {(fields, { add, remove }) => (
      <div className="form-list">
        <Typography.Label weight={TYPOGRAPHY_WEIGHT.BOLD}>{label}</Typography.Label>
        {fields.map((field, index) => (
          <Row key={field.key} gutter={[20, 0]} className="form-list__item">
            <Col flex={1} className="form-list__item__first">
              <Input {...props} itemProps={{ ...field, rules }} />
            </Col>
            {!!index && (
              <Col flex="0 0 36px" className="form-list__item__second">
                <Button
                  type={BUTTON_TYPES.GHOST}
                  className="form-list__item__delete"
                  onClick={() => remove(field.name)}
                  icon={<Bin />}
                />
              </Col>
            )}
          </Row>
        ))}
        {fields.length < maxCount && (
          <Button icon={<Union />} onClick={() => add()}>
            {buttonText}
          </Button>
        )}
      </div>
    )}
  </Form.List>
);
