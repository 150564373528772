import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Row, Select as SelectAntd } from 'antd';
import moment from 'moment';
import { DatePicker, Input, Popup, RichArea, Select } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { dateFormatter } from 'helpers/dateFormatter';
import { REQUIRED_RULE } from '_organisms/InterviewFeedback/constants';
import { RULES } from '_organisms/Offer/constants';
import { getOpeningsList } from 'actions';
import './style.scss';

const openingsMapper = openings =>
  openings.map(o => ({
    value: o.uuid,
    label: `${o.level} - ${o.team_lead.full_name}`,
    offices: o.offices.map(office => ({ value: office.uuid, label: office.name })),
    expected: o.expected,
  }));

export const PopupEditHired = ({ open, data, jobName, loading, onClose, onSave }) => {
  const { id: jobId } = useParams();
  const [form] = Form.useForm();
  const [openings, setOpenings] = useState([]);

  useEffect(() => {
    if (open) {
      const opening = {
        current: true,
        value: data.opening.uuid,
        label: `${data.opening.level} - ${data.team_lead}`,
        offices: data.opening.offices,
        expected: data.opening.expected,
      };
      form.setFieldsValue({
        opening,
        location: data.location,
        entry_date: moment(data.entry_date, 'DD.MM.YYYY'),
        probation_period: data.probation_period,
        comment: '',
      });
      getOpeningsList(jobId, { status: 'Open' })
        .then(ops => setOpenings([opening, ...openingsMapper(ops).filter(o => o.value !== opening.value)]))
        .catch(() => setOpenings([]));
    } else {
      form.setFieldsValue({
        opening: undefined,
        location: undefined,
        entry_date: undefined,
        probation_period: '',
        comment: '',
      });
      setOpenings([]);
    }
  }, [open]);

  const onSaveForm = ({ opening, location, entry_date, probation_period, comment }) => {
    onSave({
      opening: opening.value,
      location: location.value,
      entry_date: entry_date.format('YYYY-MM-DD'),
      probation_period: +probation_period,
      comment,
    });
  };

  if (!data) return null;
  return (
    <Popup
      open={open}
      loading={loading}
      className="popup-edit-hired mobile-full-size"
      title="Edit hiring information"
      cancelButtonProps={{
        children: 'cancel',
        onClick: onClose,
      }}
      okButtonProps={{
        children: 'save',
        form: 'form-edit-hired',
        htmlType: 'submit',
      }}
    >
      <div className="candidate-info">
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.candidate.fullNameLatin}</Typography.Text>
        <Typography.Label weight={TYPOGRAPHY_WEIGHT.NORMAL}>{data.candidate.fullNameLocal}</Typography.Label>
        <Typography.Label weight={TYPOGRAPHY_WEIGHT.NORMAL}>{jobName}</Typography.Label>
      </div>
      <Form id="form-edit-hired" form={form} onFinish={onSaveForm} validateTrigger="onSubmit">
        <Select
          labelInValue
          label="Opening"
          itemProps={{ name: 'opening', rules: [REQUIRED_RULE] }}
          onChange={opening => {
            const location = opening.offices.length === 1 ? opening.offices[0] : undefined;
            form.setFieldsValue({ opening, location });
          }}
        >
          {openings.map(opening => (
            <SelectAntd.Option key={opening.uuid} value={opening.value} label={opening.label} offices={opening.offices}>
              <div className="opening-select-option">
                <Typography.Text>
                  {opening.label}
                  {opening.current && <span> (Current)</span>}
                </Typography.Text>
                <Typography.Caption>{`Expected date: ${dateFormatter(opening.expected)}`}</Typography.Caption>
                <Typography.Caption>{opening.offices.map(o => o.label).join(', ')}</Typography.Caption>
              </div>
            </SelectAntd.Option>
          ))}
        </Select>
        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.opening !== curr.opening}>
          {({ getFieldValue }) => (
            <Select
              labelInValue
              label="Location"
              options={getFieldValue('opening')?.offices}
              disabled={getFieldValue('opening')?.offices.length === 1}
              itemProps={{ name: 'location', rules: [REQUIRED_RULE] }}
            />
          )}
        </Form.Item>
        <Row gutter={12}>
          <Col span={12}>
            <DatePicker label="Entry date" itemProps={{ name: 'entry_date', rules: [REQUIRED_RULE] }} />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Input
              label="Probation period"
              placeholder="Number of months"
              itemProps={{ name: 'probation_period', rules: RULES.PERIOD }}
            />
          </Col>
          <Col span={12}>
            <Typography.Text className="months">month(s)</Typography.Text>
          </Col>
        </Row>
        <Form.Item noStyle name="comment">
          <RichArea label="Comment" placeholder="Add a comment" />
        </Form.Item>
      </Form>
    </Popup>
  );
};
