import { levelOptions, levels } from 'constants/constants';

export const DECISION_OPTIONS = [
  { value: true, label: 'approve' },
  { value: false, label: 'reject' },
];

export const ENG_LEVEL_OPTIONS = [
  { value: 'n/a', label: 'n/a' },
  ...levelOptions.map(value => ({ value, label: value })),
];

export const LEVEL_OPTIONS = [{ value: 'n/a', label: 'n/a' }, ...levels.map(value => ({ value, label: value }))];

export const REQUIRED_RULE = { required: true, message: 'This field is required' };
