import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms';

let elements = {};
export const ScrollPanel = ({ options }) => {
  const [tabs, setTabs] = useState(options);
  const [activeTab, setTab] = useState(tabs[0].id);

  const getCoords = elem => {
    const box = elem.getBoundingClientRect();
    return box.top + window.pageYOffset;
  };

  const changeTab = tab => {
    const top = elements[tab].getBoundingClientRect().top + window.pageYOffset - 205;
    window.scrollTo({ top, behavior: 'smooth' });
  };

  useEffect(() => {
    elements = {};
    const newTabs = [];
    options.forEach(item => {
      const elem = document.getElementById(item.id);
      if (elem) {
        elements[item.id] = elem;
        newTabs.push(item);
      }
    });

    setTabs(newTabs);
    setTab(newTabs[0].id);
  }, []);
  const onScroll = () => {
    const coordinats = [];
    options.forEach(item => {
      if (elements[item.id]) coordinats.push({ top: getCoords(elements[item.id]), id: item.id });
    });
    const newActiveTab = coordinats.filter(i => i.top - 20 < window.scrollY + 205);
    if (newActiveTab.length) setTab(newActiveTab[newActiveTab.length - 1].id);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <div className="form-body__tabs-block">
      {options.map((item, i) => (
        <div
          key={`${i + 1}`}
          role="none"
          className={cn('form-body__tabs-block_item', { active: activeTab === item.id })}
          onClick={() => changeTab(item.id)}
        >
          <Typography.Title4 weight={TYPOGRAPHY_WEIGHT.BOLD}>{item.title}</Typography.Title4>
        </div>
      ))}
    </div>
  );
};
