import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { NotFound } from '_templates';
import { setPermissionAssigned } from 'store/reducers/authorizeReducer';
import { getPermissionAssigned, getUser } from 'store/selectors/authorizeSelectors';
import { getUserData } from 'actions';
import { checkToken } from 'helpers/helpers';
import { updateAbility, useAbility } from 'permission';
import { ROUTES } from 'router/constants';
import { GlobalLoading } from './GlobalLoading';

export const AuthCheck = ({ children }) => {
  const dispatch = useDispatch();
  const ability = useAbility();
  const { state } = useLocation();
  const user = useSelector(getUser);
  const isLogged = !!window.localStorage.getItem('access_token') && checkToken();
  const permissionsProvided = useSelector(getPermissionAssigned);

  useEffect(() => {
    if (user) {
      updateAbility(ability, user);
      dispatch(setPermissionAssigned(true));
    }
  }, [ability, user]);

  useEffect(() => {
    if (isLogged) dispatch(getUserData());
    else dispatch(setPermissionAssigned(true));
  }, []);

  if (!isLogged && location.pathname !== ROUTES.LOGIN) {
    window.localStorage.setItem('link_to_page', window.location.pathname);
    return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: '' } }} />;
  }

  return state?.is404 ? <NotFound /> : permissionsProvided ? children : <GlobalLoading />;
};
