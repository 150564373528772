import React from 'react';
import { Popup } from '_atoms';
import { BUTTON_TEXT } from 'constants/text';
import { TEXT } from './constants';
import './styles.scss';

export const PopupAssignToMe = ({ open, onOk, onClose, candidateName }) => (
  <Popup
    className="popup-reassign-recruiter-recruiter"
    open={open}
    title={TEXT.TITLE}
    cancelButtonProps={{ onClick: onClose, children: BUTTON_TEXT.CANCEL }}
    okButtonProps={{ onClick: onOk, children: BUTTON_TEXT.REASSIGN }}
  >
    {TEXT.SUBTITLE(candidateName)}
  </Popup>
);
