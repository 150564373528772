import { JOB_STATUSES } from 'constants/job';
import { STATUS_TYPES } from '_atoms/Status';

export const getJobStatusType = status => {
  switch (status) {
    case JOB_STATUSES.OPEN:
      return STATUS_TYPES.GREEN;
    case JOB_STATUSES.DRAFT:
      return STATUS_TYPES.GRAY;
    case JOB_STATUSES.ARCHIVED:
      return STATUS_TYPES.RED;
    default:
      return STATUS_TYPES.BLUE;
  }
};
