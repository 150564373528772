export const getLoading = state => state.common.loading;
export const getCandidateLoading = state => state.common.candidate_loading;
export const getStepsLoading = state => state.common.steps_loading;
export const getOpeningsLoading = state => state.common.openings_loading;
export const getHrManagers = state => state.common.hr_managers;
export const getRecruiterLeads = state => state.common.recruiterLeads;
export const getListHRL = state => state.common.list_hrl;
export const getServices = state => state.common.services;
export const getLocations = state => state.common.locations;
export const getLanguages = state => state.common.languages;
export const getLevels = state => state.common.levels;
export const getPositions = state => state.common.positions;
export const getCompanies = state => state.common.companies;
