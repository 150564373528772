import fetch from 'isomorphic-fetch';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { notification } from '_atoms';
import { ROUTES } from 'router/constants';
import history from './history';

export const API_HOST = process.env.REACT_APP_API_HOST;

export const setQuery = props =>
  props
    ? `?${Object.keys(props)
        .map(key => `${key}=${props[key]}`)
        .join('&')}`
    : '';
export const checkToken = () => {
  const data = jwtDecode(window.localStorage.getItem('access_token'));
  return data.exp > moment().unix();
};

export function fetchWrapper(
  { url, method = 'GET', query = '', body = null, headers = {}, version = 'v1' },
  notStringify = false
) {
  const requestParams = {
    method,
    headers,
  };
  if (window.localStorage.getItem('access_token')) {
    if (!checkToken()) {
      history.push(ROUTES.LOGIN);
    }
    headers.Authorization = `JWT ${window.localStorage.getItem('access_token')}`;
  }
  if (method !== 'GET' && body) {
    if (notStringify) {
      requestParams.body = body;
    } else {
      requestParams.body = JSON.stringify(body);
      headers['Content-Type'] = 'application/json';
    }
  }
  if (method === 'GET' && !query) {
    let str = '';
    for (const key in body) {
      if (body[key]) {
        str += str ? '&' : '?';
        str += `${key}=${body[key]}`;
      }
    }
    query = str;
  }
  return fetch(`${API_HOST}/api/${version}/${url}${query}`, requestParams).then(resp => {
    if (resp.status >= 200 && resp.status < 400) {
      if (resp.status === 204) return;
      if (resp.headers.get('Content-Type') === 'application/json') {
        return resp.json().catch(err => {});
      }
      return resp.blob().catch(err => {});
    }
    if ((resp.status === 403 || resp.status === 401) && !window.localStorage.getItem('link_to_page')) {
      window.localStorage.setItem('link_to_page', window.location.pathname);
    }
    if (resp.status === 401) {
      window.localStorage.removeItem('access_token');
      history.push(ROUTES.LOGIN);
    } else if (resp.status === 500) {
      notification({ message: resp.statusText || '500 error. Please contact your Administrator' });
    } else if (resp.status === 400) {
      if (resp.json) {
        resp.json().then(data => {
          let error = '';
          Object.keys(data).forEach(v => {
            const item = data[v];
            if (typeof item === 'string') {
              error += item;
            } else if (item.length) {
              error += item.join(', ');
            } else {
              error += JSON.stringify(item);
            }
          });
          notification({ message: error });
        });
      } else {
        notification({ message: 'Something went wrong.Please check your data' });
      }
    } else if (resp.status === 403) {
      notification({ message: 'You do not have permission to perform this action.' });
    } else {
      const isJSON = !!resp.json;
      if (isJSON) {
        try {
          return resp
            .json()
            .then(error => Promise.reject(error))
            .catch(error => Promise.reject(error));
        } catch (e) {
          return resp;
        }
      } else return resp;
    }
    throw resp.statusText;
  });
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
}

export function dataURItoBlob(dataURI) {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export const snakeCase = string =>
  string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
