import React from 'react';
import { Link } from 'react-router-dom';
import { Button, BUTTON_TYPES, Tooltip } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { getJobStatusColor } from '_templates/Candidates/utils';
import { stepsStatuses } from 'constants/constants';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import cn from 'classnames';

export const TABS = [
  {
    label: 'Active candidates',
    key: 'candidates',
  },
  {
    label: 'Self-submitted candidates',
    key: 'ssc',
  },
];

const headerCellRender = (title, field, key, sort, setSorting) => () => {
  const order = sort.key === key ? sort.order : null;
  const onClick = () => setSorting({ field, key, order: order === 'asc' ? 'desc' : 'asc' });
  return (
    <>
      {title}
      <Sort className={order} onClick={onClick} />
    </>
  );
};

export const CANDIDATES_GROUP = [
  { value: '', label: 'None', tableData: '' },
  { value: 'actions', label: 'Action', tableData: 'actions' },
  { value: 'jobs', label: 'Job', tableData: 'jobs' },
  { value: 'steps', label: 'Step', tableData: 'steps' },
];

export const COLUMNS_CANDIDATES = (sort, setSorting, userUuid) => [
  {
    title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
    sorter: true,
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 210,
    className: 'active-candidates__name',
    render: data => (
      <Tooltip label={data.full_name_latin}>
        <Link to={data.url} target="_blank">
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
          <Typography.Caption>{data.full_name_local}</Typography.Caption>
        </Link>
      </Tooltip>
    ),
  },
  {
    title: headerCellRender('Level', 'level', 'level', sort, setSorting),
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    className: 'active-candidates__level',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: headerCellRender('Job', 'job', 'job', sort, setSorting),
    dataIndex: 'job',
    rowKey: 'job',
    width: 210,
    sorter: true,
    className: 'active-candidates__job',
    render: data => (
      <>
        <Tooltip label={data.job_name}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.job_name || '-'}</Typography.Text>
        </Tooltip>
        <Typography.Caption>{data.service}</Typography.Caption>
      </>
    ),
  },
  {
    title: headerCellRender('Recruiter', 'recruiter', 'recruiter', sort, setSorting),
    dataIndex: 'recruiter',
    rowKey: 'recruiter',
    width: 210,
    sorter: true,
    className: 'active-candidates__recruiter',
    render: data => (
      <Tooltip label={data.full_name}>
        <Typography.Text>{data.full_name}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: headerCellRender('Status & Step', 'status_step', 'status', sort, setSorting),
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 228,
    sorter: true,
    className: 'active-candidates__status-step',
    render: data =>
      data.step ? (
        <>
          <Status type={getJobStatusColor(data.status)}>{data.status}</Status>
          <Typography.Caption>{data.step}</Typography.Caption>
        </>
      ) : (
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
      ),
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 202,
    fixed: 'right',
    className: 'active-candidates__action',
    render: ({ text, subtext, time, link, button, recruiter_uuid }) => (
      <>
        {text && <Typography.Text className="text">{text}</Typography.Text>}
        {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
        {time && <Typography.Caption className="time">{time}</Typography.Caption>}
        {link && (
          <a className="link" href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
        {button && (
          <a
            className={cn('action-button', { disabled: userUuid !== recruiter_uuid })}
            href={button.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type={BUTTON_TYPES.PRIMARY} disabled={userUuid !== recruiter_uuid}>
              {button.label}
            </Button>
          </a>
        )}
      </>
    ),
  },
];

export const COLUMNS_CANDIDATES_ACTION_TOP = (sort, setSorting) => [
  {
    title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
    sorter: true,
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 210,
    className: 'active-candidates__name',
    render: () => null,
  },
  {
    title: headerCellRender('Level', 'level', 'level', sort, setSorting),
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    className: 'active-candidates__level',
    render: () => null,
  },
  {
    title: headerCellRender('Job', 'job', 'job', sort, setSorting),
    sorter: true,
    dataIndex: 'job',
    rowKey: 'job',
    width: 210,
    className: 'active-candidates__job',
    render: () => null,
  },
  {
    title: headerCellRender('Recruiter', 'recruiter', 'recruiter', sort, setSorting),
    dataIndex: 'recruiter',
    rowKey: 'recruiter',
    width: 210,
    sorter: true,
    className: 'active-candidates__recruiter',
    render: () => null,
  },
  {
    title: headerCellRender('Status & Step', 'status_step', 'status_step', sort, setSorting),
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 210,
    sorter: true,
    className: 'active-candidates__status-step',
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 220,
    fixed: 'right',
    className: 'active-candidates__action',
    render: () => null,
  },
];

export const COLUMNS_CANDIDATES_ACTION = userUuid => [
  {
    title: 'Candidate name',
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 210,
    ellipsis: true,
    className: 'active-candidates__name',
    render: data => (
      <Tooltip label={data.full_name_latin}>
        <Link to={data.url} target="_blank">
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
          <Typography.Caption>{data.full_name_local}</Typography.Caption>
        </Link>
      </Tooltip>
    ),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    ellipsis: true,
    className: 'active-candidates__level',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'Job',
    dataIndex: 'job',
    rowKey: 'job',
    width: 210,
    ellipsis: true,
    className: 'active-candidates__job',
    render: ({ job_name, service }) => (
      <>
        <Tooltip label={job_name}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{job_name || '-'}</Typography.Text>
        </Tooltip>
        <Typography.Caption>{service}</Typography.Caption>
      </>
    ),
  },
  {
    title: 'Recruiter',
    dataIndex: 'recruiter',
    rowKey: 'recruiter',
    width: 210,
    sorter: true,
    className: 'active-candidates__recruiter',
    render: data => (
      <Tooltip label={data.full_name}>
        <Typography.Text>{data.full_name}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 210,
    ellipsis: true,
    className: 'active-candidates__status',
    render: ({ step, status }) =>
      step ? (
        <>
          <Status type={getJobStatusColor(status)}>{status}</Status>
          <Typography.Caption>{step}</Typography.Caption>
        </>
      ) : (
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
      ),
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    ellipsis: true,
    width: 220,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'active-candidates__action',
    render: ({ text, subtext, time, link, button, recruiter_uuid }) => (
      <>
        {text && <Typography.Text className="text">{text}</Typography.Text>}
        {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
        {time && <Typography.Caption className="time">{time}</Typography.Caption>}
        {link && (
          <a className="link" href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
        {button && (
          <a
            className={cn('action-button', { disabled: userUuid !== recruiter_uuid })}
            href={button.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type={BUTTON_TYPES.PRIMARY} disabled={userUuid !== recruiter_uuid}>
              {button.label}
            </Button>
          </a>
        )}
      </>
    ),
  },
];

export const COLUMNS_CANDIDATES_JOB_TOP = (sort, setSorting) => [
  {
    title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
    sorter: true,
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 320,
    className: 'active-candidates__name',
    render: () => null,
  },
  {
    title: headerCellRender('Level', 'level', 'level', sort, setSorting),
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    className: 'active-candidates__level',
    render: () => null,
  },
  {
    title: headerCellRender('Recruiter', 'recruiter', 'recruiter', sort, setSorting),
    dataIndex: 'recruiter',
    rowKey: 'recruiter',
    width: 210,
    sorter: true,
    className: 'active-candidates__recruiter',
    render: () => null,
  },
  {
    title: headerCellRender('Status & Step', 'status_step', 'status_step', sort, setSorting),
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 210,
    sorter: true,
    className: 'active-candidates__status-step',
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 320,
    fixed: 'right',
    className: 'active-candidates__action',
    render: () => null,
  },
];

export const COLUMNS_CANDIDATES_JOB = userUuid => [
  {
    title: 'Candidate name',
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 320,
    ellipsis: true,
    className: 'active-candidates__name',
    render: data => (
      <Tooltip label={data.full_name_latin}>
        <Link to={data.url} target="_blank">
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
          <Typography.Caption>{data.full_name_local}</Typography.Caption>
        </Link>
      </Tooltip>
    ),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    ellipsis: true,
    className: 'active-candidates__level',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'Recruiter',
    dataIndex: 'recruiter',
    rowKey: 'recruiter',
    width: 210,
    ellipsis: true,
    className: 'active-candidates__recruiter',
    render: data => (
      <Tooltip label={data.full_name}>
        <Typography.Text>{data.full_name}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 210,
    ellipsis: true,
    className: 'active-candidates__status',
    render: ({ step, status }) =>
      step ? (
        <>
          <Status type={getJobStatusColor(status)}>{status}</Status>
          <Typography.Caption>{step}</Typography.Caption>
        </>
      ) : (
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
      ),
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    ellipsis: true,
    width: 320,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'active-candidates__action',
    render: ({ text, subtext, time, link, button, recruiter_uuid }) => (
      <>
        {text && <Typography.Text className="text">{text}</Typography.Text>}
        {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
        {time && <Typography.Caption className="time">{time}</Typography.Caption>}
        {link && (
          <a className="link" href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
        {button && (
          <a
            className={cn('action-button', { disabled: userUuid !== recruiter_uuid })}
            href={button.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type={BUTTON_TYPES.PRIMARY} disabled={userUuid !== recruiter_uuid}>
              {button.label}
            </Button>
          </a>
        )}
      </>
    ),
  },
];

export const COLUMNS_CANDIDATES_STEP_TOP = (sort, setSorting) => [
  {
    title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
    sorter: true,
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 210,
    className: 'active-candidates__name',
    render: () => null,
  },
  {
    title: headerCellRender('Level', 'level', 'level', sort, setSorting),
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    className: 'active-candidates__level',
    render: () => null,
  },
  {
    title: headerCellRender('Job', 'job', 'job', sort, setSorting),
    sorter: true,
    dataIndex: 'job',
    rowKey: 'job',
    width: 210,
    className: 'active-candidates__job',
    render: () => null,
  },
  {
    title: headerCellRender('Recruiter', 'recruiter', 'recruiter', sort, setSorting),
    dataIndex: 'recruiter',
    rowKey: 'recruiter',
    width: 210,
    sorter: true,
    className: 'active-candidates__recruiter',
    render: () => null,
  },
  {
    title: headerCellRender('Status & Step', 'status_step', 'status_step', sort, setSorting),
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 210,
    sorter: true,
    className: 'active-candidates__status-step',
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 220,
    fixed: 'right',
    className: 'active-candidates__action',
    render: () => null,
  },
];

export const COLUMNS_CANDIDATES_STEP = userUuid => [
  {
    title: 'Candidate name',
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 210,
    ellipsis: true,
    className: 'active-candidates__name',
    render: data => (
      <Tooltip label={data.full_name_latin}>
        <Link to={data.url} target="_blank">
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
          <Typography.Caption>{data.full_name_local}</Typography.Caption>
        </Link>
      </Tooltip>
    ),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    ellipsis: true,
    className: 'active-candidates__level',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'Job',
    dataIndex: 'job',
    rowKey: 'job',
    width: 210,
    ellipsis: true,
    className: 'active-candidates__job',
    render: ({ job_name, service }) => (
      <>
        <Tooltip label={job_name}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{job_name || '-'}</Typography.Text>
        </Tooltip>
        <Typography.Caption>{service}</Typography.Caption>
      </>
    ),
  },
  {
    title: 'Recruiter',
    dataIndex: 'recruiter',
    rowKey: 'recruiter',
    width: 210,
    ellipsis: true,
    className: 'active-candidates__recruiter',
    render: data => (
      <Tooltip label={data.full_name}>
        <Typography.Text>{data.full_name}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 210,
    ellipsis: true,
    className: 'active-candidates__status',
    render: ({ step, status }) =>
      step ? (
        <>
          <Status type={getJobStatusColor(status)}>{status}</Status>
          <Typography.Caption>{step}</Typography.Caption>
        </>
      ) : (
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
      ),
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    ellipsis: true,
    width: 220,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'active-candidates__action',
    render: ({ text, subtext, time, link, button, recruiter_uuid }) => (
      <>
        {text && <Typography.Text className="text">{text}</Typography.Text>}
        {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
        {time && <Typography.Caption className="time">{time}</Typography.Caption>}
        {link && (
          <a className="link" href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
        {button && (
          <a
            className={cn('action-button', { disabled: userUuid !== recruiter_uuid })}
            href={button.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type={BUTTON_TYPES.PRIMARY} disabled={userUuid !== recruiter_uuid}>
              {button.label}
            </Button>
          </a>
        )}
      </>
    ),
  },
];

export const CANDIDATES_TABLE_COLUMNS = {
  [CANDIDATES_GROUP[0].value]: [COLUMNS_CANDIDATES],
  [CANDIDATES_GROUP[1].value]: [COLUMNS_CANDIDATES_ACTION_TOP, COLUMNS_CANDIDATES_ACTION],
  [CANDIDATES_GROUP[2].value]: [COLUMNS_CANDIDATES_JOB_TOP, COLUMNS_CANDIDATES_JOB],
  [CANDIDATES_GROUP[3].value]: [COLUMNS_CANDIDATES_STEP_TOP, COLUMNS_CANDIDATES_STEP],
};

export const INITIAL_VALUES_1 = {
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};

export const INITIAL_VALUES = {
  sorting: { key: 'candidate', order: 'asc', field: 'candidate' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
  only_with_actions: false,
};

export const BUTTON_LABELS = {
  [stepsStatuses.waitForResolution]: 'Submit Decision',
  [stepsStatuses.waitingForFinalDecision]: 'Submit Final Decision',
  [stepsStatuses.provideFeedback]: 'Provide Feedback',
  [stepsStatuses.scheduleInterview]: 'Schedule Interview',
  [stepsStatuses.sendOffer]: 'Add Offer',
  [stepsStatuses.receiveFeedback]: 'Submit Decision',
};
