import moment from 'moment';
import { dateFormatter } from 'helpers/dateFormatter';
import { STEPS_LABELS, stepsStatuses } from 'constants/constants';
import { OPENING_STATUSES } from 'constants/opening';
import { BUTTON_LABELS, OPENINGS_GROUP } from './constants';

export const isServiceGrouping = grouping => grouping === OPENINGS_GROUP[0].value;

export const serviceTableDataMapper = services =>
  services.map(service => ({
    uuid: service.uuid,
    name: service.name,
    count: service.openings_count,
    candidates_count: service.candidates_count,
    openings_count: service.total_openings_count,
    openings: service.openings.map(opening => ({
      uuid: opening.uuid,
      job: {
        job_name: opening.job_name,
        job_uuid: opening.job_uuid,
        priority: opening.priority,
      },
      level: opening.custom_level || opening.level,
      location: opening.locations.join(', ') || '-',
      creation_date: dateFormatter(opening.created),
      expected_date: {
        date: dateFormatter(opening.expected),
        error:
          opening.status === OPENING_STATUSES.OPEN &&
          moment(opening.expected, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day')),
      },
      team_lead: opening.team_lead.full_name,
      status: opening.status,
      action: {
        status: opening.status,
        job_uuid: opening.job_uuid,
        job_name: opening.job_name,
        service_uuid: opening.uuid,
        opening,
      },
    })),
  }));

export const jobTableDataMapper = jobs =>
  jobs.map(job => ({
    uuid: job.uuid,
    name: job.name,
    count: job.openings_count,
    service: job.service_name,
    candidates_count: job.candidates_count,
    openings_count: job.total_openings_count,
    openings: job.openings.map(opening => ({
      uuid: opening.uuid,
      location: opening.locations.join(', ') || '-',
      level: { priority: opening.priority, level: opening.custom_level || opening.level },
      creation_date: dateFormatter(opening.created),
      expected_date: {
        date: dateFormatter(opening.expected),
        error:
          opening.status === OPENING_STATUSES.OPEN &&
          moment(opening.expected, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day')),
      },
      team_lead: opening.team_lead.full_name,
      status: opening.status,
      action: {
        status: opening.status,
        job_uuid: job.uuid,
        job_name: job.name,
        service_uuid: opening.uuid,
        opening,
      },
    })),
  }));

export const candidatesTableDataMapper = candidates =>
  candidates.map((c, index) => ({
    uuid: c.uuid + index,
    candidate_name: {
      url: c.assignment
        ? `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`
        : `/candidates/${c.uuid}/show`,
      full_name_latin: c.full_name_latin,
      full_name_local: c.full_name_local,
      hot: false,
    },
    level: c.level,
    job: {
      job_name: c.assignment?.job_name,
      service: c.assignment?.service,
    },
    status_step: {
      status: c.assignment?.status,
      step:
        c.assignment &&
        `${Object.values(STEPS_LABELS).findIndex(l => l === c.assignment.step_type) + 1} - ${c.assignment.step_type}`,
    },
    action: [
      stepsStatuses.waitingForFinalDecision,
      stepsStatuses.provideFeedback,
      stepsStatuses.scheduleInterview,
      stepsStatuses.sendOffer,
    ].includes(c.assignment?.status)
      ? {
          text: '',
          subtext: '',
          time: '',
          link: '',
          button: {
            label: BUTTON_LABELS[c.assignment.status],
            link: `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`,
          },
        }
      : stepsStatuses.waitForResolution === c.assignment?.status
      ? c.start_date && moment(c.start_date, 'YYYY-MM-DD').startOf('day').isAfter(moment().startOf('day'))
        ? {
            text: 'Entry date:',
            subtext: moment(c.start_date, 'YYYY-MM-DD').format('MMM DD, YYYY'),
            time: '',
            link: '',
            button: '',
          }
        : {
            text: '',
            subtext: '',
            time: '',
            link: '',
            button: {
              label: BUTTON_LABELS[c.assignment.status],
              link: `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`,
            },
          }
      : c.expected_answer_date
      ? moment(c.expected_answer_date, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day'))
        ? {
            text: '',
            subtext: '',
            time: '',
            link: '',
            button: {
              label: BUTTON_LABELS[c.assignment.status],
              link: `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`,
            },
          }
        : {
            text: 'Expected answer date:',
            subtext: moment(c.expected_answer_date).format('MMM DD, YYYY'),
            time: '',
            link: '',
            button: '',
          }
      : c.interview
      ? {
          text: moment(c.interview.date).format('ddd MMM DD, YYYY'),
          subtext: `${dateFormatter(`${c.interview.date} ${c.interview.from_time}`, 'H:mm')} - ${dateFormatter(
            `${c.interview.date} ${c.interview.to_time}`,
            'H:mm'
          )}`,
          time: '',
          link: c.interview.link,
          button: '',
        }
      : c.reminder
      ? {
          text: `Reminder: ${c.reminder.type}`,
          subtext: '',
          time: moment(c.reminder.datetime).format('ddd MMM DD, YYYY [at] H:mm'),
          link: '',
          button: '',
        }
      : {
          text: '',
          subtext: '',
          time: '',
          link: '',
          button: '',
        },
  }));
