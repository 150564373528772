import { OPENING_STATUSES } from 'constants/opening';

export const useOpeningLabel = ({ decline_reason, closed, candidate, status }) => {
  if (decline_reason && status === OPENING_STATUSES.DECLINED)
    return { label: 'Decline reason: ', label_desc: decline_reason };
  if (closed && status === OPENING_STATUSES.CLOSED) return { label: 'Closure comment: ', label_desc: closed.comment };
  if (candidate)
    return {
      label: status === OPENING_STATUSES.WAITING_FOR_ENTRY ? 'Linked Candidate: ' : 'Hired Candidate: ',
      label_desc: candidate.full_name_latin,
      label_link: `/candidates/${candidate.uuid}/show`,
    };
  return {};
};
