import React, { useEffect, useState } from 'react';
import { TextArea, Popup } from '_atoms';
import { Typography } from '_atoms/Typography';
import { BUTTON_TEXT } from 'constants/text';

export const PopupWithdraw = ({ open, onClose, onWithdraw, userData = {} }) => {
  const { first_name_latin, last_name_latin, first_name_local, last_name_local, speciality } = userData;

  const [comment, setComment] = useState('');
  const onChangeComment = e => setComment(e.target.value);
  const onWithdrawClick = () => onWithdraw(comment);

  useEffect(() => {
    if (!open) setComment('');
  }, [open]);

  return (
    <Popup
      open={open}
      className="mobile-full-size"
      title="Do you want to withdraw candidate from the job?"
      cancelButtonProps={{
        onClick: onClose,
        children: BUTTON_TEXT.CANCEL,
      }}
      okButtonProps={{
        children: BUTTON_TEXT.WITHDRAW,
        onClick: onWithdrawClick,
      }}
    >
      <div className="user-info">
        <Typography.Text className="user-info__title">
          {first_name_latin} {last_name_latin}
        </Typography.Text>
        <Typography.Caption className="user-info__subtitle">
          {first_name_local} {last_name_local}
        </Typography.Caption>
        <Typography.Caption className="user-info__subtitle">{speciality}</Typography.Caption>
      </div>
      <TextArea
        label="Comment"
        value={comment}
        maxLength={6000}
        placeholder="Please enter comment"
        onChange={onChangeComment}
      />
    </Popup>
  );
};
