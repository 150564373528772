import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextArea, Popup, Select } from '_atoms';
import { STATUSES } from 'constants/user';
import { BUTTON_TEXT, POPUP_LABELS } from 'constants/text';
import { getCandidateInfo, getJobs, setCandidateMarkAsFired } from 'actions';
import { getCandidateJobs } from 'store/selectors/candidateSelectors';
import './style.scss';

export const PopupFire = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [firing_comment, setComment] = useState('');
  const [job, setJob] = useState(undefined);
  const onChangeComment = e => setComment(e.target.value);

  const candidateJobs = useSelector(getCandidateJobs);
  const jobOptions = useMemo(
    () => candidateJobs.filter(j => j.candidate_status === STATUSES.HIRED).map(j => ({ value: j.uuid, label: j.job })),
    [candidateJobs]
  );

  const onClosePopup = () => {
    setComment('');
    setJob(undefined);
    onClose();
  };

  const onBlackList = () => {
    setCandidateMarkAsFired(id, job.value, { firing_comment }).then(() => {
      dispatch(getCandidateInfo(id));
      dispatch(getJobs(id));
      onClosePopup();
    });
  };

  useEffect(() => {
    if (jobOptions.length === 1) setJob(jobOptions[0]);
  }, [jobOptions]);

  return (
    <Popup
      open={open}
      title={POPUP_LABELS.FIRED_CANDIDATE}
      className="fire-candidate-popup"
      cancelButtonProps={{
        children: BUTTON_TEXT.CANCEL,
        onClick: onClosePopup,
      }}
      okButtonProps={{
        children: BUTTON_TEXT.FIRED_CANDIDATE,
        disabled: !firing_comment || !job,
        onClick: onBlackList,
      }}
    >
      <Select label="Job" value={job} onChange={setJob} options={jobOptions} disabled={jobOptions.length < 2} />
      <TextArea label="Comment" value={firing_comment} placeholder="Add a comment" onChange={onChangeComment} />
    </Popup>
  );
};
