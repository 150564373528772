import React, { useState } from 'react';
import { TextArea, Popup } from '_atoms';
import { Typography } from '_atoms/Typography';
import { BUTTON_TEXT } from 'constants/text';
import './style.scss';

export const PopupCompleteReminder = ({ open, onClose, onSave }) => {
  const [comment, setComment] = useState('');
  const onChangeComment = e => setComment(e.target.value);
  const onComplete = () => onSave(comment);
  return (
    <Popup
      open={open}
      title="Complete reminder"
      className="complete-reminder"
      cancelButtonProps={{
        children: BUTTON_TEXT.CANCEL,
        onClick: onClose,
      }}
      okButtonProps={{
        children: BUTTON_TEXT.COMPLETE,
        disabled: !comment,
        onClick: onComplete,
      }}
    >
      <Typography.Text className="complete-reminder__warning">Do you really want to complete reminder?</Typography.Text>
      <TextArea label="Comment" value={comment} placeholder="Add a comment" onChange={onChangeComment} />
    </Popup>
  );
};
