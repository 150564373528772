import moment from 'moment';
import { STATUS_TYPES } from '_atoms/Status';
import { STATUSES } from 'constants/user';

export const dataMapper = (list, action) =>
  list.map(el => ({
    uuid: el.uuid,
    candidate: { uuid: el.uuid, fullNameLatin: el.full_name_latin, fullNameLocal: el.full_name_local },
    team_lead: el.team_lead.full_name,
    recruiter: el.recruiter.full_name,
    experience_level: el.experience_level,
    location: { value: el.location.uuid, label: el.location.name },
    status: {
      type: el.status === STATUSES.FIRED ? STATUS_TYPES.RED : STATUS_TYPES.GREEN,
      status: el.status,
    },
    opening: { ...el.opening, offices: el.opening.offices.map(o => ({ value: o.uuid, label: o.name })) },
    entry_date: moment(el.entry_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    probation_period: el.probation_period,
    action: { show: el.status === STATUSES.HIRED, action },
    comment: el.comment,
  }));
