import React from 'react';
import { OTHER_TEXT } from 'constants/text';
import { ReactComponent as CircularProgress } from 'dist/icons/loading.svg';

export const GlobalLoading = () => (
  <div className="app-loading-wrapper">
    <div className="app-loading">
      <CircularProgress width={40} height={40} />
      <div className="app-loading__text">{OTHER_TEXT.USER_LOADING}</div>
    </div>
  </div>
);
