const validator = (_, value) => {
  if (value && !value[0] && value[1]) {
    return Promise.reject(new Error('Please select start date'));
  }
  if (value && value[0] && !value[1]) {
    return Promise.reject(new Error('Please select end date'));
  }
  return Promise.resolve();
};

export const RULES = {
  ACTIVE: [{ validator }],
  EXPECTED: [{ validator }],
  CREATED: [{ validator }],
};
