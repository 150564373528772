export const getCandidateListFilters = state => state.candidate.filter;
export const getCandidateCountries = state => state.candidate.settings.countries;
export const getCandidateCompanies = state => state.candidate.settings.companies;
export const getCandidateTags = state => state.candidate.settings.tags;
export const getCandidateSpecialities = state => state.candidate.settings.specialities;
export const getCandidateReminders = state => state.candidate.reminders;
export const getCandidateInteractions = state => state.candidate.interactions;
export const getCandidateRecruiters = state => state.candidate.recruiters;
export const getCandidateJobs = state => state.candidate.jobs;
export const getCandidateJobsLoading = state => state.candidate.jobsLoading;
export const getCandidateData = state => state.candidate.candidateData;
export const getCandidateTimeline = state => state.candidate.timeline;
export const getCandidateSources = state => state.candidate.sources;
