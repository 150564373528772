import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Typography } from '_atoms/Typography';
import { ReactComponent as FileImage } from 'dist/icons/uploaded_file.svg';
import './style.scss';

export const File = ({ file, className, ...props }) => (
  <a className={cn('file-default', className)} href={file.file} target="_blank" rel="noreferrer noopener" {...props}>
    <FileImage className="file-default__icon" />
    <div>
      <Typography.Text className="file-default__title">{file.filename}</Typography.Text>
      <Typography.Caption className="file-default__subtitle">
        {moment(file.uploaded).format('MM/DD/YYYY')}
      </Typography.Caption>
    </div>
  </a>
);
