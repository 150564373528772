import { JOB_REJECTION_OPTIONS, JOB_SUBMIT_OPTIONS } from 'constants/job';
import moment from 'moment';

export const DECISION_OPTIONS = [
  { value: JOB_SUBMIT_OPTIONS.APPROVE, label: JOB_SUBMIT_OPTIONS.APPROVE },
  { value: JOB_SUBMIT_OPTIONS.REJECT, label: JOB_SUBMIT_OPTIONS.REJECT },
];

export const REJECTION_REASONS = [
  JOB_REJECTION_OPTIONS.STAY_AT_CURRENT_COMPANY,
  JOB_REJECTION_OPTIONS.LOW_SALARY_OFFERED,
  JOB_REJECTION_OPTIONS.PERSONAL_REASONS,
  JOB_REJECTION_OPTIONS.INTERESTED_IN_OTHER_COMPANYS_PROJECT,
  JOB_REJECTION_OPTIONS.LOCATION_RELOCATION,
  JOB_REJECTION_OPTIONS.BAD_RECOMMENDATIONS,
  JOB_REJECTION_OPTIONS.OTHER,
].map(v => ({ value: v, label: v }));

export const INITIAL_VALUES = {
  decision: undefined,
  opening: undefined,
  office: undefined,
  date: moment().add({ day: 7 }),
  rejection: JOB_REJECTION_OPTIONS.OTHER,
  comment: '',
};
