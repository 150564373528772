import React from 'react';

export const Second = () => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23" cy="23" r="23" fill="#F5F6F7" />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="46" height="46">
      <circle cx="23" cy="23" r="23" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4206 33.5977C27.2014 34.4743 26.9823 36.4906 27.3329 37.411C27.4644 37.7617 34.2144 40.3477 35.0034 40.6545C36.669 41.312 38.1592 42.0133 38.7729 42.7146C39.4742 43.5036 40.0001 44.8623 40.0001 46.6594C26.3686 46.6594 19.2241 46.6594 5.59259 46.6594C5.59259 44.8623 6.11857 43.5036 6.81987 42.7146C7.43351 42.0133 8.92376 41.3558 10.5894 40.6545C11.3783 40.3477 18.1283 37.7617 18.2598 37.411C18.6105 36.4906 18.3913 34.4743 18.1722 33.5977C23.7387 30.7048 21.8978 30.661 27.4206 33.5977Z"
        fill="#FFD0CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1299 38.2879C29.1299 38.2879 27.2451 40.9616 25.7987 41.0931C23.6071 41.3122 23.0811 40.6109 21.6347 39.1645C20.4074 37.9811 18.961 36.3593 18.4789 35.2635C18.4789 35.2635 18.2159 33.072 18.0844 32.7213C17.9091 32.3707 22.1607 30.7927 22.1607 30.7927L26.4561 30.7051L27.4643 33.5541C27.4643 33.5541 26.9383 36.2278 27.3766 37.3674L29.1299 38.2879Z"
        fill="#FFB4AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7175 27.4615C15.7175 27.4615 14.4026 27.856 13.4821 26.7602C12.5617 25.6645 10.8523 20.5362 14.8409 21.2375L15.7175 27.4615Z"
        fill="#FFD0CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2452 34.4746C23.3442 38.6824 22.1608 38.6824 18.216 34.4746C15.6299 31.6694 15.1478 28.7327 14.9725 25.27C14.7971 22.158 13.9643 17.8625 15.6738 15.8025C18.3475 12.6028 27.1137 12.6028 29.7874 15.8025C31.4968 17.8625 30.6202 22.158 30.4887 25.27C30.3134 28.7327 29.8313 31.6694 27.2452 34.4746Z"
        fill="#FFD9D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5377 14.531C27.5377 14.531 25.6091 17.7307 20.4809 19.3963C15.3088 21.1057 15.002 27.4174 15.002 27.4174C15.002 27.4174 12.8542 25.5765 12.4159 22.2453C11.1887 12.9531 17.1935 8.00018 23.1108 8.00018C31.2634 7.95635 34.2001 16.0651 32.7975 22.859C32.4907 24.3054 32.0962 25.7518 31.7455 26.1463C30.9127 26.9353 30.3429 21.7632 30.3429 21.7632C30.3429 21.7632 26.4419 18.0375 27.5377 14.531Z"
        fill="#6A4747"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8231 38.156C30.8832 39.0764 34.4335 40.4352 35.0033 40.6544C36.6689 41.3118 38.1592 42.0131 38.7728 42.7144C39.4741 43.5034 40.0001 44.8622 40.0001 46.6593C26.3686 46.6593 19.2241 46.6593 5.59253 46.6593C5.59253 44.8622 6.11851 43.5034 6.81981 42.7144C7.43344 42.0131 8.9237 41.3557 10.5893 40.6544C11.1591 40.4352 14.7533 39.0326 16.8133 38.156C17.8653 40.6105 20.1445 42.3638 22.7744 42.3638C25.4919 42.32 27.7712 40.6105 28.8231 38.156Z"
        fill="#FC4C02"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3938 21.8912C30.5691 21.8912 31.6649 20.4886 32.4538 21.5406C33.199 22.5925 31.9717 27.8961 30.2185 27.7646C30.1746 27.7646 29.1665 25.0033 30.3938 21.8912Z"
        fill="#FFD9D4"
      />
    </g>
  </svg>
);
