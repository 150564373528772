import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { subject } from '@casl/ability';
import { Button, BUTTON_TYPES, Tooltip } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { Priority } from '_atoms/Priority';
import { ACTIONS, Can, UI } from 'permission';
import { BUTTON_TEXT } from 'constants/text';
import { OPENING_STATUS_TYPES, OPENING_STATUSES } from 'constants/opening';
import { stepsStatuses } from 'constants/constants';
import { getJobStatusColor } from '_templates/Candidates/utils';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';

const headerCellRender = (title, field, key, sort, setSorting) => () => {
  const order = sort.key === key ? sort.order : null;
  const onClick = () => setSorting({ field, key, order: order === 'asc' ? 'desc' : 'asc' });
  return (
    <>
      {title}
      <Sort className={order} onClick={onClick} />
    </>
  );
};

export const CANDIDATES_GROUP = [
  { value: '', label: 'None', tableData: '' },
  { value: 'actions', label: 'Action', tableData: 'actions' },
  { value: 'jobs', label: 'Job', tableData: 'jobs' },
  { value: 'steps', label: 'Step', tableData: 'steps' },
];

export const OPENING_STATUSES_OPTIONS = [
  OPENING_STATUSES.SH_APPROVAL,
  OPENING_STATUSES.RM_APPROVAL,
  OPENING_STATUSES.LM_APPROVAL,
  OPENING_STATUSES.HRL_APPROVAL,
  OPENING_STATUSES.OPEN,
  OPENING_STATUSES.WAITING_FOR_ENTRY,
].map(value => ({ value, label: value }));

export const COLUMNS_OPENINGS_SERVICE_TOP = [
  {
    title: 'Job',
    sorter: true,
    dataIndex: 'job',
    rowKey: 'job',
    width: 228,
    render: () => null,
  },
  {
    title: 'Level',
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    render: () => null,
  },
  {
    title: 'Location',
    sorter: true,
    dataIndex: 'location',
    rowKey: 'location',
    width: 120,
    render: () => null,
  },
  {
    title: 'Expected date',
    dataIndex: 'expected_date',
    rowKey: 'expected_date',
    width: 112,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Team lead',
    dataIndex: 'team_lead',
    rowKey: 'team_lead',
    width: 228,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'status',
    width: 164,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 208,
    fixed: 'right',
    render: () => null,
  },
];

export const COLUMNS_OPENINGS_SERVICE = onAction => [
  {
    title: 'Job',
    dataIndex: 'job',
    rowKey: 'job',
    width: 228,
    ellipsis: true,
    className: 'all-openings__job',
    render: data => (
      <a href={`/jobs/${data.job_uuid}/show/openings`} target="_blank" rel="noreferrer noopener">
        <Priority priority={data.priority} />
        <Tooltip label={data.job_name}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.job_name}</Typography.Text>
        </Tooltip>
      </a>
    ),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    className: 'all-openings__level',
    render: data => (
      <Tooltip label={data}>
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    rowKey: 'location',
    width: 120,
    className: 'all-openings__location',
    render: data => (
      <Tooltip label={data}>
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Expected date',
    dataIndex: 'expected_date',
    rowKey: 'expected_date',
    width: 112,
    className: 'all-openings__expected-date',
    render: ({ date, error }) => (
      <Typography.Text className={cn({ error })} weight={TYPOGRAPHY_WEIGHT.BOLD}>
        {date}
      </Typography.Text>
    ),
  },
  {
    title: 'Team lead',
    dataIndex: 'team_lead',
    rowKey: 'team_lead',
    width: 228,
    className: 'all-openings__team-lead',
    render: data => (
      <Tooltip label={data}>
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'status',
    width: 164,
    className: 'all-openings__status',
    render: status => <Status type={OPENING_STATUS_TYPES[status]}>{status}</Status>,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 208,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'all-openings__action',
    render: ({ status, ...data }) => (
      <Can I={ACTIONS.READ} a={subject(UI.APPROVE_DECLINE, { status })}>
        <Button onClick={() => onAction(data, 'decline')}>{BUTTON_TEXT.DECLINE}</Button>
        <Button onClick={() => onAction(data, 'approve')} type={BUTTON_TYPES.PRIMARY}>
          {BUTTON_TEXT.APPROVE}
        </Button>
      </Can>
    ),
  },
];

export const COLUMNS_OPENINGS_JOB_TOP = [
  {
    title: 'Level',
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 122,
    render: () => null,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    rowKey: 'location',
    width: 256,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Expected date',
    dataIndex: 'expected_date',
    rowKey: 'expected_date',
    width: 138,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Team lead',
    dataIndex: 'team_lead',
    rowKey: 'team_lead',
    width: 256,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'status',
    width: 164,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 208,
    fixed: 'right',
    render: () => null,
  },
];

export const COLUMNS_OPENINGS_JOB = onAction => [
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 122,
    ellipsis: true,
    className: 'all-openings__level',
    render: ({ level, priority }) => (
      <>
        <Priority priority={priority} />
        <Tooltip label={level}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{level}</Typography.Text>
        </Tooltip>
      </>
    ),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    rowKey: 'location',
    width: 256,
    ellipsis: true,
    className: 'all-openings__location',
    render: data => (
      <Tooltip label={data}>
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Expected date',
    dataIndex: 'expected_date',
    rowKey: 'expected_date',
    width: 138,
    ellipsis: true,
    className: 'all-openings__expected-date',
    render: ({ date, error }) => (
      <Typography.Text className={cn({ error })} weight={TYPOGRAPHY_WEIGHT.BOLD}>
        {date}
      </Typography.Text>
    ),
  },
  {
    title: 'Team lead',
    dataIndex: 'team_lead',
    rowKey: 'team_lead',
    width: 256,
    ellipsis: true,
    className: 'all-openings__team-lead',
    render: data => (
      <Tooltip label={data}>
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'status',
    width: 164,
    ellipsis: true,
    className: 'all-openings__status',
    render: status => <Status type={OPENING_STATUS_TYPES[status]}>{status}</Status>,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 208,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'all-openings__action',
    render: ({ status, ...data }) => (
      <Can I={ACTIONS.READ} a={subject(UI.APPROVE_DECLINE, { status })}>
        <Button onClick={() => onAction(data, 'decline')}>{BUTTON_TEXT.DECLINE}</Button>
        <Button onClick={() => onAction(data, 'approve')} type={BUTTON_TYPES.PRIMARY}>
          {BUTTON_TEXT.APPROVE}
        </Button>
      </Can>
    ),
  },
];

export const COLUMNS_CANDIDATES = (sort, setSorting) => [
  {
    title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
    sorter: true,
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 262,
    className: 'active-candidates__name',
    render: data => (
      <Tooltip label={data.full_name_latin}>
        <Link to={data.url} target="_blank">
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
          <Typography.Caption>{data.full_name_local}</Typography.Caption>
        </Link>
      </Tooltip>
    ),
  },
  {
    title: headerCellRender('Level', 'level', 'level', sort, setSorting),
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 128,
    className: 'active-candidates__level',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: headerCellRender('Job', 'job', 'job', sort, setSorting),
    dataIndex: 'job',
    rowKey: 'job',
    width: 262,
    sorter: true,
    className: 'active-candidates__job',
    render: data => (
      <>
        <Tooltip label={data.job_name}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.job_name || '-'}</Typography.Text>
        </Tooltip>
        <Typography.Caption>{data.service}</Typography.Caption>
      </>
    ),
  },
  {
    title: headerCellRender('Status & Step', 'status_step', 'status', sort, setSorting),
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 228,
    sorter: true,
    className: 'active-candidates__status-step',
    render: data =>
      data.step ? (
        <>
          <Status type={getJobStatusColor(data.status)}>{data.status}</Status>
          <Typography.Caption>{data.step}</Typography.Caption>
        </>
      ) : (
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
      ),
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 264,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'active-candidates__action',
    render: ({ text, subtext, time, link, button }) => (
      <>
        {text && <Typography.Text className="text">{text}</Typography.Text>}
        {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
        {time && <Typography.Caption className="time">{time}</Typography.Caption>}
        {link && (
          <a className="link" href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
        {button && (
          <a className="action-button" href={button.link} target="_blank" rel="noopener noreferrer">
            <Button type={BUTTON_TYPES.PRIMARY}>{button.label}</Button>
          </a>
        )}
      </>
    ),
  },
];

export const COLUMNS_CANDIDATES_ACTION_TOP = (sort, setSorting) => [
  {
    title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
    sorter: true,
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 262,
    className: 'active-candidates__name',
    render: () => null,
  },
  {
    title: headerCellRender('Level', 'level', 'level', sort, setSorting),
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 128,
    className: 'active-candidates__level',
    render: () => null,
  },
  {
    title: headerCellRender('Job', 'job', 'job', sort, setSorting),
    sorter: true,
    dataIndex: 'job',
    rowKey: 'job',
    width: 262,
    className: 'active-candidates__job',
    render: () => null,
  },
  {
    title: headerCellRender('Status & Step', 'status_step', 'status_step', sort, setSorting),
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 228,
    sorter: true,
    className: 'active-candidates__status-step',
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 264,
    fixed: 'right',
    className: 'active-candidates__action',
    render: () => null,
  },
];

export const COLUMNS_CANDIDATES_ACTION = [
  {
    title: 'Candidate name',
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 262,
    ellipsis: true,
    className: 'active-candidates__name',
    render: data => (
      <Tooltip label={data.full_name_latin}>
        <Link to={data.url} target="_blank">
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
          <Typography.Caption>{data.full_name_local}</Typography.Caption>
        </Link>
      </Tooltip>
    ),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 128,
    ellipsis: true,
    className: 'active-candidates__level',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'Job',
    dataIndex: 'job',
    rowKey: 'job',
    width: 262,
    ellipsis: true,
    className: 'active-candidates__job',
    render: ({ job_name, service }) => (
      <>
        <Tooltip label={job_name}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{job_name || '-'}</Typography.Text>
        </Tooltip>
        <Typography.Caption>{service}</Typography.Caption>
      </>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 228,
    ellipsis: true,
    className: 'active-candidates__status',
    render: ({ step, status }) =>
      step ? (
        <>
          <Status type={getJobStatusColor(status)}>{status}</Status>
          <Typography.Caption>{step}</Typography.Caption>
        </>
      ) : (
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
      ),
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 264,
    fixed: 'right',
    hideMobileTitle: true,
    ellipsis: true,
    className: 'active-candidates__action',
    render: ({ text, subtext, time, link, button }) => (
      <>
        {text && <Typography.Text className="text">{text}</Typography.Text>}
        {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
        {time && <Typography.Caption className="time">{time}</Typography.Caption>}
        {link && (
          <a className="link" href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
        {button && (
          <a className="action-button" href={button.link} target="_blank" rel="noopener noreferrer">
            <Button type={BUTTON_TYPES.PRIMARY}>{button.label}</Button>
          </a>
        )}
      </>
    ),
  },
];

export const COLUMNS_CANDIDATES_JOB_TOP = (sort, setSorting) => [
  {
    title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
    sorter: true,
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 340,
    className: 'active-candidates__name',
    render: () => null,
  },
  {
    title: headerCellRender('Level', 'level', 'level', sort, setSorting),
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 200,
    className: 'active-candidates__level',
    render: () => null,
  },
  {
    title: headerCellRender('Status & Step', 'status_step', 'status_step', sort, setSorting),
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 240,
    sorter: true,
    className: 'active-candidates__status-step',
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 364,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'active-candidates__action',
    render: () => null,
  },
];

export const COLUMNS_CANDIDATES_JOB = [
  {
    title: 'Candidate name',
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 340,
    ellipsis: true,
    className: 'active-candidates__name',
    render: data => (
      <Link to={data.url} target="_blank">
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
        <Typography.Caption>{data.full_name_local}</Typography.Caption>
      </Link>
    ),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 200,
    ellipsis: true,
    className: 'active-candidates__level',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'Status',
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 240,
    ellipsis: true,
    className: 'active-candidates__status',
    render: ({ step, status }) =>
      step ? (
        <>
          <Status type={getJobStatusColor(status)}>{status}</Status>
          <Typography.Caption>{step}</Typography.Caption>
        </>
      ) : (
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
      ),
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 364,
    fixed: 'right',
    hideMobileTitle: true,
    ellipsis: true,
    className: 'active-candidates__action',
    render: ({ text, subtext, time, link, button }) => (
      <>
        {text && <Typography.Text className="text">{text}</Typography.Text>}
        {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
        {time && <Typography.Caption className="time">{time}</Typography.Caption>}
        {link && (
          <a className="link" href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
        {button && (
          <a className="action-button" href={button.link} target="_blank" rel="noopener noreferrer">
            <Button type={BUTTON_TYPES.PRIMARY}>{button.label}</Button>
          </a>
        )}
      </>
    ),
  },
];

export const COLUMNS_CANDIDATES_STEP_TOP = (sort, setSorting) => [
  {
    title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
    sorter: true,
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 262,
    className: 'active-candidates__name',
    render: () => null,
  },
  {
    title: headerCellRender('Level', 'level', 'level', sort, setSorting),
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 128,
    className: 'active-candidates__level',
    render: () => null,
  },
  {
    title: headerCellRender('Job', 'job', 'job', sort, setSorting),
    sorter: true,
    dataIndex: 'job',
    rowKey: 'job',
    width: 262,
    className: 'active-candidates__job',
    render: () => null,
  },
  {
    title: headerCellRender('Status & Step', 'status_step', 'status_step', sort, setSorting),
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 228,
    sorter: true,
    className: 'active-candidates__status-step',
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 264,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'active-candidates__action',
    render: () => null,
  },
];

export const COLUMNS_CANDIDATES_STEP = [
  {
    title: 'Candidate name',
    dataIndex: 'candidate_name',
    rowKey: 'candidate_name',
    width: 262,
    ellipsis: true,
    className: 'active-candidates__name',
    render: data => (
      <Link to={data.url} target="_blank">
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
        <Typography.Caption>{data.full_name_local}</Typography.Caption>
      </Link>
    ),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 128,
    ellipsis: true,
    className: 'active-candidates__level',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'Job',
    dataIndex: 'job',
    rowKey: 'job',
    width: 262,
    ellipsis: true,
    className: 'active-candidates__job',
    render: ({ job_name, service }) => (
      <>
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{job_name || '-'}</Typography.Text>
        <Typography.Caption>{service}</Typography.Caption>
      </>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status_step',
    rowKey: 'status_step',
    width: 228,
    ellipsis: true,
    className: 'active-candidates__status',
    render: ({ step, status }) =>
      step ? (
        <>
          <Status type={getJobStatusColor(status)}>{status}</Status>
          <Typography.Caption>{step}</Typography.Caption>
        </>
      ) : (
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
      ),
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 264,
    fixed: 'right',
    hideMobileTitle: true,
    ellipsis: true,
    className: 'active-candidates__action',
    render: ({ text, subtext, time, link, button }) => (
      <>
        {text && <Typography.Text className="text">{text}</Typography.Text>}
        {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
        {time && <Typography.Caption className="time">{time}</Typography.Caption>}
        {link && (
          <a className="link" href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
        {button && (
          <a className="action-button" href={button.link} target="_blank" rel="noopener noreferrer">
            <Button type={BUTTON_TYPES.PRIMARY}>{button.label}</Button>
          </a>
        )}
      </>
    ),
  },
];

export const CANDIDATES_TABLE_COLUMNS = {
  [CANDIDATES_GROUP[0].value]: [COLUMNS_CANDIDATES],
  [CANDIDATES_GROUP[1].value]: [COLUMNS_CANDIDATES_ACTION_TOP, COLUMNS_CANDIDATES_ACTION],
  [CANDIDATES_GROUP[2].value]: [COLUMNS_CANDIDATES_JOB_TOP, COLUMNS_CANDIDATES_JOB],
  [CANDIDATES_GROUP[3].value]: [COLUMNS_CANDIDATES_STEP_TOP, COLUMNS_CANDIDATES_STEP],
};

export const OPENINGS_GROUP = [
  { value: 'service', label: 'Service', tableData: 'job' },
  { value: 'job', label: 'Job', tableData: 'service' },
];

export const INITIAL_VALUES_1 = {
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};

export const INITIAL_VALUES_2 = {
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};

export const INITIAL_VALUES = {
  sorting: { key: 'candidate', order: 'asc', field: 'candidate' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
  only_with_actions: false,
};

export const BUTTON_LABELS = {
  [stepsStatuses.waitForResolution]: 'Submit Decision',
  [stepsStatuses.waitingForFinalDecision]: 'Submit Final Decision',
  [stepsStatuses.provideFeedback]: 'Provide Feedback',
  [stepsStatuses.scheduleInterview]: 'Schedule Interview',
  [stepsStatuses.sendOffer]: 'Add Offer',
  [stepsStatuses.receiveFeedback]: 'Submit Decision',
};
