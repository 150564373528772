import React from 'react';

export const useDebounceEffect = (callback, delay = 300) => {
  const handler = React.useRef();
  return (...v) => {
    if (!callback) return;
    clearTimeout(handler.current);
    handler.current = setTimeout(() => {
      callback(...v);
    }, delay);
  };
};
