import React from 'react';
import { Select, Tooltip, Checkbox, Typography, TYPOGRAPHY_WEIGHT } from '_atoms';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { TOOLTIP_TXT } from 'constants/constants';
import { ACTIONS, Can, UI } from 'permission';
import { ReactComponent as Info } from 'dist/icons/info.svg';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import { FILTER_OPTIONS, TEXT } from './constants';

export const JobOpeningsTabFilters = ({ count, filters, onChangeFilters, levelOptions }) => (
  <Can I={ACTIONS.READ} a={UI.ONLY_MY_OPENINGS} passThrough>
    {access => (
      <div className="openings-filter">
        <div className="openings-filter__item-group">
          <Typography.Title1 weight={TYPOGRAPHY_WEIGHT.BOLD} className="openings-filter__item-group__title">
            {TEXT.TITLE} <span>{count}</span>
          </Typography.Title1>
          <Tooltip label={TOOLTIP_TXT.OPENING}>
            <Info className="openings-filter__item-group__info" />
          </Tooltip>
        </div>
        <div className="openings-filter__item-group filter-section">
          <Select
            className="status"
            label="Status"
            value={filters.statuses}
            mode={SELECT_TYPES.MULTIPLE}
            placeholder="Please select status"
            options={FILTER_OPTIONS}
            onChange={v => onChangeFilters('statuses', v)}
            allowClear={false}
          />
          <Select
            className="level"
            label="Level"
            value={filters.level}
            mode={SELECT_TYPES.MULTIPLE}
            placeholder="Please select level"
            options={levelOptions}
            onChange={v => onChangeFilters('level', v)}
            allowClear={false}
          />
          <div className="extra-filters">
            <button className="sorting" type="button" onClick={() => onChangeFilters('sorting')}>
              {filters.sorting.label}
              <Sort className={filters.sorting.value} />
            </button>
            {!access && (
              <Checkbox
                className="only-my-openings"
                label="Only my openings"
                isSelected={filters.only_my_openings}
                onChange={e => onChangeFilters('only_my_openings', e.target.checked)}
              />
            )}
          </div>
        </div>
      </div>
    )}
  </Can>
);
