import React, { useEffect, useState } from 'react';
import { Select, Popup, User, Tooltip } from '_atoms';
import { fetchWrapper } from 'helpers/helpers';
import { useDebounceEffect } from 'helpers/useDebounceEffect';
import { ReactComponent as OptionalApprover } from 'dist/icons/person.svg';
import { ReactComponent as PrimaryApprover } from 'dist/icons/person-filled.svg';
import { ReactComponent as Delete } from 'dist/icons/close.svg';
import './style.scss';

const getRecruiters = body =>
  fetchWrapper({
    url: 'users/',
    body,
  }).catch(() => []);

export const PopupEditApprovers = ({ open, onClose, onSave, approvers, responsibleRecruiter, requestOptions }) => {
  const [approversList, setApproversList] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setApproversList(
      approvers.map(approver => ({
        value: approver.user.uuid,
        label: approver.user.full_name,
        avatar: approver.user.profile.avatar,
        optional: approver.optional,
        is_default: approver.is_default,
        is_responsible: approver.user.uuid === responsibleRecruiter?.uuid,
      }))
    );
  }, [open, responsibleRecruiter]);

  const onAddApprover = value => {
    setApproversList(values => [value, ...values]);
    setOptions([]);
  };

  const onSearch = (search = '') => {
    setLoading(true);
    getRecruiters({ ...requestOptions, search, exclude_uuid__in: approversList.map(el => el.value) }).then(resp => {
      const newApproversOptions = resp.results.map(user => ({
        value: user.uuid,
        label: user.full_name,
        avatar: user.profile.avatar,
        optional: false,
        is_default: false,
      }));
      setOptions(newApproversOptions);
      setLoading(false);
    });
  };
  const debounceSearch = useDebounceEffect(onSearch, 500);

  const setOptional = value => () =>
    setApproversList(items => items.map(item => (item.value === value ? { ...item, optional: !item.optional } : item)));
  const onDeleteItem = value => () => setApproversList(items => items.filter(item => item.value !== value));
  return (
    <Popup
      open={open}
      title="Edit approvers list"
      cancelButtonProps={{
        children: 'cancel',
        onClick: onClose,
      }}
      okButtonProps={{
        children: 'save',
        onClick: () => onSave(approversList.map(el => ({ user: el.value, optional: el.optional }))),
      }}
      className="edit-approvers-popup mobile-full-size"
    >
      <Select
        placeholder="Type name"
        className="edit-approvers__input"
        value={null}
        onSearch={debounceSearch}
        options={options}
        filterOption={false}
        onChange={onAddApprover}
        loading={loading}
        onBlur={() => setOptions([])}
      />
      <div className="edit-approvers__list">
        {approversList.map(approver => (
          <div key={approver.value} className="approver">
            <div className="approver__info">
              <User
                avatar={approver.avatar}
                fullName={
                  <>
                    {approver.label}
                    {approver.optional && <span className="optional-tag">(Optional)</span>}
                  </>
                }
              />
            </div>
            {!approver.is_responsible && (
              <div className="approver__extra">
                {approver.optional ? (
                  <Tooltip label="Mark as required" placement="topRight">
                    <OptionalApprover onClick={setOptional(approver.value)} />
                  </Tooltip>
                ) : (
                  <Tooltip label="Mark as optional" placement="topRight">
                    <PrimaryApprover onClick={setOptional(approver.value)} />
                  </Tooltip>
                )}
                <Tooltip label="Remove" placement="topRight">
                  <Delete onClick={onDeleteItem(approver.value)} />
                </Tooltip>
              </div>
            )}
          </div>
        ))}
      </div>
    </Popup>
  );
};
