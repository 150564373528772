const basicMapper = array => array.map(c => ({ value: c.uuid, label: c.name }));
const simpleMapper = array => array.map(c => ({ value: c, label: c }));

export const optionsMappers = {
  companies: basicMapper,
  services: basicMapper,
  team_leads: basicMapper,
  jobs_statuses: simpleMapper,
  recruiters: basicMapper,
  openings_statuses: simpleMapper,
  offices: basicMapper,
  experience_levels: array =>
    array.map(c =>
      c.level === 'Custom' ? { value: c.custom_level, label: c.custom_level } : { value: c.level, label: c.level }
    ),
};

export const searchFiltersMapper = ({ search: _s, ...filters }) =>
  Object.fromEntries(Object.entries(filters).map(([k, v]) => [k, v.map(val => val.value)]));
