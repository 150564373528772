import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import { Card, Tabs } from '_atoms';
import { BreadCrumbs } from '_atoms/BreadCrumbs';
import { ExperienceTabContent, JobAssignedTabContent, PopupReassignCandidate } from '_molecules';
import { Timeline, CandidateDetails } from '_organisms';
import history from 'helpers/history';
import { ROLES } from 'permission';
import { clearCandidateData } from 'store/reducers/candidateReducer';
import { getCandidateData, getCandidateJobs } from 'store/selectors/candidateSelectors';
import { getHrManagers } from 'store/selectors/commonSelectors';
import { getUser } from 'store/selectors/authorizeSelectors';
import * as actions from '../../actions';
import { MixpanelService } from '../../helpers/Mixpanel';
import { TABS_OPTIONS } from './constants';
import './style.scss';

export const CandidateProfile = ({ candidateLoading, isSSC, ...props }) => {
  const user = useSelector(getUser);

  const [tab, setTab] = useState(TABS_OPTIONS[0].key);
  const [assignPopup, setAssignPopup] = useState(false);

  const jobs = useSelector(getCandidateJobs);
  const candidateData = useSelector(getCandidateData);

  const { id } = useParams();

  useEffect(() => {
    props.getCandidateInfo(id, isSSC ? 'v2' : 'v1', false, isSSC);
    if (!isSSC) props.getJobs(id);
    else {
      actions.markCandidateAsViewed(id);
    }
    props.getListHrManagers({
      role: [ROLES.RECRUITER, ROLES.RECRUITER_LEAD, ROLES.HR_LEAD],
      my_recruiters_group: user.active_role.name === ROLES.RECRUITER_LEAD,
      ordering: 'full_name',
    });
    const mixpanel = new MixpanelService();
    mixpanel.track('View Page.Candidate Profile', {
      'Candidate ID': id,
    });

    window.scrollTo({ top: 0 });

    return () => {
      props.clearCandidateData();
    };
  }, [id, isSSC]);

  const deleteReminder = data => {
    props.deleteReminder(data.uuid, id);
  };

  const deleteInteraction = data => {
    props.deleteInteraction(data.details.uuid, id);
  };

  const completeReminder = (data, limit, comment) => {
    props.completeReminder(data, id, limit, comment);
  };

  const showAssignPopup = useCallback(() => {
    setAssignPopup(true);
  }, []);

  const closeAssignPopup = () => {
    setAssignPopup(false);
  };

  const fullName =
    candidateData.first_name_latin && `${candidateData.first_name_latin} ${candidateData.last_name_latin}`;

  return (
    <>
      <div className="wrapper-content candidate-details-page">
        {!isSSC && <BreadCrumbs element="Candidates" elementName={fullName} path="/candidates" />}
        <div className="candidate-details-page__main-info">
          <CandidateDetails showAssignPopup={showAssignPopup} candidateData={candidateData} isSSC={isSSC} />
          <Card className={cn('candidate-job-experience', { 'is-self-submitted': isSSC })}>
            {!isSSC && (
              <Tabs
                activeTab={tab}
                onTabClick={setTab}
                items={TABS_OPTIONS}
                className="candidate-job-experience__tabs"
              />
            )}
            <div className="candidate-job-experience__content">
              {tab === TABS_OPTIONS[0].key && <ExperienceTabContent candidateData={candidateData} />}
              {tab === TABS_OPTIONS[1].key && <JobAssignedTabContent jobs={jobs} />}
            </div>
          </Card>
        </div>
        {!isSSC && (
          <Timeline
            deleteReminder={deleteReminder}
            deleteInteraction={deleteInteraction}
            completeReminder={completeReminder}
            candidateData={candidateData}
            hrManagers={props.hrManagers}
            jobs={jobs}
          />
        )}
      </div>
      <PopupReassignCandidate
        candidate={candidateData}
        open={assignPopup}
        getCandidateInfo={props.getCandidateInfo}
        onAssign={() => {
          if (isSSC) {
            history.push(`/candidates/${candidateData.uuid}/show`);
          } else {
            props.getJobs(id);
            props.getCandidateInfo(id);
            props.getTimeline(id, undefined, true);
          }
          closeAssignPopup();
        }}
        onClose={closeAssignPopup}
        candidateJobs={jobs}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    hrManagers: getHrManagers(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCandidateInfo: bindActionCreators(actions.getCandidateInfo, dispatch),
    deleteReminder: bindActionCreators(actions.deleteReminder, dispatch),
    completeReminder: bindActionCreators(actions.completeReminder, dispatch),
    getReminders: bindActionCreators(actions.getReminders, dispatch),
    getJobs: bindActionCreators(actions.getJobs, dispatch),
    getRespRecruiters: bindActionCreators(actions.getResponsibleRecruiter, dispatch),
    getListHrManagers: bindActionCreators(actions.getListHrManagers, dispatch),
    clearCandidateData: bindActionCreators(clearCandidateData, dispatch),
    getTimeline: bindActionCreators(actions.getTimeline, dispatch),
    deleteInteraction: bindActionCreators(actions.deleteInteraction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfile);
