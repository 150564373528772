import React, { useMemo } from 'react';
import './passing-step.scss';
import cn from 'classnames';
import { Select, AtsSkeleton } from '_atoms';
import { MixedCharts } from './mixed-charts';
import { AnalyticsEmpty } from '../AnalyticsEmpty/analytics-empty';
import Empty from '../../../dist/images/common/icons/empty-analytics.jpg';

export const PassingStep = React.memo(({ options, from, to, setTo, setFrom, loading }) => {
  const changeValue = (data, type) => {
    if (type === 'from') {
      if (data.index === from.index) return;
      setFrom(data);
    } else {
      if (data.index <= from.index || data.index === to.index) return;
      setTo(data);
    }
  };

  const totalTimeHandler = value => {
    let result;
    if (value) result = value;
    else {
      result = options
        .filter(item => item.index >= from.index && item.index <= to.index)
        .reduce((sum, current) => sum + current.data, 0);
    }
    let days = '';
    let hours = '';
    if (result > 24) {
      days = `${Math.floor(result / 24)}d`;
      hours = `${result % 24}h`;
    } else {
      days = '';
      hours = `${result}h`;
    }

    return days + hours;
  };

  const yData = item => {
    if (item.index === from.index) return item.data;
    return options
      .filter((option, index) => index <= item.index && index >= from.index)
      .reduce((sum, current) => sum + current.data, 0);
  };

  const areaData = useMemo(
    () => ({
      tooltip: options.map(item => ({
        name: item.label,
        data: [
          { name: 'Time:', data: totalTimeHandler(yData(item)) },
          {
            name: 'Steps:',
            data: item.label === from.label ? from.label : `${from.label} - ${item.label}`,
          },
        ],
      })),
      data: options.map(item => {
        const nothing = item.index < from.index || item.index > to.index;
        if (nothing) return { x: item.index, y: null };
        return {
          x: item.index,
          y: yData(item),
        };
      }),
    }),
    [options, from, to]
  );

  const barData = useMemo(
    () => ({
      tooltip: options.map(item => ({
        name: item.label,
        data: [
          { name: 'Time:', data: totalTimeHandler(item.data) },
          { name: 'Step:', data: item.label },
        ],
      })),
      data: options.map(item => item.data),
    }),
    [options]
  );

  const totalTime = useMemo(() => totalTimeHandler(), [options, from, to]);

  const fromOptions = useMemo(() => options?.filter(item => item.index < to.index), [to, from]);

  const toOptions = useMemo(() => options?.filter(item => item.index > from.index), [to, from]);

  const empty = useMemo(() => !!options.filter(item => !!item.data).length, [options]);

  return (
    <div id="step" className={cn('passing-step shadow', { loading })}>
      {loading ? <AtsSkeleton height={30} width={217} /> : <span className="title">Time of passing steps</span>}
      <div className="select-block">
        {loading ? (
          <>
            <AtsSkeleton height={60} width={270} />
            <AtsSkeleton height={60} width={270} />
          </>
        ) : empty ? (
          <>
            <Select label="From step" value={from} options={fromOptions} onChange={data => changeValue(data, 'from')} />
            <Select label="To step" value={to} options={toOptions} onChange={data => changeValue(data, 'to')} />
          </>
        ) : null}
      </div>
      {!loading && empty ? (
        <div className="total-legend">
          <div className="total">
            Total time: <span>{totalTime}</span>
          </div>
          <div className="legends">
            <div>
              <span className="line" />
              Total time
            </div>
            <div>
              <span className="bar" />
              Step time
            </div>
          </div>
        </div>
      ) : null}
      {loading ? (
        <AtsSkeleton height={282} />
      ) : empty ? (
        <MixedCharts areaData={areaData} barData={barData} />
      ) : (
        <AnalyticsEmpty text="There is no Time of passing steps" img={Empty} />
      )}
    </div>
  );
});
