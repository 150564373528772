import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { Button, BUTTON_TYPES, Popup, Opening, AtsSkeleton, Card, Typography } from '_atoms';
import { PopupCloseDeclineOpening } from '_molecules';
import history from 'helpers/history';
import { deleteOpening, getOpenings, closeOpening as closeOpeningAPI, getOpeningsLevelsAPI } from 'actions';
import { setOpeningsList } from 'store/reducers/jobReducer';
import { getOpeningsLoading } from 'store/selectors/commonSelectors';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from 'constants/text';
import { ReactComponent as Image } from 'dist/emptyStates/dashboardOpening.svg';
import { JobOpeningsTabFilters } from './JobOpeningsTabFilters';
import { FILTER_OPTIONS, SORTING_OPTIONS } from './constants';
import './style.scss';

export const JobOpeningsTab = ({ showAddOpening, approveDeclineOpening, openingsList, getListLocations, jobName }) => {
  const params = new URLSearchParams(window.location.search);
  const idScrollTo = params.get('scroll');
  const defaultStatuses = params.get('statuses');
  const [scrollTo, setScrollTo] = useState('');
  const ref = useRef(null);
  const { id: jobId } = useParams();
  const [closeOpening, setCloseOpening] = useState(undefined);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [currentOpening, setCurrentOpening] = useState({});
  const [levelOptions, setLevelOptions] = useState([]);
  const [filters, setFilters] = useState({
    only_my_openings: false,
    sorting: SORTING_OPTIONS[0],
    statuses: defaultStatuses?.split(',').map(value => ({ value, label: value })) || [
      FILTER_OPTIONS[0],
      FILTER_OPTIONS[1],
      FILTER_OPTIONS[2],
    ],
    level: [],
  });
  const loading = useSelector(getOpeningsLoading);
  const dispatch = useDispatch();

  const openingsCount = useMemo(() => openingsList.length, [openingsList]);

  const getFilters = () => ({
    status: filters.statuses.map(s => s.value).join(','),
    only_my_openings: filters.only_my_openings,
    sorting_field: 'expected',
    sorting_order: filters.sorting.value,
    level: filters.level
      .filter(s => !s.isCustom)
      .map(s => s.value)
      .join(','),
    custom_level: filters.level
      .filter(s => s.isCustom)
      .map(s => s.value)
      .join(','),
  });

  const onChangeFilters = (field, value) => {
    if (field === 'sorting') {
      const sorting = filters.sorting === SORTING_OPTIONS[0] ? SORTING_OPTIONS[1] : SORTING_OPTIONS[0];
      setFilters(f => ({ ...f, sorting }));
    } else {
      setFilters(f => ({ ...f, [field]: value }));
    }
  };

  const removeOpening = uuid => {
    dispatch(deleteOpening(jobId, uuid, getFilters()));
  };

  const onCloseOpening = closure_comment => {
    dispatch(closeOpeningAPI(jobId, closeOpening.uuid, { closure_comment }, getFilters()));
    setCloseOpening(undefined);
  };

  const updateOpeningsList = () => {
    dispatch(getOpenings(jobId, getFilters()));
  };

  const onRemoveOpening = data => {
    setCurrentOpening(data);
    setOpenConfirmation(true);
  };

  const onApproveDeclineOpening = (opening, decline) => approveDeclineOpening(opening, decline, getFilters());

  const onOpeningAdd = () => history.push(`/jobs/${jobId}/create-opening`);

  const onConfirmDeleteOpening = () => {
    removeOpening(currentOpening.uuid);
    setOpenConfirmation(false);
    setCurrentOpening({});
  };
  const onCloseDeleteOpening = () => setOpenConfirmation(false);

  const onCloseCloseOpeningPopup = () => setCloseOpening(undefined);

  const onRemoveHighlight = () => setScrollTo('');

  useEffect(() => {
    getOpeningsLevelsAPI(jobId)
      .then(resp => {
        setLevelOptions(
          resp.map(r => ({
            label: r.level,
            value: r.level,
            isCustom: r.is_custom,
          }))
        );
      })
      .catch(() => {
        setLevelOptions([]);
      });
    getListLocations();
    return () => dispatch(setOpeningsList([]));
  }, []);

  useEffect(() => {
    if (scrollTo && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [scrollTo]);

  const onRefChange = React.useCallback(node => {
    if (node) {
      ref.current = node;
      setScrollTo(idScrollTo);
      history.replace({ search: '' });
    }
  }, []);

  useEffect(updateOpeningsList, [
    filters.only_my_openings,
    filters.statuses,
    filters.level,
    filters.sorting,
    filters.levels,
  ]);

  return (
    <div className="openings-list">
      <JobOpeningsTabFilters
        count={openingsCount}
        filters={filters}
        onChangeFilters={onChangeFilters}
        levelOptions={levelOptions}
      />
      {!loading ? (
        openingsList.length > 0 ? (
          openingsList.map(opening => (
            <div
              key={opening.uuid}
              className="openings-list__opening-wrapper"
              ref={idScrollTo === opening.uuid ? onRefChange : null}
              onBlur={idScrollTo === opening.uuid ? onRemoveHighlight : null}
            >
              <Opening
                className={cn('openings-list__opening', { highlight: scrollTo === opening.uuid })}
                key={opening.uuid}
                closeOpening={setCloseOpening}
                removeOpening={onRemoveOpening}
                approveDeclineOpening={onApproveDeclineOpening}
                opening={opening}
                editable
                jobName={jobName}
              />
            </div>
          ))
        ) : (
          <Card className="empty-opening">
            <Image className="empty-opening__image" />
            {showAddOpening ? (
              <>
                <span className="empty-opening__title">Create opening to start hiring process</span>
                <Button type={BUTTON_TYPES.PRIMARY} className="empty-opening__add-opening" onClick={onOpeningAdd}>
                  {BUTTON_TEXT.ADD_OPENING}
                </Button>
              </>
            ) : (
              <>
                <span className="empty-opening__title">No openings yet</span>
                <span className="empty-opening__text">Opening defines the need to hire a specialist</span>
              </>
            )}
          </Card>
        )
      ) : (
        <div className="opening-loading">
          <AtsSkeleton height={346} />
        </div>
      )}
      <Popup
        open={openConfirmation}
        title={POPUP_LABELS.DELETE_OPENING}
        cancelButtonProps={{ onClick: onCloseDeleteOpening, children: BUTTON_TEXT.CANCEL }}
        okButtonProps={{ onClick: onConfirmDeleteOpening, children: BUTTON_TEXT.DELETE }}
      >
        <div className="popup-delete-opening">
          <Typography.Text className="popup-delete-opening__title">{currentOpening.level}</Typography.Text>
          <Typography.Caption className="popup-delete-opening__subtitle">
            {'Expected on '}
            {currentOpening.expected && moment(currentOpening.expected, 'YYYY-MM-DD').format('MMM DD, YYYY')}
          </Typography.Caption>
          <Typography.Text className="popup-delete-opening__text">{POPUP_BODY.DELETE_OPENING}</Typography.Text>
        </div>
      </Popup>
      <PopupCloseDeclineOpening
        open={closeOpening}
        opening={closeOpening}
        jobName={jobName}
        onClose={onCloseCloseOpeningPopup}
        confirm={onCloseOpening}
        close
      />
    </div>
  );
};
