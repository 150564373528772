import React, { useState } from 'react';
import cn from 'classnames';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import CharacterCount from '@tiptap/extension-character-count';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';

import { ReactComponent as BoldIcon } from 'dist/icons/bold.svg';
import { ReactComponent as ItalicIcon } from 'dist/icons/italic.svg';
import { ReactComponent as OrderedListIcon } from 'dist/icons/ordered-list.svg';
import { ReactComponent as UnorderedListIcon } from 'dist/icons/unordered-list.svg';
import './style.scss';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="rich-area-default__menu">
      <BoldIcon
        className={cn('rich-area__icon bold', { active: editor.isActive('bold') })}
        onClick={() => editor.chain().focus().toggleBold().run()}
      />
      <ItalicIcon
        className={cn('rich-area__icon italic', { active: editor.isActive('italic') })}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      />
      <div className="divider" />
      <UnorderedListIcon
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={cn('rich-area__icon bulletList', { active: editor.isActive('bulletList') })}
      />
      <OrderedListIcon
        className={cn('rich-area__icon orderedList', { active: editor.isActive('orderedList') })}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      />
    </div>
  );
};

export const RichArea = ({
  className,
  initialValue = '',
  onChange,
  placeholder = 'Placeholder',
  maxLength = 2000,
  error,
  disabled,
  optional,
  autofocus = false,
  ...props
}) => {
  const [count, setCount] = useState(0);
  const onChangeValue = ({ editor: e }) => {
    const text = e.getText().replace(/(\r\n|\n|\r)/gm, '');
    if (text.length) {
      onChange(e.getHTML(), e.getText());
    } else {
      onChange('');
    }
    setCount(text.length);
  };
  const editor = useEditor(
    {
      editable: props.disabled,
      autofocus,
      extensions: [
        StarterKit,
        CharacterCount.configure({
          limit: maxLength,
        }),
      ],
      content: initialValue,
      onUpdate: onChangeValue,
      onCreate: onChangeValue,
    },
    [maxLength, autofocus, initialValue, disabled]
  );

  return (
    <div className={cn('rich-area-default', { error }, { disabled }, className)}>
      <Typography.Label weight={TYPOGRAPHY_WEIGHT.BOLD}>
        {props.required ? `${props.label} *` : props.label}
        {optional && <span className="optional"> (optional)</span>}
      </Typography.Label>
      <div className="rich-area-default__rich-area">
        <MenuBar editor={editor} />
        {!count && <div className="placeholder">{placeholder}</div>}
        <EditorContent className="rich-area-default__rich-content" editor={editor} />
      </div>
      <Typography.Caption className="rich-area-default__counter">
        {count}/{maxLength}
      </Typography.Caption>
    </div>
  );
};
