import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { rootReducer, initialState } from './reducers';

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== 'production',
});
