import React from 'react';

export const Seventh = () => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23" cy="23" r="23" fill="#F5F6F7" />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="46" height="46">
      <circle cx="23" cy="23" r="23" fill="#F5F6F7" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2256 30.6494C27.0325 31.4215 26.8395 33.1974 27.1484 34.0081C27.2642 34.3169 33.2094 36.5947 33.9043 36.8649C35.3713 37.444 36.6839 38.0617 37.2244 38.6794C37.8421 39.3743 38.3054 44.9172 38.3054 46.5C26.299 46.5 20.0063 46.5 8 46.5C8 44.9172 8.46327 39.3743 9.08096 38.6794C9.62144 38.0617 10.934 37.4826 12.401 36.8649C13.0959 36.5947 19.0412 34.3169 19.157 34.0081C19.4659 33.1974 19.2728 31.4215 19.0798 30.6494C23.9827 28.1014 22.3613 28.0628 27.2256 30.6494Z"
        fill="#AF9D97"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.7312 34.7801C28.7312 34.7801 27.0712 37.135 25.7972 37.2508C23.8669 37.4439 23.4037 36.8262 22.1297 35.5522C21.0487 34.5098 19.7747 33.0814 19.3501 32.1163C19.3501 32.1163 19.1185 30.186 19.0026 29.8772C18.8482 29.5683 22.593 28.1785 22.593 28.1785L26.3763 28.1013L27.2642 30.6107C27.2642 30.6107 26.801 32.9656 27.187 33.9694L28.7312 34.7801Z"
        fill="#997E75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0637 26.2932C17.0637 26.2932 15.9055 26.6407 15.0948 25.6755C14.2841 24.7104 12.7785 20.1935 16.2916 20.8112L17.0637 26.2932Z"
        fill="#AB9790"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1559 26.4091C29.1559 26.4091 30.314 26.7565 31.1248 25.7914C31.9355 24.8262 33.4411 20.3094 29.928 20.9271L29.1559 26.4091Z"
        fill="#AB9790"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2103 30.6264C23.8421 33.9263 22.8202 33.9263 19.4142 30.6264C17.1813 28.4265 16.765 26.1235 16.6136 23.408C16.4623 20.9674 15.7432 17.5988 17.2192 15.9833C19.5277 13.474 27.0967 13.474 29.4053 15.9833C30.8812 17.5988 30.1243 20.9674 30.0108 23.408C29.8594 26.1235 29.4431 28.4265 27.2103 30.6264Z"
        fill="#AF9D97"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7211 23.4753H16.6091L16.3388 19.1515C16.3388 19.1515 15.6439 14.4416 23.9055 14.6732C23.9055 14.6732 29.2331 14.8276 29.8122 18.5724L29.7736 23.2823L30.4298 23.3209L31.0861 20.9659C31.0861 20.9659 31.7424 18.3408 31.0475 15.7156C29.8508 11.4304 25.8744 10.0406 21.9366 10.4266C17.9602 10.8127 15.5667 13.0518 14.949 16.7579C14.7174 18.3794 14.9104 20.3869 15.7211 23.4753Z"
        fill="#424242"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.461 34.6644C30.2754 35.4751 33.4025 36.6718 33.9043 36.8649C35.3713 37.444 36.6839 38.0616 37.2244 38.6793C37.8421 39.3742 38.3054 44.9172 38.3054 46.5C26.299 46.5 20.0063 46.5 8 46.5C8 44.9172 8.46327 39.3742 9.08096 38.6793C9.62144 38.0616 10.934 37.4826 12.401 36.8649C12.9029 36.6718 16.0686 35.4365 17.883 34.6644C18.8096 36.8263 20.817 38.3705 23.1334 38.3705C25.5269 38.3319 27.5344 36.8263 28.461 34.6644Z"
        fill="#288840"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8718 17.7232C14.8718 17.7232 14.4085 12.164 18.1147 9.46163C21.5506 6.91366 23.0562 8.45789 25.3725 4.09546C25.9516 3.0145 28.6154 11.4305 29.928 13.3608C31.2792 15.3683 31.2792 16.7967 31.2792 16.7967L24.6004 13.1678L20.5854 12.8975L16.8021 15.2525L14.8718 17.7232Z"
        fill="#424242"
      />
    </g>
  </svg>
);
