import React from 'react';
import classNames from 'classnames';
import { Tabs as TabsAntd } from 'antd';
import './Tabs.scss';

export const Tabs = ({ className, tabs, onChange, activeTab, ...props }) => (
  <TabsAntd
    className={classNames('tabs-default', className)}
    activeKey={activeTab}
    onChange={onChange}
    items={tabs}
    {...props}
  />
);
