import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { subject } from '@casl/ability';
import { Button, BUTTON_TYPES, Tooltip, User, AtsSkeleton, Typography } from '_atoms';
import { ReactComponent as Info } from 'dist/icons/info.svg';
import { ACTIONS, Can, UI } from 'permission';
import { getApprovers, getSteps, setApprover } from 'actions';
import { PopupEditApprovers } from '../PopupEditApprovers';
import './style.scss';

export const Approvers = ({ list, loading, requestOptions, defaultApprover, responsibleRecruiter, rejected }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const onCloseEditPopup = useCallback(() => setOpenPopup(false), []);
  const openEdipApprovers = () => setOpenPopup(true);

  const saveApprovers = approversId => {
    setApprover(params.id, params.step, params.candidate, approversId)
      .then(() => {
        dispatch(getSteps(params.id, true));
        dispatch(getApprovers(params.id, params.step, params.candidate));
      })
      .finally(onCloseEditPopup);
  };
  return (
    <div className="approvers-list">
      <div className="approvers-list__header">
        <div className="title">
          <Typography.Title2>Approvers</Typography.Title2>
          <Tooltip label="People who will make decision about the candidate">
            <Info />
          </Tooltip>
        </div>
        <Can I={ACTIONS.READ} a={subject(UI.EDIT_APPROVERS, { is_default: defaultApprover, rejected })}>
          <Button type={BUTTON_TYPES.GHOST} onClick={openEdipApprovers} disabled={loading}>
            edit approvers
          </Button>
        </Can>
      </div>
      <div className="approvers-list__content">
        {loading ? (
          <AtsSkeleton height={84} width={704} />
        ) : (
          list.map(el => (
            <User
              key={el.uuid}
              fullName={
                <>
                  {el.user.full_name}
                  {el.optional && <span className="optional-tag">(Optional)</span>}
                </>
              }
              avatar={el.user.profile.avatar}
              roles={el.user.roles}
            />
          ))
        )}
      </div>
      <PopupEditApprovers
        open={openPopup}
        onClose={onCloseEditPopup}
        onSave={saveApprovers}
        approvers={list}
        responsibleRecruiter={responsibleRecruiter}
        requestOptions={requestOptions}
      />
    </div>
  );
};
