import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import cn from 'classnames';
import { ROLES } from 'permission';
import { Checkbox, Select, Table, Tabs, Typography, TYPOGRAPHY_WEIGHT } from '_atoms';
import { TableMobile } from '_atoms/Table/Table';
import { PLACEMENTS, SelectSmall } from '_atoms/SelectSmall';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { NewJobs, SelfSubmittedCandidatesDashboard } from '_organisms';
import { getUser } from 'store/selectors/authorizeSelectors';
import useScreenResize from 'helpers/useScreenResize';
import { useQuery } from 'helpers/useLocation';
import { getListUsersByRole, getRecruiterLeadCandidatesTableDashboardAPI } from 'actions';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import { ReactComponent as JobImage } from 'dist/emptyStates/dashboardOpening.svg';
import { CANDIDATES_TABLES_MAPPERS } from './utils';
import { CANDIDATES_GROUP, CANDIDATES_TABLE_COLUMNS, INITIAL_VALUES, TABS } from './constants';
import './style.scss';

export const DashboardRecruiterLead = () => {
  const query = useQuery();

  const [tab, setTab] = useState(query.tab || TABS[0].key);

  return (
    <div className="dashboard-recruiter-lead">
      <div className="dashboard-recruiter-lead__banner-wrapper">
        <div className="top-banner" />
        <NewJobs />
      </div>
      <Tabs className="dashboard-recruiter-lead__tabs" activeTab={tab} onChange={setTab} items={TABS} />
      {
        {
          [TABS[0].key]: <MyActiveCandidates />,
          [TABS[1].key]: <SelfSubmittedCandidatesDashboard dashboard="recruiter-lead" />,
        }[tab]
      }
    </div>
  );
};

const MyActiveCandidates = () => {
  const tableRef = useRef(null);

  const user = useSelector(getUser);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES);
  const [count, setCount] = useState(0);
  const [grouping, setGrouping] = useState(CANDIDATES_GROUP[0]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [recruiters, setRecruiters] = useState([{ value: user.uuid, label: user.full_name }]);
  const [recruitersOptions, setRecruitersOptions] = useState([]);

  const onChangeFilter = (field, item) => {
    if (field === 'page') {
      setTableOptions(s => ({ ...s, page: item, offset: (item - 1) * s.limit.value }));
    } else if (field === 'limit') {
      setTableOptions(s => ({ ...s, limit: item, offset: 0, page: 1 }));
    } else if (field === 'only_with_actions') {
      setTableOptions(s => ({ ...s, only_with_actions: item, offset: 0, page: 1 }));
    } else {
      setTableOptions(s => ({ ...s, [field]: item }));
    }
  };
  const setSorting = sort => onChangeFilter('sorting', sort);
  const onChangeWithActions = e => onChangeFilter('only_with_actions', e.target.checked);

  const onExpand =
    ({ uuid }) =>
    () => {
      if (!expandedRowKeys.includes(uuid)) setExpandedRowKeys(rows => [...rows, uuid]);
      else setExpandedRowKeys(rows => rows.filter(r => r !== uuid));
    };

  const onGroupingChange = newGrouping => {
    setGrouping(g => ({ ...newGrouping, tableData: g.tableData }));
  };

  const getTableData = () => {
    setLoading(true);
    getRecruiterLeadCandidatesTableDashboardAPI(
      {
        limit: tableOptions.limit.value,
        offset: tableOptions.offset,
        sorting_field: tableOptions.sorting.field,
        sorting: tableOptions.sorting.order,
        only_with_actions: tableOptions.only_with_actions,
        recruiters: recruiters.map(r => r.value),
      },
      grouping.value
    )
      .then(d => {
        setTableData([]);
        setTableOptions(o => ({ ...o, itemsCount: d.count }));
        setCount(d.count);
        setGrouping(g => ({ ...g, tableData: g.value }));
        setTableData(CANDIDATES_TABLES_MAPPERS[grouping.value](d.results));
        setExpandedRowKeys(d.results.map((_, index) => index));
      })
      .catch(() => {
        setTableData([]);
        setCount(0);
        setGrouping(g => ({ ...g, tableData: g.value }));
        setTableOptions(INITIAL_VALUES);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getListUsersByRole({
      role: [ROLES.RECRUITER, ROLES.RECRUITER_LEAD],
      my_recruiters_group: true,
    })
      .then(users => {
        setRecruitersOptions(users.map(u => ({ value: u.uuid, label: u.full_name })));
      })
      .catch(() => {
        setRecruitersOptions([]);
      });
  }, []);

  useEffect(getTableData, [
    tableOptions.sorting,
    tableOptions.limit,
    tableOptions.offset,
    tableOptions.page,
    tableOptions.only_with_actions,
    grouping.value,
    recruiters,
  ]);
  useScreenResize('md', tableRef);

  return (
    <Table
      innerRef={tableRef}
      rowKey="uuid"
      className={cn('active-candidates', grouping.tableData)}
      cardTitle="Active candidates"
      cardCount={count}
      emptyComponent={<ActiveCandidatesTableEmpty />}
      extra={
        <div className="active-candidates__header-extra">
          <Select
            className="recruiters-filter"
            mode={SELECT_TYPES.MULTIPLE}
            value={recruiters}
            onChange={setRecruiters}
            options={recruitersOptions}
            optionFilterProp="label"
          />
          <Checkbox
            label="Show only with action"
            isSelected={tableOptions.only_with_actions}
            onChange={onChangeWithActions}
          />
          <div className="filter-container">
            <SelectSmall
              label="Group by"
              options={CANDIDATES_GROUP}
              value={grouping}
              onChange={onGroupingChange}
              placement={PLACEMENTS.BOTTOM_RIGHT}
            />
          </div>
        </div>
      }
      columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][0](tableOptions.sorting, setSorting, user.uuid)}
      loading={loading}
      data={tableData}
      limit={tableOptions.limit}
      page={tableOptions.page}
      itemsCount={tableOptions.itemsCount}
      tooltipInfo="One candidate may be mentioned more than once (for example, if assigned to more than one job)"
      updateParams={onChangeFilter}
      expandable={
        grouping.tableData && {
          expandedRowKeys,
          expandedRowRender: ({ candidates }) => (
            <>
              <Table
                rowKey="uuid"
                className={cn('active-candidates__sub-table', grouping.tableData)}
                columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][1](user.uuid)}
                data={candidates}
                showCardHeader={false}
                showCardFooter={false}
              />
              <TableMobile
                className={cn('active-candidates__sub-table active-candidates__sub-table-mobile', grouping.tableData)}
                data={candidates}
                columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][1](user.uuid)}
              />
            </>
          ),
          expandIcon: ({ expanded, record }) => (
            <div role="none" className="expanded-row">
              <div className="expanded-row__info">
                <Vector className={cn('expanded-row__icon', { expanded })} onClick={onExpand(record)} />
                <Typography.Text className="name">
                  {record.name}
                  <span>{record.count}</span>
                </Typography.Text>
                {!!record.extra && <Typography.Text className="extra">{record.extra}</Typography.Text>}
              </div>
            </div>
          ),
        }
      }
      defaultTableMobile={!grouping.tableData}
    />
  );
};

const ActiveCandidatesTableEmpty = () => (
  <div className="active-candidates__empty">
    <JobImage />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You have no active candidates here</Typography.Title3>
    <Typography.Text>We’ll let you know if any shows up or try another filter</Typography.Text>
  </div>
);
