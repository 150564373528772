import React, { useMemo } from 'react';
import cn from 'classnames';
import { Avatar } from 'antd';
import { Tooltip } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as AvatarEmpty } from 'dist/icons/avatar-placeholder.svg';
import './style.scss';

export const User = ({ avatar, fullName, roles = [], className }) => {
  const rolesToDisplay = useMemo(() => [...new Set(roles.map(r => r.name).sort())].join(', '), [roles]);
  return (
    <div className={cn('user-default', className)}>
      <Avatar src={avatar || <AvatarEmpty className="empty" />} className="user-default__avatar" />
      <Tooltip
        label={
          <div className="user-default__tooltip">
            <Typography.Caption weight={TYPOGRAPHY_WEIGHT.BOLD}>{fullName}</Typography.Caption>
            <Typography.Caption>{rolesToDisplay}</Typography.Caption>
          </div>
        }
      >
        <Typography.Text
          className={cn('user-default__name', { 'no-roles': !roles.length })}
          weight={TYPOGRAPHY_WEIGHT.BOLD}
        >
          {fullName}
        </Typography.Text>
        <Typography.Caption className="user-default__roles">{rolesToDisplay}</Typography.Caption>
      </Tooltip>
    </div>
  );
};
