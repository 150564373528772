import React from 'react';
import { ReactComponent as Phone } from 'dist/icons/socialNetworks/phone.svg';
import { ReactComponent as Email } from 'dist/icons/socialNetworks/email.svg';
import { ReactComponent as Skype } from 'dist/icons/socialNetworks/skype.svg';
import { ReactComponent as LinkedIn } from 'dist/icons/socialNetworks/linkedin.svg';
import { ReactComponent as Facebook } from 'dist/icons/socialNetworks/facebook.svg';
import { ReactComponent as Instagram } from 'dist/icons/socialNetworks/instagram.svg';
import { ReactComponent as Link } from 'dist/icons/socialNetworks/link.svg';

export const Icon = ({ type, ...props }) => {
  switch (type) {
    case 'Phone':
      return <Phone {...props} />;
    case 'Email':
      return <Email {...props} />;
    case 'Skype':
      return <Skype {...props} />;
    case 'LinkedIn':
      return <LinkedIn {...props} />;
    case 'Facebook':
      return <Facebook {...props} />;
    case 'Instagram':
      return <Instagram {...props} />;
    case 'Link':
      return <Link {...props} />;
    default:
      return <Link {...props} />;
  }
};
