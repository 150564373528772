import React, { useMemo } from 'react';
import cn from 'classnames';
import { AtsSkeleton, Card } from '_atoms';
import Empty from 'dist/images/common/icons/empty-analytics.jpg';
import { BarGraphic } from './bar';
import { AnalyticsEmpty } from '../AnalyticsEmpty/analytics-empty';
import './rejection.scss';

export const Rejection = React.memo(({ options, loading }) => {
  const yData = useMemo(() => options.map(option => option.value), [options]);
  const rejections = useMemo(() => !!options.reduce((sum, current) => sum + current.value, 0), [options]);

  return (
    <Card className={cn('rejection', { loading })}>
      {loading ? <AtsSkeleton height={30} width={217} /> : <span className="title">Number of rejections</span>}
      {loading ? (
        <AtsSkeleton height={368} />
      ) : rejections ? (
        <div className="graphic">
          <BarGraphic data={yData} options={options} />
        </div>
      ) : (
        <AnalyticsEmpty text="There are no Rejections" img={Empty} />
      )}
    </Card>
  );
});
