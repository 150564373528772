import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { notification, NOTIFICATION_TYPES } from '_atoms';
import history from 'helpers/history';
import { AbilityContext, ACTIONS, UI } from 'permission';
import * as actions from 'actions';
import { getUser } from 'store/selectors/authorizeSelectors';
import { clearCandidatesJob, setCandidateExists } from 'store/reducers/jobReducer';
import {
  getIsCandidateExistsOnJob,
  getJobActivityListLoading,
  getJobApproversLoading,
  getJobCandidateDataLoading,
  getJobEntryDateLoading,
  getJobInterviewLoading,
  getJobLoader,
  getJobOfferingLoading,
} from 'store/selectors/jobSelectors';
import { JobStepsPanel } from '_molecules';
import { StepsLeftPanel, StepsRightPanel } from '_organisms';
import './style.scss';

class JobStepsTab extends Component {
  constructor(props) {
    super(props);
    const activeStep = props.activeStep ? props.steps.find(v => v.uuid === props.activeStep).ordering_number : 0;
    this.state = {
      activeStep,
      candidateSearch: '',
      onlyMyCandidates: false,
      statuses: this.props.steps[activeStep].assignments_statuses.map(({ name }) => ({ value: name, label: name })),
    };
    this.setActiveStep = this.setActiveStep.bind(this);
    this.actionWithdraw = this.actionWithdraw.bind(this);
    this.assignToStep = this.assignToStep.bind(this);
    this.submitDecision = this.submitDecision.bind(this);
    this.submitDecisionWithOppening = this.submitDecisionWithOppening.bind(this);
    this.createInterview = this.createInterview.bind(this);
    this.updateCandidateResolution = this.updateCandidateResolution.bind(this);
    this.submitOffer = this.submitOffer.bind(this);
    this.cancelInterview = this.cancelInterview.bind(this);
    this.approveRR = this.approveRR.bind(this);
  }

  componentDidMount() {
    history.push(
      `/jobs/${this.props.id}/show/steps/${this.props.steps[this.state.activeStep].uuid}${
        this.props.candidate ? `/${this.props.candidate}` : ''
      }`
    );
  }

  componentWillUnmount() {
    this.props.setCandidateExists(false);
  }

  setActiveStep(value, candidateId) {
    if (Object.values(this.props.loader).find(loading => loading) || value === this.state.activeStep) return;
    this.props.updateData('activeStep', value);
    const statuses = this.props.steps[value].assignments_statuses.map(({ name }) => ({ value: name, label: name }));
    this.setState({
      activeStep: value,
      statuses,
      candidateSearch: '',
    });
    history.push(
      `/jobs/${this.props.id}/show/steps/${this.props.steps[value].uuid}${candidateId ? `/${candidateId}` : ''}`
    );
  }

  updateCandidateResolution(data) {
    this.props.updateCandidateResolution(this.props.id, this.props.candidate, data);
  }

  updateList(activeStep, candidate) {
    Promise.all(
      this.state.statuses.map(status =>
        this.props.getCandidatesJobs(this.props.id, this.props.steps[activeStep].uuid, true, {
          limit: 5,
          offset: 0,
          status: status.value,
          only_my_candidates: this.state.onlyMyCandidates,
          search: this.state.candidateSearch,
          user_uuid: this.props.candidate,
        })
      )
    ).then(resp => {
      const data = resp.map(el => el.results).flat();
      const candidateId = candidate || this.props.candidate || data[0]?.candidate.uuid;
      if (resp && window.location.pathname.includes('show/steps')) {
        this.props.setCandidateExists(true);
        history.push(
          `/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}${
            candidateId ? `/${candidateId}` : ''
          }`
        );
      }
    });
  }

  actionWithdraw(activeStep, comment) {
    this.props
      .actionWithdraw(this.props.id, this.props.steps[activeStep].uuid, this.props.userData.uuid, { comment })
      .then(() => {
        this.props.getSteps(this.props.id);
        this.setState({ candidateSearch: '' });
        history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}`);
        this.updateList(activeStep);
      });
  }

  assignToStep(activeStep, step) {
    const candidateId = this.props.candidate;
    this.props
      .assignToStep(this.props.id, this.props.steps[activeStep].uuid, this.props.userData.uuid, { step: step.uuid })
      .then(() => {
        history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}`);
        this.props.getSteps(this.props.id);
        const statuses = step.assignments_statuses.map(({ name }) => ({ value: name, label: name }));
        this.setState({ activeStep: step.ordering_number, statuses }, () => {
          history.push(`/jobs/${this.props.id}/show/steps/${step.uuid}/${candidateId}`);
        });
      });
  }

  submitDecision(activeStep, finalDecision, body) {
    if (!body.reason) {
      delete body.reason;
    }
    this.props
      .submitDecision(this.props.id, this.props.steps[activeStep].uuid, this.props.userData.uuid, body, finalDecision)
      .then(() => {
        this.props.getSteps(this.props.id).then(() => {
          const candidateId = this.props.candidate;
          history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}`);
          if (body.positive) {
            const newStepId = this.props.steps[activeStep + 1].uuid;
            const statuses = this.props.steps
              .find(({ uuid }) => uuid === newStepId)
              .assignments_statuses.map(({ name }) => ({ value: name, label: name }));
            this.setState({ statuses, activeStep: activeStep + 1 });
            history.push(`/jobs/${this.props.id}/show/steps/${newStepId}${candidateId ? `/${candidateId}` : ''}`);
          } else {
            this.setState({ candidateSearch: '' });
            history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}`);
            this.updateList(activeStep);
          }
        });
      });
  }

  submitDecisionWithOppening(activeStep, data) {
    this.props
      .submitDecisionWithOppening(this.props.id, this.props.steps[activeStep].uuid, this.props.userData.uuid, data)
      .then(() => {
        this.props.setCandidateExists(false);
        this.props.getSteps(this.props.id).then(() => {
          if (data.positive) {
            if (activeStep === 6) {
              history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}`);
              this.updateList(activeStep);
            } else {
              const candidateId = this.props.candidate;
              const newStepId = this.props.steps[activeStep + 1].uuid;
              const statuses = this.props.steps
                .find(({ uuid }) => uuid === newStepId)
                .assignments_statuses.map(({ name }) => ({ value: name, label: name }));
              this.setState({ statuses, activeStep: activeStep + 1 });
              history.push(`/jobs/${this.props.id}/show/steps/${newStepId}${candidateId ? `/${candidateId}` : ''}`);
            }
          } else {
            this.setState({ candidateSearch: '' });
            history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}`);
            this.updateList(activeStep);
          }
        });
      });
  }

  createInterview(activeStep, body, candidateName) {
    const newInterview = !body.uuid;
    const candidateId = this.props.candidate;

    this.props
      .createInterview(this.props.id, this.props.steps[activeStep].uuid, this.props.userData.uuid, body)
      .then(() => {
        this.props.getSteps(this.props.id, !newInterview).then(() => {
          if (newInterview) {
            notification({
              type: NOTIFICATION_TYPES.SUCCESS,
              message: `Interview for ${candidateName} has been created on ${moment(
                `${body.date} ${body.from_time}`
              ).format('ddd MMM DD, YYYY H:mm')} - ${moment(`${body.date} ${body.to_time}`).format('H:mm')}.`,
            });
            const statuses = this.props.steps
              .find(({ uuid }) => uuid === this.props.steps[activeStep].uuid)
              .assignments_statuses.map(({ name }) => ({ value: name, label: name }));
            this.setState({ statuses });
            history.push(
              `/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}${
                candidateId ? `/${candidateId}` : ''
              }`
            );
            this.updateList(activeStep);
            this.props.getCandidateStepAssignment(
              this.props.id,
              this.props.candidate,
              false,
              this.props.steps[activeStep].uuid,
              activeStep
            );
          }
          this.props.getActivityList(this.props.id, this.props.userData.uuid);
        });
      })
      .catch(() => {});
  }

  submitOffer(activeStep, data) {
    const candidateId = this.props.candidate;
    this.props
      .submitOffer(this.props.id, this.props.steps[activeStep].uuid, this.props.userData.uuid, data)
      .then(() => {
        history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}`);
        this.props.getSteps(this.props.id).then(() => {
          this.setState({ candidateSearch: '' });
          history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[activeStep].uuid}/${candidateId}`);
          this.updateList(activeStep);
        });
      });
  }

  cancelInterview(uuidToRemove) {
    this.props
      .deleteInterview(
        this.props.id,
        this.props.steps[this.state.activeStep].uuid,
        this.props.userData.uuid,
        uuidToRemove
      )
      .then(() => {
        const candidateId = this.props.candidate;
        history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[this.state.activeStep].uuid}`);
        this.props.clearCandidatesJob();
        const statuses = this.props.steps
          .find(({ uuid }) => uuid === this.props.steps[this.state.activeStep].uuid)
          .assignments_statuses.map(({ name }) => ({ value: name, label: name }));
        this.setState({ statuses });
        this.updateList(this.state.activeStep, candidateId);
        this.props.getCandidateStepAssignment(
          this.props.id,
          candidateId,
          false,
          this.props.steps[this.state.activeStep].uuid,
          this.state.activeStep
        );
      });
  }

  approveRR() {
    this.props.approveRR(this.state.activeStep);
  }

  render() {
    const stepData = this.props.steps.length ? this.props.steps[this.state.activeStep] : '';
    return (
      <div className="job-steps">
        <JobStepsPanel activeStep={this.state.activeStep} steps={this.props.steps} setActiveStep={this.setActiveStep} />
        <div className="job-steps_wrapperInfo">
          <StepsLeftPanel
            userData={this.props.userData}
            getCandidatesJobs={this.props.getCandidatesJobs}
            activeStepLabels={this.props.steps[this.state.activeStep].assignments_statuses}
            setActiveStep={stepId => {
              const activeStep = this.props.steps.findIndex(s => s.uuid === stepId);
              this.setActiveStep(activeStep, this.props.candidate);
            }}
            candidates={this.props.candidates}
            candidateSearch={this.state.candidateSearch}
            onlyMyCandidates={this.state.onlyMyCandidates}
            statuses={this.state.statuses}
            updateParams={(field, value) => this.setState({ [field]: value })}
          />
          <StepsRightPanel
            {...this.props}
            step={stepData}
            updateCandidateResolution={this.updateCandidateResolution}
            hasAccessToActions={this.context.can(ACTIONS.READ, UI.ACTIONS_TL_SA)}
            activeStep={this.state.activeStep}
            submitOffer={data => {
              this.submitOffer(this.state.activeStep, data);
            }}
            submitDecisionWithOppening={data => {
              this.submitDecisionWithOppening(this.state.activeStep, data);
            }}
            submitDecision={(finalDecision, body) => {
              this.submitDecision(this.state.activeStep, finalDecision, body);
            }}
            actionWithdraw={comment => {
              this.actionWithdraw(this.state.activeStep, comment);
            }}
            assignToStep={step => {
              this.assignToStep(this.state.activeStep, step);
            }}
            createInterview={(body, name) => {
              this.createInterview(this.state.activeStep, body, name);
            }}
            interview={this.props.interview}
            approveRR={this.approveRR}
            userData={this.props.userData}
            approversCandidate={this.props.approversCandidate}
            empty={!this.props.candidate || !this.props.isCandidateExistsOnJob}
            loading={this.props.candidateDataLoading}
            cancelInterview={this.cancelInterview}
          />
        </div>
      </div>
    );
  }
}
JobStepsTab.contextType = AbilityContext;

function mapStateToProps(store) {
  return {
    loader: getJobLoader(store),
    interview_loading: getJobInterviewLoading(store),
    approversCandidateLoading: getJobApproversLoading(store),
    activityListLoading: getJobActivityListLoading(store),
    offeringCandidateLoading: getJobOfferingLoading(store),
    user: getUser(store),
    candidateDataLoading: getJobCandidateDataLoading(store),
    isCandidateExistsOnJob: getIsCandidateExistsOnJob(store),
    entryDateLoading: getJobEntryDateLoading(store),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getApprovers: bindActionCreators(actions.getApprovers, dispatch),
    actionWithdraw: bindActionCreators(actions.actionWithdraw, dispatch),
    submitDecision: bindActionCreators(actions.submitDecision, dispatch),
    assignToStep: bindActionCreators(actions.assignToStep, dispatch),
    createInterview: bindActionCreators(actions.createInterview, dispatch),
    updateCandidateResolution: bindActionCreators(actions.updateCandidateResolution, dispatch),
    submitDecisionWithOppening: bindActionCreators(actions.submitDecisionWithOppening, dispatch),
    submitOffer: bindActionCreators(actions.submitOffer, dispatch),
    deleteInterview: bindActionCreators(actions.deleteInterview, dispatch),
    setCandidateExists: bindActionCreators(setCandidateExists, dispatch),
    clearCandidatesJob: () => dispatch(clearCandidatesJob()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobStepsTab);
