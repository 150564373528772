import moment from 'moment';
import { candidateLevels, levelOptions } from 'constants/constants';

export const EMPLOYMENT_TYPE_OPTIONS = [
  { value: 'Full-time', label: 'Full time' },
  { value: 'Part-time', label: 'Part time' },
  { value: 'Hourly', label: 'Hourly' },
];

export const WORK_EXPERIENCE_OPTIONS = [
  { value: '1', label: '1' },
  { value: '1-3', label: '1-3' },
  { value: '3-5', label: '3-5' },
  { value: 'Custom', label: 'Custom' },
];

export const PRIORITY_OPTIONS = [
  { value: 'Minor', label: 'Minor' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Critical', label: 'Critical' },
];

export const LEVEL_OPTIONS = levelOptions.map(value => ({ value, label: value }));

export const INITIAL_VALUES = {
  job_name: '',
  priority: PRIORITY_OPTIONS[1],
  expected: moment().add(45, 'days'),
  offices: [],
  foreign_languages: [{ language: undefined, level: undefined }],
  employment: EMPLOYMENT_TYPE_OPTIONS[0].value,
  work_experience: WORK_EXPERIENCE_OPTIONS[0].value,
  experience_level: candidateLevels[0],
  requirements_responsibilities: '',
  requirements_responsibilities_initial: '',
  comment: '',
  comment_initial: '',
};

export const EXPECTED_DATES = {
  [candidateLevels[0].value]: moment().add(45, 'days'),
  [candidateLevels[1].value]: moment().add(1, 'month'),
  [candidateLevels[2].value]: moment().add(1, 'month'),
  [candidateLevels[3].value]: moment().add(45, 'days'),
  [candidateLevels[4].value]: moment().add(2, 'month'),
  [candidateLevels[5].value]: moment().add(3, 'month'),
  [candidateLevels[6].value]: moment().add(1, 'month'),
};

const validator = (_, value) => {
  if (/^\d+$/.test(value) && +value > 0) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Should be in range 1 - 99'));
};

const wordExpValidator = (_, value) => {
  if (/^(?:[1-9][0-9]?|99)(?:\+)?$/.test(value) || /^(?:[1-9][0-9]?|99)-(?:[1-9][0-9]?|99)$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Should be in range 1 - 99'));
};

const richAreaMinLength = length => (_, value) => {
  if (value.replace(/<[^>]*>|\n|\t/g, '').length >= length) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`Minimum ${length} characters allowed`));
};

export const RULES = {
  TEAM_LEAD: [{ required: true, message: 'Please select Team Lead' }],
  PRIORITY: [{ required: true, message: 'Please select Priority of opening' }],
  EXPECTED: [{ required: true, message: 'Please enter Expected date' }],
  OFFICES: [{ required: true, message: 'Please select Office location' }],
  EXPERIENCE_LEVEL: [{ required: true, message: 'Please select Experience level' }],
  EXPERIENCE_LEVEL_CUSTOM: [{ required: true, message: 'Please enter custom level' }],
  RESPONSIBILITIES: [{ required: true, message: 'Please enter Requirements/Responsibilities' }],
  EXP_TYPE: [{ required: true, message: 'Should be in range 1 - 99', validator }],
  WORK_EXP: [{ required: true, message: 'Should be in range 1 - 99', validator: wordExpValidator }],
  RICHAREA_MIN_LENGTH: len => [
    { required: true, message: `Minimum ${len} characters allowed`, validator: richAreaMinLength(len) },
  ],
};
