import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AvatarLabel, Select, Popup } from '_atoms';
import { Typography } from '_atoms/Typography';
import { getUsersByRole } from '_molecules/PopupAssignRecruiters/PopupAssignRecruiters';
import { fetchWrapper } from 'helpers/helpers';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ACTIONS, ROLES, UI, useAbility } from 'permission';
import { TEXT } from './constants';
import './styles.scss';

const getRecruiters = body =>
  fetchWrapper({
    url: 'users/',
    body,
  }).catch(() => []);

export const PopupReassignRecruiter = ({ open, onClose, onConfirm, recruitersReassignFrom }) => {
  const ability = useAbility();
  const user = useSelector(getUser);

  const [recruitersOptions, setRecruitersOptions] = useState([]);
  const [reassignedRecruiters, setReassignedRecruiters] = useState({});

  const assignRecruiterLead = ability.can(ACTIONS.READ, UI.ASSIGN_RECRUITER_LEAD);
  const isRL = user.active_role.name === ROLES.RECRUITER_LEAD;

  const cancelButtonProps = {
    onClick: onClose,
    children: 'Cancel',
  };

  const okButtonProps = {
    onClick: () => onConfirm(reassignedRecruiters),
    children: 'Reassign',
    disabled: Object.keys(reassignedRecruiters).length !== recruitersReassignFrom.length,
  };

  const onSelectNewRecruiter = (oldRecruiter, newRecruiter) => {
    setReassignedRecruiters(r => ({
      ...r,
      [oldRecruiter]:
        assignRecruiterLead || isRL
          ? recruitersOptions
              .reduce((options, option) => [...options, ...option.options], [])
              .find(option => option.value === newRecruiter)
          : recruitersOptions.find(option => option.value === newRecruiter),
    }));
  };

  useEffect(() => {
    if (open) {
      getRecruiters({
        role: assignRecruiterLead ? [ROLES.RECRUITER_LEAD, ROLES.RECRUITER] : ROLES.RECRUITER,
        exclude_uuid__in: recruitersReassignFrom.map(r => r.uuid).join(','),
        my_recruiters_group: isRL,
      }).then(resp => {
        const listOptions = [];
        const recruiterLeadOptions = assignRecruiterLead ? getUsersByRole(resp, ROLES.RECRUITER_LEAD) : [];
        if (isRL && !recruitersReassignFrom.map(r => r.uuid).includes(user.uuid))
          recruiterLeadOptions.push({ value: user.uuid, label: user.full_name });
        const recruiterOptions = getUsersByRole(
          resp,
          ROLES.RECRUITER,
          recruiterLeadOptions.map(u => u.value)
        );
        if (recruiterLeadOptions.length)
          listOptions.push({ label: ROLES.RECRUITER_LEAD, options: recruiterLeadOptions });
        if (recruiterOptions.length) listOptions.push({ label: ROLES.RECRUITER, options: recruiterOptions });
        const newApproversOptions = assignRecruiterLead || isRL ? listOptions : getUsersByRole(resp);
        setRecruitersOptions(newApproversOptions);
      });
    } else {
      setRecruitersOptions([]);
      setReassignedRecruiters({});
    }
  }, [open]);

  return (
    <Popup open={open} title={TEXT.TITLE} cancelButtonProps={cancelButtonProps} okButtonProps={okButtonProps}>
      <div className="popup-reassign-recruiter">
        <Typography.Text className="popup-reassign-recruiter__subtitle">{TEXT.SUBTITLE}</Typography.Text>
        {recruitersReassignFrom.map((r, idx) => (
          <div key={r.full_name + idx.toString()} className="popup-reassign-recruiter__recruiter-item">
            <AvatarLabel name={r.full_name} src={r.avatar} extraName={ROLES.RECRUITER} />
            <Select
              className="popup-reassign-recruiter__select"
              options={recruitersOptions}
              label={TEXT.LABEL}
              value={reassignedRecruiters[r.uuid]}
              onChange={({ value }) => onSelectNewRecruiter(r.uuid, value)}
              inPopup
            />
          </div>
        ))}
      </div>
    </Popup>
  );
};
