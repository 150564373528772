import React from 'react';
import cn from 'classnames';
import { Form, Upload as UploadAntd } from 'antd';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as UploadImg } from 'dist/icons/Upload_new.svg';
import { ReactComponent as File } from 'dist/icons/uploaded_file.svg';
import { ReactComponent as Bin } from 'dist/icons/bin.svg';
import { normFile } from './utils';
import './style.scss';

const itemRender = (_, file, _fileList, { remove }) => (
  <div className="upload-default__item">
    <File className="file-icon" />
    <Typography.Text>{file.name}</Typography.Text>
    <div role="none" className="remove-file" onClick={remove}>
      <Bin />
    </div>
  </div>
);

export const Upload = ({ className, label, children, itemProps, ...props }) => (
  <div className={cn('upload-default', className)}>
    <Typography.Label weight={TYPOGRAPHY_WEIGHT.BOLD}>{label}</Typography.Label>
    <Form.Item getValueFromEvent={normFile} valuePropName="fileList" {...itemProps}>
      <UploadAntd.Dragger
        className="upload-default__dragger"
        customRequest={() => {}}
        action={false}
        beforeUpload={() => false}
        itemRender={itemRender}
        {...props}
      >
        {children || (
          <div className="upload-default__content">
            <UploadImg className="upload-icon" />
            <p>
              Drop files, or <span className="browse">browse</span>
            </p>
          </div>
        )}
      </UploadAntd.Dragger>
    </Form.Item>
  </div>
);
