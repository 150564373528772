export const JOB_STATUSES = {
  DRAFT: 'Draft',
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED: 'Approved',
  OPEN: 'Open',
  ARCHIVED: 'Archived',
};

export const JOB_REJECTION_OPTIONS = {
  HIGH_MONEY_MOTIVATION: 'High money motivation',
  LACK_OF_SKILLS: 'Lack of skills / experience',
  LOCATION_RELOCATION: 'Location / Relocation',
  STOPPED_REPLYING: 'Stopped replying',
  OTHER: 'Other',
  BAD_RECOMMENDATIONS: 'Bad recommendations',
  LOW_TECHNICAL_LEVEL: 'Low technical level',
  JUNIOR_LEVEL_SPECIALIST: 'Junior level specialist',
  NO_SOFT_SKILLS: 'No soft skills',
  NO_BAD_ENGLISH: 'No / bad English',
  STAY_AT_CURRENT_COMPANY: 'Stay at current company',
  ANOTHER_COMPANYS_JOB_ACCEPTED: 'Another Company’s JO accepted',
  WITHOUT_EXPLANATION: 'Without explanation',
  DIDNT_SHOW_UP_TO_INTERVIEW: 'Didn’t show up to interview',
  PERSONAL_REASONS: 'Personal reasons',
  REFUSED_TO_DO_TASK: 'Refused to do a test task',
  LOW_SALARY_OFFERED: 'Low salary offered',
  INTERESTED_IN_OTHER_COMPANYS_PROJECT: 'Project (interested in other Company’s project)',
  DIDNT_SHOW_UP: 'Didn’t show up',
};

export const JOB_SUBMIT_OPTIONS = {
  APPROVE: 'Approve',
  REJECT: 'Reject',
  REJECTED_BY_CANDIDATE: 'Rejected by Candidate',
  REJECTED_BY_MANAGEMENT: 'Rejected by Management',
  REJECTED_BY_TEAM_LEAD: 'Rejected by Team Lead',
};

export const INTERVIEW_TYPES = ['Offline', 'Google Meet', 'Skype', 'Other'];
