import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { Divider } from 'antd';
import { subject } from '@casl/ability';
import { AtsButtonMenu, Button, BUTTON_TYPES, Card, RichText, User } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { Priority } from '_atoms/Priority';
import { getUser } from 'store/selectors/authorizeSelectors';
import history from 'helpers/history';
import { dateFormatter } from 'helpers/dateFormatter';
import { BUTTON_TEXT } from 'constants/text';
import { OPENING_STATUS_TYPES, OPENING_STATUSES } from 'constants/opening';
import { ACTIONS, Can, ROLES, SUBJECTS, UI, useAbility } from 'permission';
import { useOpeningLabel } from './utils';
import './style.scss';

export const Opening = ({
  className,
  opening,
  removeOpening,
  closeOpening,
  approveDeclineOpening,
  editable,
  jobName,
  inResolution,
}) => {
  const ability = useAbility();
  const { id: jobId } = useParams();

  const user = useSelector(getUser);
  const isLmApprover = user.roles.find(r => r.name === ROLES.LOCATION_MANAGER)?.is_opening_approver;

  const [showMore, setShowMore] = useState(false);
  const onShowMore = () => setShowMore(s => !s);

  const showEdit = ability.can(ACTIONS.UPDATE, subject(SUBJECTS.OPENING, { status: opening.status })) && editable;
  const showClone = ability.can(ACTIONS.READ, UI.CLONE_OPENING) && editable;
  const showClose = showEdit && opening.status === OPENING_STATUSES.OPEN;
  const showDelete = ability.can(ACTIONS.DELETE, subject(SUBJECTS.OPENING, { status: opening.status })) && editable;

  const onApprove = () => approveDeclineOpening(opening, false);
  const onDecline = () => approveDeclineOpening(opening, true);

  const onEditOpening = openingId => () => history.push(`/jobs/${jobId}/create-opening/${openingId}`);
  const onCloneOpening = openingId => () => history.push(`/jobs/${jobId}/clone-opening/${openingId}`);

  const { label, label_desc, label_link } = useOpeningLabel(opening);

  const workExperience =
    opening.work_experience === 'Custom' ? opening.work_experience_custom_value : opening.work_experience;

  return (
    <Card className={cn('opening-default', { resolution: inResolution }, className)}>
      <div className="opening-default__header">
        <div>
          <div className="opening-default__header__main-info">
            <Priority priority={opening.priority} />
            <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>
              {`${opening.custom_level || opening.level} ${jobName}`}
            </Typography.Title2>
            <Status className="opening-default__status" type={OPENING_STATUS_TYPES[opening.status]}>
              {opening.status}
            </Status>
          </div>
          <Typography.Title3 className="opening-default__header__extra-info">
            {`Expected on ${dateFormatter(opening.expected)}`}
          </Typography.Title3>
        </div>
        <div>
          <div className="opening-default__buttons">
            <Can I={ACTIONS.READ} a={subject(UI.APPROVE_DECLINE, { status: opening.status, isApprover: isLmApprover })}>
              <Button onClick={onDecline}>{BUTTON_TEXT.DECLINE}</Button>
              <Button type={BUTTON_TYPES.PRIMARY} onClick={onApprove}>
                {BUTTON_TEXT.APPROVE}
              </Button>
            </Can>
            <AtsButtonMenu>
              {showEdit && (
                <li role="none" onClick={onEditOpening(opening.uuid)}>
                  Edit
                </li>
              )}
              {showClone && (
                <li role="none" onClick={onCloneOpening(opening.uuid)}>
                  Copy
                </li>
              )}
              {showClose && (
                <li role="none" onClick={() => closeOpening(opening)}>
                  Close
                </li>
              )}
              {showDelete && (
                <li role="none" className="active" onClick={() => removeOpening(opening)}>
                  Delete
                </li>
              )}
            </AtsButtonMenu>
          </div>
        </div>
      </div>
      {!!label && !!label_desc && (
        <div className="opening-default__label">
          {label}
          <span>
            {label_link ? (
              <a href={label_link} target="_blank" rel="noreferrer noopener">
                {label_desc}
              </a>
            ) : (
              label_desc
            )}
          </span>
        </div>
      )}
      <div className="opening-default__body">
        <User
          avatar={opening?.team_lead?.avatar}
          fullName={opening?.team_lead?.full_name}
          roles={opening?.team_lead?.roles}
          className="opening-default__team-lead"
        />
        <div className="opening-default__body__info">
          <div className="opening-default__body__main-info">
            <div className="info-block">
              <Typography.Label>Office location</Typography.Label>
              <Typography.Text>{opening.offices.map(o => o.name).join(', ')}</Typography.Text>
            </div>
            <div className="info-block">
              <Typography.Label>Company</Typography.Label>
              <Typography.Text>{opening.company}</Typography.Text>
            </div>
            <div className="info-block">
              <Typography.Label>Foreign language</Typography.Label>
              <Typography.Text>
                {opening.foreign_languages.length
                  ? opening.foreign_languages.map(l => (
                      <div key={l.language + l.level}>
                        {l.language}: {l.level}
                      </div>
                    ))
                  : '-'}
              </Typography.Text>
            </div>
            <div className="info-block">
              <Typography.Label>Employment type</Typography.Label>
              <Typography.Text>
                {opening.employment}
                {opening.employment === 'Hourly' && `, ${opening.employment_custom_value}`}
              </Typography.Text>
            </div>
            <div className="info-block">
              <Typography.Label>Required work experience</Typography.Label>
              <Typography.Text>{`${workExperience} year${workExperience % 10 !== 1 ? 's' : ''}`}</Typography.Text>
            </div>
          </div>
          <div className="opening-default__comment">
            <Typography.Label className="opening-default__body__extra-info__label">Comment</Typography.Label>
            <RichText text={opening.comment || '-'} />
          </div>
        </div>
      </div>
      <div className={cn('opening-default__body__extra-info', { hidden: !showMore })}>
        <Typography.Label className="opening-default__body__extra-info__label">
          Requirements/Responsibilities
        </Typography.Label>
        <RichText text={opening.requirements_responsibilities} opened />
        <Divider />
        <div className="opening-default__body__extra-info__footer">
          <div className="opening-default__body__extra-info__footer__persons">
            <div className="info-block">
              <Typography.Label>Creation date</Typography.Label>
              <Typography.Text>{dateFormatter(opening.created)}</Typography.Text>
            </div>
            {opening.sh_approval_date && (
              <div className="info-block">
                <Typography.Label>SH approval date</Typography.Label>
                <Typography.Text>{dateFormatter(opening.sh_approval_date)}</Typography.Text>
              </div>
            )}
            {opening.rm_approval_date && (
              <div className="info-block">
                <Typography.Label>RM approval date</Typography.Label>
                <Typography.Text>{dateFormatter(opening.rm_approval_date)}</Typography.Text>
              </div>
            )}
            {opening.lm_approval_date && (
              <div className="info-block">
                <Typography.Label>LM approval date</Typography.Label>
                <Typography.Text>{dateFormatter(opening.lm_approval_date)}</Typography.Text>
              </div>
            )}
            {opening.hrl_approval_date && (
              <div className="info-block">
                <Typography.Label>HRL approval date</Typography.Label>
                <Typography.Text>{dateFormatter(opening.hrl_approval_date)}</Typography.Text>
              </div>
            )}
          </div>
          <div>
            {opening.closed && (
              <div className="opening-default__body__extra-info__footer__persons">
                <div className="info-block">
                  <Typography.Label>Closed by</Typography.Label>
                  {opening.closed.user ? (
                    <User
                      className="opening-default__extra-user"
                      avatar={opening.closed.user.avatar}
                      fullName={opening.closed.user.full_name}
                      roles={opening.closed.user.roles}
                    />
                  ) : (
                    <Typography.Text>-</Typography.Text>
                  )}
                </div>
                <div className="info-block">
                  <Typography.Label>Closure date</Typography.Label>
                  <Typography.Text>{dateFormatter(opening.closed.date)}</Typography.Text>
                </div>
                {opening.closed.comment && (
                  <div className="info-block">
                    <Typography.Label>Closure comment</Typography.Label>
                    <Typography.Text>{opening.closed.comment}</Typography.Text>
                  </div>
                )}
                {opening.status === OPENING_STATUSES.HIRED && (
                  <div className="info-block">
                    <Typography.Label>Hired candidate</Typography.Label>
                    <a href={`/candidates/${opening.candidate.uuid}/show`} target="_blank" rel="noreferrer noopener">
                      {opening.candidate.full_name_latin || opening.candidate.full_name_local}
                    </a>
                  </div>
                )}
              </div>
            )}
            {opening.decline_reason && (
              <div className="info-block">
                <Typography.Label>Decline reason</Typography.Label>
                <Typography.Text>{opening.decline_reason}</Typography.Text>
              </div>
            )}
            {opening.status === OPENING_STATUSES.WAITING_FOR_ENTRY && (
              <>
                {opening.linked_data.linked_by && (
                  <div className="info-block">
                    <Typography.Label>Linked by</Typography.Label>
                    <User
                      className="opening-default__extra-user"
                      avatar={opening.linked_data.linked_by.avatar}
                      fullName={opening.linked_data.linked_by.full_name}
                      roles={opening.linked_data.linked_by.roles}
                    />
                  </div>
                )}
                {opening.linked_data.linked_at && (
                  <div className="info-block">
                    <Typography.Label>Linked at</Typography.Label>
                    <Typography.Text>{dateFormatter(opening.linked_data.linked_at)}</Typography.Text>
                  </div>
                )}
                <div className="info-block">
                  <Typography.Label>Linked candidate</Typography.Label>
                  <a href={`/candidates/${opening.candidate.uuid}/show`} target="_blank" rel="noreferrer noopener">
                    {opening.candidate.full_name_latin || opening.candidate.full_name_local}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Button type={BUTTON_TYPES.TEXT} className="opening-default__show-more" onClick={onShowMore}>
        {showMore ? 'Show less details' : 'Show more details'}
      </Button>
    </Card>
  );
};
