import React from 'react';
import cn from 'classnames';
import { ConfigProvider, DatePicker as DatePickerAntd, Form } from 'antd';
import moment from 'moment';
import 'moment/locale/en-gb';
import locale from 'antd/lib/locale/en_GB';
import { Typography } from '_atoms/Typography';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import { ReactComponent as Clear } from 'dist/icons/clear.svg';
import './style.scss';

const { RangePicker: RangePickerAntd } = DatePickerAntd;

export const RangePicker = ({ value, onChange, className, onOpenChange, label, itemProps, ...props }) => (
  <div className={cn('datepicker-default-wrapper', className)}>
    {label && <Typography.Label>{label}</Typography.Label>}
    <ConfigProvider locale={locale}>
      <Form.Item {...itemProps}>
        <RangePickerAntd
          className="datepicker-default datepicker-double"
          value={value}
          onChange={onChange}
          suffixIcon={null}
          prevIcon={<Vector />}
          nextIcon={<Vector className="rotate" />}
          clearIcon={<Clear />}
          allowClear
          separator={null}
          format="MMM DD, YYYY"
          placeholder={['MM/DD/YYYY', 'MM/DD/YYYY']}
          popupClassName="datepicker-default__calendar"
          showToday={false}
          disabledDate={d =>
            d < moment('01/01/2000', 'MM/DD/YYYY').startOf('day') || d < props.minDate || d > props.maxDate
          }
          allowEmpty={[true, true]}
          onOpenChange={onOpenChange}
          {...props}
        />
      </Form.Item>
    </ConfigProvider>
  </div>
);
