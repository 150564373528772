import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  user: null,
  permissionAssigned: false,
};

const authorizeSlice = createSlice({
  name: 'authorize',
  initialState,
  reducers: {
    setCurrentUserData(state, { payload }) {
      state.user = payload;
      state.user.active_role = payload.roles.find(({ pk }) => pk === payload.active_role);
    },
    setPermissionAssigned(state, { payload }) {
      state.permissionAssigned = payload;
    },
    resetAuthorizeReducer(state) {
      state = initialState;
    },
  },
});

export const { setCurrentUserData, setPermissionAssigned, resetAuthorizeReducer } = authorizeSlice.actions;
export default authorizeSlice.reducer;
