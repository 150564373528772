import { useState, useEffect, useRef, useMemo } from 'react';

export const useScreenType = screen => {
  const [screenType, setScreenType] = useState(
    window.innerWidth >= 1200 ? 'lg' : window.innerWidth >= 768 ? 'md' : 'sm'
  );

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setScreenType(screenWidth >= 1200 ? 'lg' : screenWidth >= 768 ? 'md' : 'sm');
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [screenType, screen === screenType];
};

export const useHTMLChanges = (element, callback) => {
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new MutationObserver(callback);
    if (element) {
      observer.observe(element, {
        childList: true,
        subtree: true,
        characterData: true,
      });
      observerRef.current = observer;
    }

    return () => {
      observer.disconnect();
    };
  }, [element, callback]);

  return observerRef.current;
};

const useScreenResize = (screen, ref, ignore) => {
  const [trigger, setTrigger] = useState(new Date().getTime());
  const tableContent = useMemo(() => ref.current?.querySelector('.ant-table-content'), [ref.current]);
  const [, isMd] = useScreenType(screen);

  const onMutation = () => setTrigger(new Date().getTime());

  useEffect(() => {
    if (ignore || !tableContent) return null;
    const innerTableContent = tableContent.querySelectorAll('.ant-table-expanded-row-level-1 .ant-table-content');
    const innerTableExpanders = tableContent.querySelectorAll('.expanded-row');
    const handleScroll = () => {
      if (isMd) {
        innerTableContent.forEach(c => {
          c.style.marginLeft = `${tableContent.scrollLeft}px`;
          c.scrollLeft = tableContent.scrollLeft;
        });
        innerTableExpanders.forEach(c => {
          c.style.marginLeft = `${tableContent.scrollLeft}px`;
          c.scrollLeft = tableContent.scrollLeft;
        });
      } else {
        innerTableContent.forEach(c => {
          c.style.marginLeft = 0;
          c.scrollLeft = 0;
        });
        innerTableExpanders.forEach(c => {
          c.style.marginLeft = 0;
          c.scrollLeft = 0;
        });
      }
    };
    const handleResize = () => {
      if (isMd) {
        const width = `${tableContent.getBoundingClientRect().width}px`;
        innerTableContent.forEach(c => {
          c.style.width = width;
        });
        innerTableExpanders.forEach(c => {
          c.style.width = width;
        });
      } else {
        innerTableContent.forEach(c => {
          c.style.width = '';
        });
        innerTableExpanders.forEach(c => {
          c.style.width = '';
        });
      }
      handleScroll();
    };
    const handleScrollInner = e => {
      if (isMd) {
        tableContent.scrollLeft = e.target.scrollLeft;
        innerTableContent.forEach(c => {
          c.style.marginLeft = `${e.target.scrollLeft}px`;
          c.scrollLeft = e.target.scrollLeft;
        });
        innerTableExpanders.forEach(c => {
          c.style.marginLeft = `${e.target.scrollLeft}px`;
          c.scrollLeft = e.target.scrollLeft;
        });
      } else {
        tableContent.scrollLeft = 0;
        innerTableContent.forEach(c => {
          c.style.marginLeft = 0;
          c.scrollLeft = 0;
        });
        innerTableExpanders.forEach(c => {
          c.style.marginLeft = 0;
          c.scrollLeft = 0;
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    tableContent.addEventListener('scroll', handleScroll);
    innerTableContent.forEach(c => {
      c.addEventListener('scroll', handleScrollInner);
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      tableContent.removeEventListener('scroll', handleScroll);
      innerTableContent.forEach(c => {
        c.removeEventListener('scroll', handleScrollInner);
      });
    };
  }, [isMd, ref, trigger]);
  useHTMLChanges(ref.current, onMutation);
};

export default useScreenResize;
