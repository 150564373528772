import React from 'react';
import cn from 'classnames';
import './style.scss';

export const Title1 = ({ children, className, weight, uppercase, ellipsis, ...props }) => (
  <div className={cn('typography typography-h1', weight, { uppercase }, { ellipsis }, className)} {...props}>
    {children}
  </div>
);

export const Title2 = ({ children, className, weight, uppercase, ellipsis, ...props }) => (
  <div className={cn('typography typography-h2', weight, { uppercase }, { ellipsis }, className)} {...props}>
    {children}
  </div>
);

export const Title3 = ({ children, className, weight, uppercase, ellipsis, ...props }) => (
  <div className={cn('typography typography-h3', weight, { uppercase }, { ellipsis }, className)} {...props}>
    {children}
  </div>
);

export const Title4 = ({ children, className, weight, uppercase, ellipsis, ...props }) => (
  <div className={cn('typography typography-h4', weight, { uppercase }, { ellipsis }, className)} {...props}>
    {children}
  </div>
);

export const Text = ({ children, className, weight, uppercase, ellipsis, ...props }) => (
  <div className={cn('typography typography-text', weight, { uppercase }, { ellipsis }, className)} {...props}>
    {children}
  </div>
);

export const Caption = ({ children, className, weight, uppercase, ellipsis, ...props }) => (
  <div className={cn('typography typography-caption', weight, { uppercase }, { ellipsis }, className)} {...props}>
    {children}
  </div>
);

export const Label = ({ children, className, weight, uppercase, ellipsis, ...props }) => (
  <div className={cn('typography typography-label', weight, { uppercase }, { ellipsis }, className)} {...props}>
    {children}
  </div>
);
export const NumbersLarge = ({ children, className, weight, uppercase, ellipsis, ...props }) => (
  <div className={cn('typography typography-numbers-large', weight, { uppercase }, { ellipsis }, className)} {...props}>
    {children}
  </div>
);

export const NumbersMedium = ({ children, className, weight, uppercase, ellipsis, ...props }) => (
  <div
    className={cn('typography typography-numbers-medium', weight, { uppercase }, { ellipsis }, className)}
    {...props}
  >
    {children}
  </div>
);
