export const DECLINED_COLLAPSED = [
  'Declined offer',
  "Don't have required skills",
  'Another candidate was hired',
  'Opening was closed',
  'Other',
  'High money motivation',
  'Lack of skills / experience',
  'No / bad English',
  'Location / Relocation',
  'Stay at current company',
  'Another Company’s JO accepted',
  'Without explanation',
  'Didn’t show up to interview',
  'Personal reasons',
  'Refused to do a test task',
  'Bad recommendations',
  'Low technical level',
  'Junior level specialist',
  'No soft skills',
  'Low salary offered',
  'Project (interested in other Company’s project)',
];

export const HIDE_PERCENTAGE = [...DECLINED_COLLAPSED, 'Left from the previous period'];
export const LAST_PERIOD = ['Waiting for decision', 'Waiting for interview', 'Waiting for candidate’s response'];

export const SUCCESS_TOOLTIPS = {
  SV_SUBMITTED: 'JOs Accepted / CVs Submitted',
  INTERVIEW_SCHEDULED: 'JOs Accepted / Interviews Scheduled',
  INTERVIEW_CONDUCTED: 'JOs Accepted / Interviews Conducted',
  JOS_MADE: 'JOs Accepted / JOs Made',
};

export const COLUMNS = [
  {
    title: 'Month/Transition',
    dataIndex: 'transition',
    key: 'transition',
    fixed: 'left',
    width: 240,
  },
];
