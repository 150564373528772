import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useAnalyticsTooltip } from '../helpers';

export const MixedCharts = ({ areaData, barData }) => {
  const tooltip = useAnalyticsTooltip({ bar: barData.tooltip, line: areaData.tooltip });

  const labels = [
    ['Contact with', 'candidate'],
    'CV screening',
    ['Technical', 'interview'],
    ['Management', 'interview'],
    ['Management', 'approval'],
    'Offer',
    'Job started',
  ];

  const data = {
    labels,
    datasets: [
      {
        type: 'bar',
        label: 'Step time',
        data: barData.data,
        backgroundColor: '#FC4C02',
        barPercentage: 0.4,
        borderRadius: {
          topLeft: 4,
          topRight: 4,
        },
      },
      {
        type: 'line',
        label: 'Total time',
        data: areaData.data,
        borderWidth: 1,
        backgroundColor: '#F67D37',
        borderColor: '#F67D37',
        fill: {
          value: 0,
          above: '#FFF1E5',
        },
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: tooltip,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: '#D5D5D8',
        },
        ticks: {
          color: '#FC4C02',
          font: {
            size: 14,
          },
          padding: 24,
        },
      },
      y: {
        ticks: {
          color: '#757575',
          font: {
            size: 14,
          },
          padding: 24,
        },
        grid: {
          borderDash: [7, 10],
          drawBorder: false,
          color: context => {
            if (!context.index) return 'transparent';
            return '#D5D5D8';
          },
        },
      },
    },
  };

  return (
    <div className="graphic">
      <Bar className="mixed" data={data} options={options} />
    </div>
  );
};
