import { useMemo } from 'react';
import { ANALYTICS_COLORS } from 'constants/colors';

export const useDonutData = reasons =>
  useMemo(() => {
    const { labels, data } = reasons.reduce(
      (p, o) => ({
        labels: [...p.labels, o.name],
        data: [...p.data, o.value],
      }),
      { labels: [], data: [] }
    );
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ANALYTICS_COLORS,
          borderWidth: 0,
        },
      ],
    };
  }, [reasons]);
