import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Tooltip, Card } from '_atoms';
import { Status } from '_atoms/Status';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import { ReactComponent as NoCandidates } from 'dist/emptyStates/stepsNoCandidates.svg';
import { ReactComponent as CheckMark } from 'dist/icons/checkmark.svg';
import { getCandidateStatusColor, getJobStatusColor } from './utils';

export const TableEmpty = () => (
  <div className="table-candidates__empty">
    <NoCandidates />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>No candidates found</Typography.Title3>
    <Typography.Text>Please try different search criteria</Typography.Text>
  </div>
);

const HeaderCellRender = ({ title, field, sort, setSorting, fields, hasMenu }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const order = sort.field === field || fields?.includes(sort.field) ? sort.order : null;

  const onClick = () => {
    if (fields && hasMenu) {
      setOpen(o => !o);
    } else {
      setSorting({ field, order: order === 'asc' ? 'desc' : 'asc' });
      setOpen(false);
    }
  };

  const onSortingClick =
    ({ order, field }) =>
    () => {
      setSorting({ field, order });
      setOpen(false);
    };

  const closeMenu = e => {
    if (ref.current && !ref.current.contains(e.target)) setOpen(false);
  };

  useEffect(() => {
    if (fields) document.addEventListener('mousedown', closeMenu);
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, [ref, fields]);

  return (
    <>
      {title}
      <Sort className={cn(order, { open })} onClick={onClick} />
      {fields && (
        <Card innerRef={ref} className={cn('sorting-list', { open })}>
          {CANDIDATE_SORT_OPTIONS.map(o => (
            <div key={o.field + o.order} className="sorting-list__option" role="none" onClick={onSortingClick(o)}>
              {o.label}
              {o.order === order && o.field === sort.field && <CheckMark className="checkmark" />}
            </div>
          ))}
        </Card>
      )}
    </>
  );
};

export const COLUMNS = (sort, setSorting, hasMenu) => [
  {
    title: (
      <HeaderCellRender
        title="Candidate"
        field="candidate"
        fields={['candidate', 'last_interaction_date']}
        sort={sort}
        setSorting={setSorting}
        hasMenu={hasMenu}
      />
    ),
    sorter: true,
    dataIndex: 'candidate',
    rowKey: 'candidate',
    width: 'auto',
    className: 'table-job__cell multi-row',
    render: data => (
      <Tooltip label={data.fullNameEn}>
        <Link to={`/candidates/${data.uuid}/show`}>
          {data.fullNameEn && <span className="main-name link">{data.fullNameEn}</span>}
          <span className={data.fullNameEn ? 'sub-name link' : 'main-name link'}>{data.fullNameRu}</span>
        </Link>
      </Tooltip>
    ),
  },
  {
    title: <HeaderCellRender title="Level" field="level" sort={sort} setSorting={setSorting} />,
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 104,
    className: 'table-job__cell',
  },
  {
    title: <HeaderCellRender title="Job & Step" field="job" sort={sort} setSorting={setSorting} />,
    sorter: true,
    dataIndex: 'job_step',
    rowKey: 'job',
    width: 208,
    className: 'table-job__cell multi-row',
    render: data => (
      <div>
        {data ? (
          <>
            <Tooltip label={data.jobName}>
              <span className="main-name">{data.jobName}</span>
            </Tooltip>
            <span className="sub-name">{data.jobStep}</span>
            <Status className="sub-status" type={getJobStatusColor(data.jobStepStatus)}>
              {data.jobStepStatus}
            </Status>
          </>
        ) : (
          <span className="main-name">-</span>
        )}
      </div>
    ),
  },
  {
    title: <HeaderCellRender title="Status" field="status" sort={sort} setSorting={setSorting} />,
    sorter: true,
    dataIndex: 'status',
    rowKey: 'status',
    width: 168,
    className: 'table-job__cell table-job__cell_status',
    render: data => (
      <div>
        <Status type={getCandidateStatusColor(data)}>{data}</Status>
      </div>
    ),
  },
];

export const TEXT = {
  TITLE: 'Candidates',
};

export const CANDIDATE_SORT_OPTIONS = [
  { order: 'asc', field: 'candidate', label: 'Ascending (A-Z)' },
  { order: 'desc', field: 'candidate', label: 'Descending (Z-A)' },
  { order: 'desc', field: 'last_interaction_date', label: 'New interaction first' },
  { order: 'asc', field: 'last_interaction_date', label: 'Old interaction first' },
];

export const INITIAL_TABLE_VALUES = {
  onlyInteracted: false,
  sorting: { key: 'candidate', order: 'asc', field: 'candidate' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};

export const INITIAL_VALUES = {
  search: '',
  status: [],
  country: [],
  city: [],
  experience_level: [],
  speciality: [],
  tags: [],
  companies: [],
  responsible_recruiters: [],
  sources: [],
  team_lead: [],
  service: [],
  jobs: [],
  job_step: [],
  last_interaction: [null, null],
  added_by: [],
  interview_date: [null, null],
  interviewed_by: [],
  added_during_date: [null, null],
};
