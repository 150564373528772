import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import dompurify from 'dompurify';
import { Button, BUTTON_TYPES } from '_atoms';
import './style.scss';

export const RichText = ({ text, className, opened }) => {
  const [open, setOpen] = useState(opened);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  const onLoadMore = () => setOpen(o => !o);

  useEffect(() => {
    setHeight(ref.current.scrollHeight);
  });
  return (
    <div className={cn('rich-area-default__rich-content rich-text__wrapper', className)}>
      <div
        ref={ref}
        className={cn('rich-text', { open })}
        dangerouslySetInnerHTML={{ __html: dompurify.sanitize(text) }}
      />
      {!opened && height > 110 && (
        <Button onClick={onLoadMore} type={BUTTON_TYPES.TEXT}>
          {open ? 'Show less' : 'Show more'}
        </Button>
      )}
    </div>
  );
};
