import { STATUS_TYPES } from '_atoms/Status';

export const getStatusType = status => {
  switch (status) {
    case 'Rejected':
    case 'Declined':
    case 'Job not started':
    case 'Fired':
      return STATUS_TYPES.RED;
    case 'Attend Interview':
    case 'Provide Feedback':
      return STATUS_TYPES.YELLOW;
    case 'Waiting for Resolution':
    case 'Pending Approval':
      return STATUS_TYPES.BLUE;
    case 'Hired':
      return STATUS_TYPES.GREEN;
    default:
      return STATUS_TYPES.GRAY;
  }
};
