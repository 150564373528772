import React, { useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

export const DoughnutGraphic = ({ data, count, options }) => {
  const [option, setOption] = useState(null);

  const doughnutOptions = useMemo(
    () => ({
      cutout: 82,
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: false,
          external: ({ tooltip }) => {
            setOption(tooltip.opacity ? options[tooltip.dataPoints[0].dataIndex] : null);
          },
        },
      },
    }),
    [options]
  );

  return (
    <div className="doughnut">
      <div className="doughnut__info">
        <span>{count}</span>
        Total rejections
      </div>
      <Doughnut data={data} options={doughnutOptions} />
      <div className="doughnut__tooltip">
        {!!option && (
          <div>
            <div className="title">{option.name}</div>
            {option.data.map(el => (
              <div>
                <span>{el.name}</span>
                <span>{el.data}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
