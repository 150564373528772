import { useRef, useState, useEffect } from 'react';

const useClickOutside = defaultState => {
  const ref = useRef(null);
  const [active, setActive] = useState(defaultState);

  const handleClickOutside = event => {
    if (!ref.current || !ref.current.contains(event.target)) setActive(false);
    else setActive(true);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  return [ref, active, handleClickOutside];
};

export default useClickOutside;
