import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Table, Select, Divider } from '_atoms';
import { TableMobile } from '_atoms/Table/Table';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import { PLACEMENTS, SelectSmall } from '_atoms/SelectSmall';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { PopupCloseDeclineOpening } from '_molecules';
import useScreenResize from 'helpers/useScreenResize';
import { ROLES } from 'permission';
import { declineApproveOpening, getAllCompanies, getLMOpeningsTableDashboardAPI } from 'actions';
import { getCompanies } from 'store/selectors/commonSelectors';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ReactComponent as JobImage } from 'dist/emptyStates/dashboardOpening.svg';
import {
  INITIAL_VALUES_1,
  OPENINGS_GROUP,
  OPENING_STATUSES_OPTIONS,
  OPENINGS_TABLES_MAPPERS,
  OPENINGS_TABLES_COLUMNS,
  OPENINGS_TABLES_COLUMNS_TOP,
  COMPANY_ALL,
} from './constants';
import './style.scss';

export const DashboardLocationManager = () => (
  <div className="dashboard-location-manager">
    <AllOpeningsService />
  </div>
);

const AllOpeningsService = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const user = useSelector(getUser);
  const isLmApprover = user.roles.find(r => r.name === ROLES.LOCATION_MANAGER)?.is_opening_approver;

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES_1);
  const [openingStatus, setOpeningStatus] = useState(OPENING_STATUSES_OPTIONS);
  const [count, setCount] = useState(0);
  const [company, setCompany] = useState(COMPANY_ALL);
  const [grouping, setGrouping] = useState(OPENINGS_GROUP[0]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [showOpeningDeclinePopup, setShowOpeningDeclinePopup] = useState({});

  const companies = useSelector(getCompanies);
  const companyOptions = useMemo(
    () => [COMPANY_ALL, ...companies.map(c => ({ value: c.uuid, label: c.name }))],
    [companies]
  );

  const onExpand =
    ({ uuid }) =>
    () => {
      if (!expandedRowKeys.includes(uuid)) setExpandedRowKeys(rows => [...rows, uuid]);
      else setExpandedRowKeys(rows => rows.filter(r => r !== uuid));
    };

  const onClickTitle =
    ({ uuid, name }) =>
    () => {
      if (grouping.value === OPENINGS_GROUP[0].value) {
        window.open(`/jobs?services=[{"value":"${uuid}","label":"${name}"}]`, '_blank', 'noopener noreferrer');
      } else if (grouping.value === OPENINGS_GROUP[1].value) {
        window.open(`/jobs/${uuid}/show/openings`, '_blank', 'noopener noreferrer');
      } else {
        window.open(`/jobs?offices=[{"value":"${uuid}","label":"${name}"}]`, '_blank', 'noopener noreferrer');
      }
    };

  const onChangeGrouping = newGrouping => {
    setGrouping(g => ({ ...newGrouping, tableData: g.tableData }));
  };

  const onChangeFilter = (field, item) => {
    if (field === 'page') {
      const options = { ...tableOptions, page: item, offset: (item - 1) * tableOptions.limit.value };
      setTableOptions(options);
      getTableData(options);
    } else if (field === 'limit') {
      const options = { ...tableOptions, limit: item, offset: 0, page: 1 };
      setTableOptions(options);
      getTableData(options);
    } else {
      const options = { ...tableOptions, [field]: item };
      setTableOptions(options);
      getTableData(options);
    }
  };

  const getTableData = (options = tableOptions) => {
    setLoading(true);
    getLMOpeningsTableDashboardAPI(
      {
        limit: options.limit.value,
        offset: options.offset,
        status: openingStatus.map(({ value }) => value).join(','),
        company: company.value,
      },
      grouping.value
    )
      .then(d => {
        setTableData([]);
        setTableOptions(o => ({ ...o, itemsCount: d.count }));
        setCount(d.results.reduce((c, { openings }) => c + openings.length, 0));
        setGrouping(g => ({ ...g, tableData: g.value }));
        setTableData(OPENINGS_TABLES_MAPPERS[grouping.value](d.results));
        setExpandedRowKeys(d.results.map(({ uuid }) => uuid));
      })
      .catch(() => {
        setTableData([]);
        setTableOptions(INITIAL_VALUES_1);
        setCount(0);
        setGrouping(g => ({ ...g, tableData: g.value }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOpeningAction = ({ job_uuid, service_uuid, ...d }, type) => {
    if (type === 'approve') {
      declineApproveOpening(job_uuid, service_uuid, type).then(() => {
        getTableData();
      });
    } else {
      setShowOpeningDeclinePopup({ ...d, job_uuid, service_uuid, type });
    }
  };

  const onCloseCloseOpeningPopup = () => setShowOpeningDeclinePopup({});
  const onDeclineOpening = reason => {
    const { job_uuid, service_uuid, type } = showOpeningDeclinePopup;
    declineApproveOpening(job_uuid, service_uuid, type, { reason }).then(() => getTableData());
    setShowOpeningDeclinePopup({});
  };

  useEffect(() => dispatch(getAllCompanies()), []);
  useEffect(getTableData, [
    tableOptions.limit,
    tableOptions.offset,
    tableOptions.page,
    openingStatus,
    grouping.value,
    company,
  ]);
  useScreenResize('md', tableRef);

  return (
    <>
      <Table
        innerRef={tableRef}
        rowKey="uuid"
        className={cn('all-openings', grouping.tableData)}
        cardTitle="Openings"
        cardCount={tableOptions.itemsCount}
        emptyComponent={<OpeningsTableEmpty />}
        tooltipInfo={
          grouping.tableData === OPENINGS_GROUP[2].tableData
            ? 'The same Opening may be mentioned more than once in case of multiple locations'
            : undefined
        }
        extra={
          <>
            <Select
              labelInValue
              options={OPENING_STATUSES_OPTIONS}
              value={openingStatus}
              onChange={setOpeningStatus}
              mode={SELECT_TYPES.MULTIPLE}
              clearIcon={<div />}
            />
            <div className="filter-selectors">
              <div className="filter-container">
                <SelectSmall
                  label="Company"
                  options={companyOptions}
                  value={company}
                  onChange={setCompany}
                  placement={PLACEMENTS.BOTTOM_RIGHT}
                />
              </div>
              <Divider type="vertical" />
              <div className="filter-container">
                <SelectSmall
                  label="Group by"
                  options={OPENINGS_GROUP}
                  value={grouping}
                  onChange={onChangeGrouping}
                  placement={PLACEMENTS.BOTTOM_RIGHT}
                />
              </div>
            </div>
          </>
        }
        showCardFooter={tableData.length}
        columns={
          isLmApprover
            ? OPENINGS_TABLES_COLUMNS_TOP[grouping.tableData]
            : OPENINGS_TABLES_COLUMNS_TOP[grouping.tableData].slice(0, -1)
        }
        loading={loading}
        data={tableData}
        limit={tableOptions.limit}
        page={tableOptions.page}
        itemsCount={tableOptions.itemsCount}
        updateParams={onChangeFilter}
        expandable={{
          expandedRowKeys,
          expandedRowRender: ({ openings }) => (
            <>
              <Table
                rowKey="uuid"
                className={cn('all-openings__sub-table', grouping.tableData)}
                columns={
                  isLmApprover
                    ? OPENINGS_TABLES_COLUMNS[grouping.tableData](onOpeningAction, isLmApprover)
                    : OPENINGS_TABLES_COLUMNS[grouping.tableData](onOpeningAction, isLmApprover).slice(0, -1)
                }
                data={openings}
                showCardHeader={false}
                showCardFooter={false}
              />
              <TableMobile
                className={cn('all-openings__sub-table all-openings__sub-table-mobile', grouping.tableData)}
                data={openings}
                columns={
                  isLmApprover
                    ? OPENINGS_TABLES_COLUMNS[grouping.tableData](onOpeningAction, isLmApprover)
                    : OPENINGS_TABLES_COLUMNS[grouping.tableData](onOpeningAction, isLmApprover).slice(0, -1)
                }
              />
            </>
          ),
          expandIcon: ({ expanded, record }) => (
            <div role="none" className="expanded-row">
              <div className="expanded-row__info">
                <Vector className={cn('expanded-row__icon', { expanded })} onClick={onExpand(record)} />
                <Typography.Text className="name" onClick={onClickTitle(record)}>
                  {record.name}
                  <span>{record.count}</span>
                </Typography.Text>
                {!!record.service && <Typography.Text className="name__extra">{record.service}</Typography.Text>}
              </div>
              <div className="expanded-row__extra">
                {record.candidates_count !== undefined && (
                  <Typography.Caption>Total active candidates: {record.candidates_count}</Typography.Caption>
                )}
                <Typography.Caption>Total openings: {record.openings_count}</Typography.Caption>
              </div>
            </div>
          ),
        }}
      />
      <PopupCloseDeclineOpening
        open={!!showOpeningDeclinePopup.opening}
        opening={showOpeningDeclinePopup.opening}
        jobName={showOpeningDeclinePopup.job_name}
        onClose={onCloseCloseOpeningPopup}
        confirm={onDeclineOpening}
      />
    </>
  );
};

const OpeningsTableEmpty = () => (
  <div className="all-openings__empty">
    <JobImage />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You have no openings here</Typography.Title3>
    <Typography.Text>We’ll let you know if any shows up or try another filter</Typography.Text>
  </div>
);
