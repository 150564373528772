import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useAnalyticsTooltip } from '../helpers';

export const BarGraphic = ({ data, options }) => {
  const tooltip = useAnalyticsTooltip(options);
  const chartData = {
    labels: [
      ['Contact with', 'candidate'],
      'CV screening',
      ['Technical', 'interview'],
      ['Management', 'interview'],
      ['Management', 'approval'],
      'Offer',
      'Job started',
    ],
    datasets: [
      {
        data,
        backgroundColor: '#FC4C02',
        barPercentage: 0.4,
        borderRadius: {
          topLeft: 4,
          topRight: 4,
        },
      },
    ],
  };

  return (
    <Bar
      data={chartData}
      options={{
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
            external: tooltip,
          },
          legend: { display: false },
        },
        scales: {
          x: {
            grid: {
              display: false,
              borderColor: '#e9e9e9',
            },
            ticks: {
              color: '#757575',
              font: {
                size: 14,
              },
              padding: 24,
            },
          },
          y: {
            ticks: {
              color: '#757575',
              font: {
                size: 14,
              },
              padding: 24,
            },
            grid: {
              borderDash: [7, 7],
              borderColor: 'transparent',
              color: () => '#D5D5D8',
            },
          },
        },
      }}
    />
  );
};
