import React, { useEffect } from 'react';
import moment from 'moment';
import { TextArea, Popup, Tooltip } from '_atoms';
import { Form } from 'antd';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { RULES } from '_templates/CreateOpening/constants';
import { TOOLTIP_TXT } from 'constants/constants';
import { BUTTON_TEXT, POPUP_LABELS } from 'constants/text';
import { ReactComponent as Info } from 'dist/icons/info.svg';
import './style.scss';

export const PopupCloseDeclineOpening = ({ open, onClose, confirm, close, opening = {}, jobName }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [open, form]);

  return (
    <Popup
      open={open}
      title={close ? POPUP_LABELS.CLOSE_OPENING : POPUP_LABELS.DECLINE_OPENING}
      cancelButtonProps={{ onClick: onClose, children: BUTTON_TEXT.CANCEL }}
      okButtonProps={{
        form: 'form-close-opening',
        htmlType: 'submit',
        children: close ? BUTTON_TEXT.CLOSE_OPENING : BUTTON_TEXT.DECLINE,
      }}
      className="mobile-full-size"
    >
      <div className="popup-close-opening">
        <Typography.Text className="popup-close-opening__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
          {`${opening.custom_level || opening.level} ${jobName}`}
        </Typography.Text>
        <Typography.Caption className="popup-close-opening__subtitle">
          {`Expected on ${moment(opening.expected).format('MMM DD, YYYY')}`}
        </Typography.Caption>
        <Form
          id="form-close-opening"
          form={form}
          onFinish={({ comment }) => confirm(comment)}
          validateTrigger="onSubmit"
        >
          <TextArea
            label={
              close ? (
                <>
                  Comment
                  <Tooltip label={TOOLTIP_TXT.MIN_10_CHARS}>
                    <Info />
                  </Tooltip>
                </>
              ) : (
                'Decline reason'
              )
            }
            placeholder="Add a comment"
            itemProps={{ name: 'comment', rules: close ? RULES.RICHAREA_MIN_LENGTH(10) : [] }}
          />
        </Form>
      </div>
    </Popup>
  );
};
