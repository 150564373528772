import { OPENING_STATUSES, OPENING_STATUSES_SHORT } from 'constants/opening';

export const SORTING_OPTIONS = [
  { value: 'desc', label: 'Newest first' },
  { value: 'asc', label: 'Oldest first' },
];

export const FILTER_OPTIONS = [
  { value: OPENING_STATUSES.OPEN, label: OPENING_STATUSES.OPEN },
  { value: OPENING_STATUSES.WAITING_FOR_ENTRY, label: OPENING_STATUSES.WAITING_FOR_ENTRY },
  {
    value: `${OPENING_STATUSES.SH_APPROVAL},${OPENING_STATUSES.HRL_APPROVAL},${OPENING_STATUSES.RM_APPROVAL},${OPENING_STATUSES.LM_APPROVAL}`,
    label: OPENING_STATUSES_SHORT.PENDING_APPROVAL,
  },
  { value: OPENING_STATUSES.CLOSED, label: OPENING_STATUSES.CLOSED },
  { value: OPENING_STATUSES.HIRED, label: OPENING_STATUSES.HIRED },
  { value: OPENING_STATUSES.DECLINED, label: OPENING_STATUSES.DECLINED },
];

export const TEXT = {
  TITLE: 'Openings',
};
