import mixpanel from 'mixpanel-browser/src/loader-module';

const TOKEN =
  window.location.origin.includes('localhost') || window.location.origin.includes('dev')
    ? '8a7ff5edade10bc9cc302bd9706653e2'
    : '0d0ceb318626bd04a162d2f0322b4b81';

const removeDuplicates = (myArr, prop) =>
  myArr.filter((obj, pos, arr) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);

export class MixpanelService {
  constructor() {
    mixpanel.init(TOKEN);
  }

  track(event, payload = {}) {
    mixpanel.track(event, payload);
  }

  login(profile) {
    this.identify(profile);
    this.setPeople(profile);
    mixpanel.track('Login');
  }

  setPeople(data) {
    const roles = removeDuplicates(data.roles, 'name')
      .map(v => v.name)
      .join(', ');
    mixpanel.people.set({
      $email: data.email,
      $name: data.full_name,
      USER_ID: data.uuid,
      roles,
    });
  }

  logout() {
    mixpanel.reset();
    mixpanel.track('Logout');
  }

  identify(profile) {
    mixpanel.identify(profile.uuid);
  }
}
