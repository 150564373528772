import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Login } from '_templates';
import { LOGIN_TYPES } from '_templates/Login/constants';
import history from 'helpers/history';
import { resetAuthorizeReducer } from 'store/reducers/authorizeReducer';
import { resetJobReducer } from 'store/reducers/jobReducer';
import { resetCommonReducer } from 'store/reducers/commonReducer';
import { resetCandidateReducer } from 'store/reducers/candidateReducer';
import { resetDashboardReducer } from 'store/reducers/dashboardReducer';
import { ROUTES } from 'router/constants';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    if (process.env.NODE_ENV !== 'production' || window.location.hostname === 'dev.ats.leverx-group.com') return;
    this.props.resetAuthReducer();
    this.props.resetCandidateReducer();
    this.props.resetCommonReducer();
    this.props.resetDashboardReducer();
    this.props.resetJobReducer();
    window.localStorage.removeItem('access_token');
    history.push(ROUTES.LOGIN, { error: LOGIN_TYPES.APP_CRASH });
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) return <Login clearError={() => this.setState({ hasError: false })} />;
    return this.props.children;
  }
}

const mapDispatchToProps = dispatch => ({
  resetAuthReducer: bindActionCreators(resetAuthorizeReducer, dispatch),
  resetCandidateReducer: bindActionCreators(resetCandidateReducer, dispatch),
  resetCommonReducer: bindActionCreators(resetCommonReducer, dispatch),
  resetDashboardReducer: bindActionCreators(resetDashboardReducer, dispatch),
  resetJobReducer: bindActionCreators(resetJobReducer, dispatch),
});

export default connect(undefined, mapDispatchToProps)(ErrorBoundary);
