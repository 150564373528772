import React from 'react';
import './analytics-empty.scss';
import cn from 'classnames';

export const AnalyticsEmpty = ({ img, text, title, className }) => (
  <div className={cn('analytics-empty', className)}>
    <img src={img} alt="empty" />
    {title && <span className="title">{title}</span>}
    <span>{text}</span>
  </div>
);
