import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { Button } from '../Button';
import { Points } from '../Icons';
import './style.scss';

export const AtsButtonMenu = ({ className, children, disabled, fixedPosition }) => {
  const menuRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const onSetVisible = () => {
    setVisible(true);
    if (menuRef.current && fixedPosition) {
      const menu = menuRef.current.querySelector('.menu-list');
      const sizes = menuRef.current.getBoundingClientRect();
      menu.style.left = `${sizes.left + sizes.width}px`;
      menu.style.top = `${sizes.top + sizes.height}px`;
      setTimeout(() => {
        const { height } = menu.getBoundingClientRect();
        if (sizes.top - height >= 0 && sizes.bottom + height >= window.innerHeight) {
          menu.style.transform = 'translate(-100%, -100%)';
          menu.style.marginTop = '-40px';
        } else {
          menu.style.transform = '';
          menu.style.marginTop = '';
        }
      }, 100);
    }
  };

  if (!React.Children.toArray(children).filter(el => el).length) {
    return null;
  }

  return (
    <div ref={menuRef} className={cn('button-menu-default', { fixed: fixedPosition }, className)} tabIndex="0">
      <Button icon={<Points />} onClick={onSetVisible} disabled={disabled} />
      <ul role="none" className={cn('menu-list', { visible })} onClick={() => setVisible(false)}>
        {children}
      </ul>
    </div>
  );
};
