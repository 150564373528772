import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Button, BUTTON_TYPES, Card, Tooltip } from '_atoms';
import { Points } from '_atoms/Icons';
import { ReactComponent as Divider } from 'dist/icons/steps-divider.svg';
import { ReactComponent as Corner } from 'dist/images/corner.svg';
import './style.scss';

export const JobStepsPanel = ({ activeStep, setActiveStep, steps }) => {
  const [isVisiable, setIsVisible] = useState(false);

  const onChangeActiveStep = step => () => {
    setActiveStep(step);
    setIsVisible(false);
  };

  useEffect(() => {
    const onClickOutSide = e => {
      const list = document.querySelector('.steps-panel');
      if (list && !list.contains(e.target) && isVisiable) setIsVisible(false);
    };
    window.addEventListener('click', onClickOutSide);
    return () => {
      window.removeEventListener('click', onClickOutSide);
    };
  }, [isVisiable]);

  return (
    <div className="container-steps">
      <div className="step-back" data-step={activeStep}>
        <div className="step-back_main" />
        <Corner className="corner-img" />
      </div>
      <Card className={cn('current-step', { last: activeStep === steps.length - 1 })}>
        <div className="step">
          <span className="step__label">{steps[activeStep]?.name}</span>
          <div className="step__count">{steps[activeStep]?.need_actions}</div>
          <Corner className="corner-img" />
        </div>
        <Divider className="step__divider" />
        <Button icon={<Points />} onClick={() => setIsVisible(v => !v)} type={BUTTON_TYPES.GHOST} />
      </Card>
      <Card className={cn('steps-panel', { visible: isVisiable })}>
        {steps.map((item, i) => (
          <div
            role="none"
            key={item.uuid}
            className={cn('step', { active: activeStep === i })}
            onClick={onChangeActiveStep(i)}
          >
            <div className="step__content">
              <span className="step__label">{item.name}</span>
              <Tooltip
                label={`${item.need_actions} active candidates on this step`}
                placement={[5, 6].includes(i) ? 'topRight' : 'topLeft'}
              >
                <div className="step__count">{item.need_actions}</div>
              </Tooltip>
              <Corner className="corner-img" />
            </div>
            <Divider className="step__divider" />
          </div>
        ))}
      </Card>
    </div>
  );
};
