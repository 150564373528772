import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { CandidateWidget } from '_templates/AnalyticsRecruitersInfo/AnalyticsRecruitersInfo';
import { Context } from '_templates/AnalyticsDashboard/analytics-dashboard';
import { ANALYTICS_TABS, STEPS } from 'constants/constants';
import { TransitionStep } from '_templates/AnalyticsDashboard/TransitionStep/transition-step';
import { AnalyticsEmpty } from '_templates/AnalyticsDashboard/AnalyticsEmpty/analytics-empty';
import { analyticWidgets } from 'actions/analyticActions';
import { PassingStep } from '_templates/AnalyticsDashboard/PassingStep/passing-step';
import { Rejection } from '_templates/AnalyticsDashboard/Rejection/rejection';
import { initialOptions, TOTAL_UNIQUE_CANDIDATE_CONFIG } from '_templates/AnalyticsDashboard/constans';
import { Reason } from '_templates/AnalyticsDashboard/Reason/reason';
import { totalUniqueCandidatesDataMapper } from '_templates/AnalyticsDashboard/helpers';
import Date from 'dist/images/common/icons/graph.svg';

export const AnalyticsTeamLeadsInfo = () => {
  const { appliedFilters } = useContext(Context);

  const chosenTab = ANALYTICS_TABS.TEAM_LEADS;

  const [transitionStep, setTransitionStep] = useState(initialOptions);
  const [passingStep, setPassingStep] = useState(initialOptions);
  const [rejectionNumber, setRejectionNumber] = useState(initialOptions);
  const [rejectionReason, setRejectionReason] = useState(initialOptions);
  const [rejectionStep, setRejectionStep] = useState(STEPS[0]);
  const [to, setTo] = useState({});
  const [from, setFrom] = useState({});

  const [totalCandidate, setTotalCandidate] = useState([TOTAL_UNIQUE_CANDIDATE_CONFIG]);

  const setLoading = () => {
    setRejectionNumber(initialOptions);
    setRejectionReason(initialOptions);
    setPassingStep(initialOptions);
    setTransitionStep(initialOptions);
    setTotalCandidate(v => [{ ...v[0], loading: true }]);
  };

  const applyAnalyticFilter = useCallback(filterOptions => {
    setLoading();

    analyticWidgets('total-unique-candidates', filterOptions).then(resp =>
      setTotalCandidate(totalUniqueCandidatesDataMapper(resp))
    );
    analyticWidgets('rejections-number', filterOptions, 'number').then(resp =>
      setRejectionNumber({ options: resp, loading: false })
    );
    analyticWidgets('rejection-reasons', filterOptions, 'reason').then(resp => {
      setRejectionReason({ options: resp, loading: false });
      setRejectionStep(STEPS[0]);
    });
    analyticWidgets('steps-transition', filterOptions, 'transition').then(resp =>
      setTransitionStep({ options: resp.options, empty: resp.empty, loading: false })
    );
    analyticWidgets('steps-passing-time', filterOptions, 'passing').then(resp => {
      setPassingStep({ options: resp, empty: !resp.filter(item => !!item.data).length, loading: false });
      setFrom(resp[0]);
      setTo(resp[resp.length - 1]);
    });
  }, []);

  useEffect(() => {
    applyAnalyticFilter(appliedFilters);
  }, [appliedFilters]);

  return (
    <>
      <div className="candidate">
        <Row gutter={[32, 0]}>
          <Col>
            <CandidateWidget className="fill-height" data={totalCandidate} />
          </Col>
          <Col>
            <Reason
              className="fill-height"
              options={rejectionReason.options}
              loading={rejectionReason.loading}
              rejectionStep={rejectionStep}
              changeRejectionStep={setRejectionStep}
            />
          </Col>
        </Row>
      </div>
      {transitionStep.loading || (!transitionStep.loading && (!transitionStep.empty || !passingStep.empty)) ? (
        <>
          <TransitionStep
            options={transitionStep.options}
            loading={transitionStep.loading}
            empty={transitionStep.empty}
            role={chosenTab}
          />
          <PassingStep
            options={passingStep.options}
            loading={passingStep.loading}
            from={from}
            to={to}
            setFrom={setFrom}
            setTo={setTo}
          />
          <Rejection options={rejectionNumber.options} loading={rejectionNumber.loading} />
        </>
      ) : (
        <AnalyticsEmpty
          text="Please try different period or filters"
          title="There are no results for the specified parameters"
          className="shadow"
          img={Date}
        />
      )}
    </>
  );
};
