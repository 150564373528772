import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { subject } from '@casl/ability';
import moment from 'moment';
import cn from 'classnames';
import { Col, Divider, Form, Row, Select as SelectAntd } from 'antd';
import { DatePicker, Input, Button, BUTTON_TYPES, Opening, Select, AtsSkeleton } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { dateFormatter } from 'helpers/dateFormatter';
import { ACTIONS, Can, UI } from 'permission';
import { ReactComponent as Arrow } from 'dist/icons/Vector1.svg';
import { getOpeningsList } from 'actions';
import { RULES } from './constants';
import './style.scss';

export const Resolution = ({ className, probationPeriod, loading, onSubmit, jobName, jobStatus }) => {
  const { id: jobId } = useParams();
  const [form] = Form.useForm();
  const [ediMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(true);
  const [openings, setOpenings] = useState([]);

  const onOpenDetails = () => setOpen(o => !o);
  const onEdit = () => setEditMode(true);
  const onCancelEdit = () => {
    setEditMode(false);
    form.setFieldsValue({
      probation_period: probationPeriod.probation_period,
      entry_date: moment(probationPeriod.entry_date, 'YYYY-MM-DD'),
      opening: {
        value: probationPeriod.opening?.uuid,
        label: `${probationPeriod.opening?.level} - ${probationPeriod.opening?.team_lead.full_name}`,
        offices: probationPeriod.opening?.offices || [],
      },
      location: { value: probationPeriod.location?.uuid, label: probationPeriod.location?.name },
    });
  };

  const onSubmitEntryDate = ({ entry_date, probation_period, opening, location }) => {
    onSubmit({
      entry_date: entry_date.format('YYYY-MM-DD'),
      probation_period,
      opening: opening.value,
      location: location.value,
    });
    setEditMode(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      probation_period: probationPeriod.probation_period,
      entry_date: moment(probationPeriod.entry_date, 'YYYY-MM-DD'),
      opening: {
        value: probationPeriod.opening?.uuid,
        label: `${probationPeriod.opening?.level} - ${probationPeriod.opening?.team_lead.full_name}`,
        offices: probationPeriod.opening?.offices || [],
      },
      location: { value: probationPeriod.location?.uuid, label: probationPeriod.location?.name },
    });
    if (probationPeriod.opening) {
      getOpeningsList(jobId, { status: 'Open' })
        .then(openOpenings =>
          setOpenings([
            { ...probationPeriod.opening, current: true },
            ...openOpenings.map(o => ({
              ...o,
              value: o.uuid,
              label: `${o.level} - ${o.team_lead.full_name}`,
            })),
          ])
        )
        .catch(() => setOpenings([]));
    }
  }, [probationPeriod]);

  if (loading) {
    return (
      <div className={cn('resolution resolution-loading', className)}>
        <div className="resolution__header">
          <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Resolution</Typography.Title2>
        </div>
        <AtsSkeleton height={340} />
        <Divider />
      </div>
    );
  }

  if (ediMode) {
    return (
      <div className={cn('resolution', className, { open })}>
        <div className="resolution__header">
          <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Resolution</Typography.Title2>
          <Arrow className="open-details" onClick={onOpenDetails} />
        </div>
        <div className="resolution__content">
          <Form form={form} onFinish={onSubmitEntryDate} validateTrigger="onSubmit">
            <Row gutter={12}>
              <Col flex="160px">
                <DatePicker label="Entry date" itemProps={{ name: 'entry_date', rules: RULES.DATE }} />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col flex="160px">
                <Input
                  label="Probation period"
                  placeholder="Number of months"
                  disabled
                  itemProps={{ name: 'probation_period' }}
                />
              </Col>
              <Col>
                <Typography.Text className="months">month(s)</Typography.Text>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24} lg={12} md={12} sm={24}>
                <Select
                  labelInValue
                  label="Opening"
                  className="margin-bottom-20"
                  placeholder="Please select opening"
                  itemProps={{ name: 'opening' }}
                  onChange={opening => {
                    const location =
                      opening.offices.length === 1
                        ? { label: opening.offices[0].name, value: opening.offices[0].uuid }
                        : undefined;
                    form.setFieldsValue({ opening, location });
                  }}
                >
                  {openings.map(i => (
                    <SelectAntd.Option
                      key={i.uuid}
                      value={i.uuid}
                      label={`${i.level} - ${i.team_lead.full_name}`}
                      offices={i.offices}
                    >
                      <div className="opening-select-option">
                        <Typography.Text>
                          {`${i.level} - ${i.team_lead.full_name}`}
                          {i.current && <span> (Current)</span>}
                        </Typography.Text>
                        <Typography.Caption>{`Expected date: ${dateFormatter(i.expected)}`}</Typography.Caption>
                        <Typography.Caption>{i.offices.map(o => o.name).join(', ')}</Typography.Caption>
                      </div>
                    </SelectAntd.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.opening !== curr.opening}>
              {({ getFieldValue }) => (
                <Row gutter={12}>
                  <Col span={24} lg={12} md={12} sm={24}>
                    <Select
                      labelInValue
                      label="Location"
                      className="margin-bottom-20"
                      placeholder="Please select location"
                      options={getFieldValue('opening')?.offices.map(i => ({ label: i.name, value: i.uuid }))}
                      itemProps={{ name: 'location' }}
                      disabled={getFieldValue('opening')?.offices.length === 1}
                    />
                  </Col>
                </Row>
              )}
            </Form.Item>
            <div className="resolution__content-footer">
              <Button onClick={onCancelEdit}>cancel</Button>
              <Button htmlType="submit" type={BUTTON_TYPES.PRIMARY}>
                save
              </Button>
            </div>
          </Form>
          {probationPeriod.opening && <Opening opening={probationPeriod.opening} jobName={jobName} inResolution />}
        </div>
        <Divider />
      </div>
    );
  }

  if (!Object.keys(probationPeriod).length) return null;

  return (
    <div className={cn('resolution', className, { open })}>
      <div className="resolution__header">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Resolution</Typography.Title2>
        <Arrow className="open-details" onClick={onOpenDetails} />
      </div>
      <div className="resolution__content">
        <div className="info-block">
          <Typography.Label>Entry date</Typography.Label>
          <Typography.Text>{moment(probationPeriod.entry_date, 'YYYY-MM-DD')?.format('MMM DD, YYYY')}</Typography.Text>
        </div>
        <div className="info-block">
          <Typography.Label>Probation period</Typography.Label>
          <Typography.Text>{`${probationPeriod.probation_period} month(s)`}</Typography.Text>
        </div>
        <Can I={ACTIONS.READ} a={subject(UI.EDIT_RESOLUTION, { status: jobStatus })}>
          <Button onClick={onEdit} className="edit-button">
            Edit resolution
          </Button>
        </Can>
        {probationPeriod.opening && <Opening opening={probationPeriod.opening} jobName={jobName} inResolution />}
      </div>
      <Divider />
    </div>
  );
};
