import moment from 'moment';

export const initialValuesMapper = opening => [
  { name: 'team_lead', value: { value: opening.team_lead.uuid, label: opening.team_lead.full_name } },
  { name: 'priority', value: { value: opening.priority, label: opening.priority } },
  { name: 'expected', value: moment(opening.expected, 'YYYY-MM-DD') },
  { name: 'offices', value: opening.offices.map(o => ({ value: o.uuid, label: o.name })) },
  {
    name: 'foreign_languages',
    value: opening.foreign_languages.length
      ? opening.foreign_languages.map(o => ({
          language: { value: o.language, label: o.language },
          level: { value: o.level, label: o.level },
        }))
      : [{ language: undefined, level: undefined }],
  },
  { name: 'employment', value: opening.employment },
  { name: 'employment_custom_value', value: opening.employment_custom_value || '' },
  { name: 'work_experience', value: opening.work_experience },
  { name: 'work_experience_custom_value', value: opening.work_experience_custom_value || '' },
  { name: 'experience_level', value: { value: opening.level, label: opening.level } },
  { name: 'experience_level_custom_value', value: opening.custom_level },
  { name: 'requirements_responsibilities', value: opening.requirements_responsibilities },
  { name: 'requirements_responsibilities_initial', value: opening.requirements_responsibilities },
  { name: 'comment', value: opening.comment },
  { name: 'comment_initial', value: opening.comment },
];

export const formValuesMapper = data => ({
  team_lead: data.team_lead.value,
  priority: data.priority.value,
  expected: data.expected.format('YYYY-MM-DD'),
  offices: data.offices.map(el => el.value),
  foreign_languages: data.foreign_languages
    .filter(el => el.language && el.level)
    .map(el => ({ language: el.language.value, level: el.level.value })),
  employment: data.employment,
  employment_custom_value: +data.employment_custom_value || 0,
  work_experience: data.work_experience,
  work_experience_custom_value: data.work_experience_custom_value,
  level: data.experience_level.value,
  custom_level: data.experience_level_custom_value,
  requirements_responsibilities: data.requirements_responsibilities,
  comment: data.comment,
});

export const teamLeadOptionsMapper = jobData => {
  const teamLeads = [];
  if (jobData.service.service_head) {
    teamLeads.push({ value: jobData.service.service_head.uuid, label: jobData.service.service_head.full_name });
  }
  if (jobData.service.team_leads) {
    jobData.service.team_leads.forEach(tl => {
      if (tl.uuid !== jobData.service.service_head?.uuid) {
        teamLeads.push({ value: tl.uuid, label: tl.full_name });
      }
    });
  }
  return teamLeads;
};

export const validatorL =
  (fieldToCheck, message, path = 'foreign_languages') =>
  (form, index) => ({
    validator: (_, v) => {
      const row = form.getFieldValue(path)[index];
      if (!row) return Promise.resolve();
      const field = row[fieldToCheck];
      if (field && !v) return Promise.reject(new Error(message));
      return Promise.resolve();
    },
    message,
  });
