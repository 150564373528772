import { ROLES } from 'permission';
import { OPENING_STATUSES } from '../../constants/opening';
import { JOB_STATUSES } from '../../constants/job';

const LIMIT = 25;

export const LABELS = {
  COMPANIES: 'Company',
  SERVICE: 'Service',
  TEAM_LEAD: 'Team Lead',
  JOB_STATUS: 'Job status',
  RECRUITER: 'Recruiter',
  OPENING_STATUS: 'Opening status',
  OFFICE: 'Office location',
  EXPERIENCE_LEVEL: 'Required experience level',
};
export const EMPTY_FILTERS_VALUES = {
  search: '',
  companies: [],
  services: [],
  team_leads: [],
  jobs_statuses: [
    JOB_STATUSES.OPEN,
    JOB_STATUSES.APPROVED,
    JOB_STATUSES.PENDING_APPROVAL,
    JOB_STATUSES.ARCHIVED,
    JOB_STATUSES.DRAFT,
  ].map(value => ({ value, label: value })),
  recruiters: [],
  openings_statuses: [
    OPENING_STATUSES.OPEN,
    OPENING_STATUSES.WAITING_FOR_ENTRY,
    OPENING_STATUSES.SH_APPROVAL,
    OPENING_STATUSES.RM_APPROVAL,
    OPENING_STATUSES.LM_APPROVAL,
    OPENING_STATUSES.HRL_APPROVAL,
    OPENING_STATUSES.CLOSED,
    OPENING_STATUSES.DECLINED,
    OPENING_STATUSES.HIRED,
  ].map(value => ({ value, label: value })),
  offices: [],
  experience_levels: [],
};

export const RESET_FILTERS_VALUES = Object.entries(EMPTY_FILTERS_VALUES).map(([name, value]) => ({ name, value }));

export const OPTIONS_REQUEST_CONFIG = {
  companies: (search, filters) => ({
    url: `jobs/filters/companies/`,
    body: { limit: LIMIT, ...filters, search },
    method: 'POST',
    version: 'v2',
  }),
  services: (search, filters) => ({
    url: `jobs/filters/services/`,
    body: { limit: LIMIT, ...filters, search },
    method: 'POST',
    version: 'v2',
  }),
  team_leads: (search, filters) => ({
    url: `jobs/filters/team-leads/`,
    body: { limit: LIMIT, role: ROLES.TEAM_LEAD, ...filters, search },
    method: 'POST',
    version: 'v2',
  }),
  jobs_statuses: (search, filters) => ({
    url: `jobs/filters/jobs-statuses/`,
    body: { limit: LIMIT, role: ROLES.TEAM_LEAD, ...filters, search },
    method: 'POST',
    version: 'v2',
  }),
  recruiters: (search, filters) => ({
    url: `jobs/filters/recruiters/`,
    body: { limit: LIMIT, role: [ROLES.HR_LEAD, ROLES.RECRUITER], ...filters, search },
    method: 'POST',
    version: 'v2',
  }),
  openings_statuses: (search, filters) => ({
    url: `jobs/filters/openings-statuses/`,
    body: { limit: LIMIT, ...filters, search },
    method: 'POST',
    version: 'v2',
  }),
  offices: (search, filters) => ({
    url: `jobs/filters/office-locations/`,
    body: { limit: LIMIT, ...filters, search },
    method: 'POST',
    version: 'v2',
  }),
  experience_levels: (search, filters) => ({
    url: `jobs/filters/experience-levels/`,
    body: { limit: LIMIT, ...filters, search },
    method: 'POST',
    version: 'v2',
  }),
};

export const FIELDS_HIERARCHY = {
  search: [],
  companies: [
    { name: 'services', value: [] },
    { name: 'team_leads', value: [] },
    // { name: 'jobs_statuses', value: [] },
    // { name: 'recruiters', value: [] },
    // { name: 'openings_statuses', value: [] },
    { name: 'offices', value: [] },
    { name: 'experience_levels', value: [] },
  ],
  services: [
    { name: 'team_leads', value: [] },
    // { name: 'jobs_statuses', value: [] },
    // { name: 'recruiters', value: [] },
    // { name: 'openings_statuses', value: [] },
    { name: 'offices', value: [] },
    { name: 'experience_levels', value: [] },
  ],
  team_leads: [
    // { name: 'jobs_statuses', value: [] },
    // { name: 'recruiters', value: [] },
    // { name: 'openings_statuses', value: [] },
    { name: 'offices', value: [] },
    { name: 'experience_levels', value: [] },
  ],
  jobs_statuses: [],
  //   { name: 'recruiters', value: [] },
  //   { name: 'openings_statuses', value: [] },
  //   { name: 'offices', value: [] },
  //   { name: 'experience_levels', value: [] },
  // ],
  recruiters: [],
  //   { name: 'openings_statuses', value: [] },
  //   { name: 'offices', value: [] },
  //   { name: 'experience_levels', value: [] },
  // ],
  openings_statuses: [],
  offices: [],
  experience_levels: [],
};
